import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import "./AddPromptPage.css";
import AdminNavigation from "../AdminNavigation";
import { Prompt } from "../../../datamodel/Prompt";
import { PageTitle, TextGroup } from "../Items";
import { savePrompt } from "../../../redux/PromptsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getCharacterNames } from "../../../redux/CharactersSlice";
import Select, { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Deactive", label: "Deactive" },
];

const AddPromptPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [characterName, setCharacterName] = useState<string>("");
  const [prompt, setCustomPrompt] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [notes, setCustomNotes] = useState<string>("");

  // Get character names from the store
  const existingCharacterNames = useSelector(
    (state: RootState) => state.characters?.names
  );
  const characterNamesStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  useEffect(() => {
    dispatch(getCharacterNames() as any);
  }, [dispatch]);

  if (characterNamesStatus === "loading") {
    return <div className="loading">Loading character names...</div>;
  }

  if (characterNamesStatus === "failed") {
    return <div className="loading">Failed to load character names</div>;
  }

  // Create prompt object
  const defaultPrompt: Prompt = {
    characterName: "",
    status: "",
    prompt: "",
    notes: "",
  };
  const handleNameChange = (
    select: SingleValue<{ value: string; label: string }>
  ) => {
    setCharacterName(select?.value || "");
  };
  const handleStatusChange = (
    select: SingleValue<{ value: string; label: string }>
  ) => {
    setStatus(select?.value || "");
  };
  const handlePromptChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCustomPrompt(event.target.value);
  };
  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCustomNotes(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const inputPrompt = {
      ...defaultPrompt,
      characterName,
      status,
      prompt,
      notes,
    };
    await dispatch(savePrompt(inputPrompt) as any);

    // Reset the form
    setCharacterName("");
    setStatus("");
    setCustomPrompt("");
    setCustomNotes("");

    navigate("/administration/listprompts");
  };

  const characterNameOptions = existingCharacterNames.map((name) => ({
    value: name,
    label: name,
  }));

  const canBeSaved =
    characterName !== "" && status !== "" && prompt !== "" && notes !== "";

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <form onSubmit={handleSubmit}>
          <div className="page-main">
            <PageTitle title="Add Prompt" />
            <div className="items">
              <div className="item-simple-input">
                <div className="item-title-input-dropdown">
                  <TextGroup
                    title="Character Assignment*"
                    subtitle="Which character is this prompt for"
                  />
                  <Select
                    className="dropdown-content"
                    options={characterNameOptions}
                    onChange={handleNameChange}
                  />
                </div>
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Status*</div>
                <Select
                  className="dropdown-content"
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div className="item-simple-input">
                <TextGroup title="Prompt*" subtitle="500 characters max" />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input custom prompt here"
                  value={prompt}
                  onChange={handlePromptChange}
                />
              </div>
              <div className="item-simple-input">
                <TextGroup
                  title="Notes*"
                  subtitle="Internal notes about this prompt"
                />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input notes here"
                  value={notes}
                  onChange={handleNotesChange}
                />
              </div>
            </div>
          </div>
          <div className="simple-space"></div>
          <div className="actions">
            <button
              className={`${
                canBeSaved ? "button-action" : "button-disabled-action"
              }`}
              disabled={!canBeSaved}
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPromptPage;
