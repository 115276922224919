import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "../../assets/images/x.svg";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import DefaultProfileImage from "../../assets/images/fic_default_user_pic.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { GenrateImageUrl } from "../../redux/LoginSlice";
import { useRef, useState } from "react";
import Loader from "./loader";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangeAvatarPopup(props: any) {
  const {
    OpenChangeProfile,
    setOpenChangeProfile,
    setOpenCrop,
    screenWidtht,
    email,
    uploadedImage,
    setUploadedImage,
    croppedImage,
    image,
  } = props;
  const handleClose = () => {
    setOpenChangeProfile(false);
  };

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const isMobile = screenWidtht < 775;

  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const Data = useSelector((state: any) => state.LoginSlice);

  const dispatch: any = useDispatch();

  const handleReplaceButtonClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedImageFile(files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const uploadedImage = reader.result;
        setUploadedImage(uploadedImage);
        displayUploadedImage(uploadedImage?.toString() || "");
      };
    }
  };

  const displayUploadedImage = (imageData: string) => {
    const profileImage = document.getElementById(
      "profilePic"
    ) as HTMLImageElement;
    if (profileImage) {
      profileImage.src = imageData;
    }
  };

  const uploadUserImage = async () => {
    if (croppedImage) {
      await dispatch(
        GenrateImageUrl({
          email: email,
          file: croppedImage,
          dispatch,
          handleClose,
        })
      );
    } else if (selectedImageFile) {
      await dispatch(
        GenrateImageUrl({
          email: email,
          file: selectedImageFile,
          dispatch,
          handleClose,
        })
      );
    } else {
      return;
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        open={OpenChangeProfile}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: isMobile ? "0px" : "20px",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobile ? "auto" : "400px",
            padding: "10px 20px",
            borderBottom: "1px solid #CECECE",
          }}
        >
          <Typography
            component="div"
            style={{
              color: "#26DAF0",
              fontFamily: "var(--font-family-dm_mono)",
              fontSize: "var(--font-size-xl)",
              fontWeight: "regular",
            }}
          >
            Preview profile pic
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <img className="menu" src={Close} alt="menu" />
          </IconButton>
        </Box>

        <Box
          style={{
            height: "100%",
            padding: isMobile ? "20px" : "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: isMobile ? "rgba(149, 149, 149, 0.2)" : "#ffffff",
          }}
        >
          <div>
            <div
              style={{
                height: "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: isMobile
                  ? "linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2)"
                  : "",
                borderRadius: isMobile ? "20px" : "",
              }}
            >
              <img
                id="profilePic"
                style={{ height: "200px", width: "200px", borderRadius: "50%" }}
                src={
                  uploadedImage
                    ? uploadedImage
                    : image
                    ? image
                    : DefaultProfileImage
                }
                alt="profile"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
              ref={imageInputRef}
            />

            <div style={{ display: "flex", gap: "15px", padding: "15px 0" }}>
              <button style={greyBtn} onClick={handleReplaceButtonClick}>
                Replace
              </button>
              <button
                style={{
                  ...greyBtn,
                  cursor: uploadedImage ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  setOpenChangeProfile(false);
                  setOpenCrop(true);
                }}
                disabled={!uploadedImage}
              >
                Crop
              </button>
            </div>
          </div>

          <button
            className="login-signup-buttons-content login-google-button"
            onClick={() => {
              uploadUserImage();
            }}
            disabled={Data?.updateUserPropertyLoading}
          >
            <div className="login-signup-btn">
              <div
                className="login-signup-btn-text"
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <span>Save</span>
                <span>{Data?.updateUserPropertyLoading && <Loader />}</span>
              </div>
            </div>
          </button>
        </Box>
      </Dialog>
    </div>
  );
}

const greyBtn: any = {
  width: "100%",
  textAlign: "center",
  background: "#959595",
  color: "white",
  borderRadius: "8px",
  border: "none",
  padding: "8px ",
  fontFamily: "var(--font-family-inter)",
  fontSize: "var(--font-size-m)",
  fontWeight: "semi-bold",
  cursor: "pointer",
};
