import React, { FormEvent, useEffect, useRef, useState } from "react";
import "./UserProfile.css";
import DefaultProfileImage from "../../assets/images/fic_default_user_pic.jpeg";
import Header from "../header/Header";
import LoginImage from "../../assets/images/chevron-down.svg";
import Footer from "../footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkUsernameAvailable,
  deleteUser,
  getUser,
  updateUser,
  updateUserProperty,
} from "../../redux/LoginSlice";
import { useUser } from "../contexts/UserContext";
import ChangeAvatarPopup from "./ChangeAvatarPopup";
import CropProfilePopup from "./CropProfilePopup";
import MyChats from "./MyChats";

const UserProfile = () => {
  const Data = useSelector((state: any) => state.LoginSlice);
  const confirmDeleteRef = useRef(null);

  const [isselect, setselected] = useState("setting");
  const [isUsernameEditable, setIsUsernameEditable] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [Username, setUsername] = useState(newUsername ? newUsername : "");
  const { user, setUser } = useUser();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [userAvl, setUserAvl] = useState(false);

  const dispatch: any = useDispatch();
  const [OpenChangeProfile, setOpenChangeProfile] = React.useState(false);
  const [openCrop, setOpenCrop] = React.useState(false);

  const [isDisplayNameEditable, setIsDisplayNameEditable] = useState(false);
  const [userName, setUserName] = useState(false);
  const [displayName, setDisplayName] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);
  const isMobile = window.innerWidth <= 768;
  const [uploadedImage, setUploadedImage] = React.useState<
    string | ArrayBuffer | null
  >(null);
  const [croppedImage, setCroppedImage] = React.useState<Blob | null>(null);
  const [isProfilePictureEditable, setIsProfilePictureEditable] =
    useState(false);

  const { settingtype } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (Username) {
      dispatch(checkUsernameAvailable({ username: Username }));
      setUsername(Username);
    }
  }, [Username]);

  useEffect(() => {
    setNewUsername(Data.getUserData.userName);
    setNewDisplayName(Data.getUserData.displayName);
  }, [Data]);

  useEffect(() => {
    if (newUsername) {
      setUsername(newUsername);
    }

    // after uploading new picture : redirect to crop
    if (uploadedImage) {
      setOpenCrop(true);
    }
  }, [newUsername, uploadedImage]);

  useEffect(() => {
    if (settingtype) {
      setselected(settingtype);
    }
  }, [settingtype]);

  const handleEdit = (field: string) => {
    if (field === "username") {
      setIsUsernameEditable(true);
      setUserName(true);
    } else if (field === "displayName") {
      setDisplayName(true);
      setIsDisplayNameEditable(true);
    } else if (field === "profilePicture") {
      setIsProfilePictureEditable(true);
    }
  };

  const handleSave = (field: string) => {
    if (field === "username") {
      setUserName(false);
      updateuser();
      setIsUsernameEditable(false);
    } else if (field === "displayName") {
      setDisplayName(false);
      updateuser();
      setIsDisplayNameEditable(false);
    } else if (field === "profilePicture") {
      setIsProfilePictureEditable(false);
    }

    // You can make API calls here to save the updated values
  };

  const handleDelete = (event: any, field: string) => {
    event.stopPropagation();
    if (field === "username") {
      setUserName(false);
      setIsUsernameEditable(false);
    } else if (field === "displayName") {
      setDisplayName(false);
      setIsDisplayNameEditable(false);
    } else if (field === "delete") {
      setConfirmDelete(false);
    }
  };

  const updateuser = () => {
    dispatch(
      updateUser({
        useremail: Data.getUserData.email,
        username: Username.length > 2 ? Username : newUsername,
        displayname: newDisplayName,
        id: Data.getUserData._id,
        dispatch,
      })
    );
  };

  const userdelete = () => {
    if (confirmDelete) {
      dispatch(
        deleteUser({
          useremail: Data.getUserData.email,
          navigate,
          setUser,
        })
      );
    }
  };

  const handleClickOutside = (event: any) => {
    if (
      confirmDeleteRef.current &&
      //@ts-ignore
      !confirmDeleteRef.current.contains(event.target)
    ) {
      setConfirmDelete(false);
      setIsUsernameEditable(false);
      setIsDisplayNameEditable(false);
    }
  };

  useEffect(() => {
    if (confirmDelete) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [confirmDelete]);

  const editFieldsRef = useRef(null);

  // Function to close the editing fields when clicking outside
  const closeEditFields = () => {
    setIsUsernameEditable(false);
    setUserName(false);
    setIsDisplayNameEditable(false);
  };
  useEffect(() => {
    const handleMouseDown = (event: any) => {
      if (
        editFieldsRef.current &&
        //@ts-ignore
        !editFieldsRef.current.contains(event.target)
      ) {
        closeEditFields();
      }
    };

    if (isUsernameEditable || isDisplayNameEditable) {
      window.addEventListener("mousedown", handleMouseDown);
    } else {
      window.removeEventListener("mousedown", handleMouseDown);
    }

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [isUsernameEditable, isDisplayNameEditable]);

  const updateScreenHeight = () => {
    setScreenWidtht(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  // checkbox
  const [checkbox1, setCheckbox1] = useState<boolean>();
  const [checkbox2, setCheckbox2] = useState<boolean>();

  useEffect(() => {
    setCheckbox2(Data?.getUserData?.emailWhenRated);
    setCheckbox1(Data?.getUserData?.emailForNewCharacter);
  }, [Data]);

  const handleCheckboxChange = (checkboxName: String) => {
    if (checkboxName === "checkbox1") {
      const updatebody: any = {
        email: Data.getUserData.email,
        file1: Data?.getUserData?.profilePicture,
        dispatch,
        newChar: !checkbox1,
        whenRated: checkbox2,
      };

      dispatch(updateUserProperty(updatebody));
    } else if (checkboxName === "checkbox2") {
      const updatebody: any = {
        email: Data.getUserData.email,
        file1: Data?.getUserData?.profilePicture,
        dispatch,
        newChar: checkbox1,
        whenRated: !checkbox2,
      };
      dispatch(updateUserProperty(updatebody));
    }
  };

  useEffect(() => {
    document.title = `Fictiono.us - ${
      isselect === "setting" ? "Settings" : "My Chats"
    }`;
  });

  return (
    <div className="container">
      <div className="user-profile">
        {/* <UserHead /> */}
        <Header buttonText="slogin" imageSrc={LoginImage} />

        <div className="user-profile-container">
          {/* left image part */}
          <section className="profile-card">
            <div style={{ width: screenWidtht < 775 ? "50px" : "" }}>
              <div
                className={
                  screenWidtht < 775
                    ? "avatar-xs small-img-profile"
                    : "avatar-l borderclass"
                }
              >
                <img
                  className="avatar-image user-img-mobo"
                  src={
                    Data?.getUserData?.profilePicture
                      ? Data?.getUserData?.profilePicture
                      : DefaultProfileImage
                  }
                  alt="profile"
                />
              </div>
            </div>
            <div className="paragraph-container">
              <p className="paragraph-main">{Data?.getUserData?.displayName}</p>
              <p className="paragraph-user">@{Data?.getUserData?.userName}</p>
            </div>
          </section>

          {/* right content part */}
          <section className="Newsection">
            <div className="navbar-section">
              <div
                onClick={() => {
                  setselected("mychat");
                  navigate(`/user-profile/mychat`);
                }}
                className={
                  isselect === "mychat" ? "selected-class" : "nonselected-class"
                }
              >
                My Chats
              </div>

              <div
                onClick={() => {
                  setselected("setting");
                  navigate(`/user-profile/setting`);
                }}
                className={
                  isselect === "setting"
                    ? "selected-class"
                    : "nonselected-class"
                }
              >
                Settings
              </div>
            </div>

            {isselect === "setting" ? (
              <>
                <div className="account-section" ref={editFieldsRef}>
                  <h4 className="Account">Account</h4>
                  <div className="profile-cards">
                    <div>
                      <p className="user-name-details">Username</p>
                      {isUsernameEditable ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="input-field-editable">
                            <input
                              className="input_change_field"
                              type="text"
                              // value={Username ? Username : newUsername}
                              value={Username ? Username : ""}
                              onChange={(e) => setUsername(e.target.value)}
                              // onBlur={() => {
                              //   setIsUsernameEditable(false);
                              //   setUserName(false);
                              // }}
                            />

                            <button
                              onClick={() => handleSave("username")}
                              className="save_button"
                              disabled={!Data?.checkUsernameData.available}
                            >
                              Save
                            </button>
                            <button
                              onClick={(event) =>
                                handleDelete(event, "username")
                              }
                              className="cancel_button"
                            >
                              Cancel
                            </button>
                          </div>
                          {!Data?.checkUsernameData.available &&
                          Data.getUserData.userName !== Username ? (
                            <div className="error-username">
                              Username not available
                            </div>
                          ) : (
                            ""
                          )}
                          {Username?.length <= 2 ? (
                            <div className="error-username">
                              Enter more than 2 character.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <p className="user-login-details">{newUsername}</p>
                      )}
                    </div>
                    <div className="user-btn">
                      {!userName && (
                        <button
                          className="user-btn-change"
                          onClick={() => handleEdit("username")}
                        >
                          Change
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="profile-cards">
                    <div>
                      <p className="user-name-details">Display Name</p>
                      {isDisplayNameEditable ? (
                        <div className="input-field-editable">
                          <input
                            className="input_change_field"
                            type="text"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                            // onBlur={() => {
                            //   setDisplayName(false);
                            //   setIsDisplayNameEditable(false);
                            // }}
                          />
                          <button
                            onClick={() => handleSave("displayName")}
                            className="save_button"
                            // ref={confirmDeleteRef}
                          >
                            Save
                          </button>
                          <button
                            onClick={(event) =>
                              handleDelete(event, "displayName")
                            }
                            className="cancel_button"
                            // ref={confirmDeleteRef}
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <p className="user-login-details">{newDisplayName}</p>
                      )}
                    </div>
                    <div className="user-btn">
                      {!isDisplayNameEditable && (
                        <button
                          className="user-btn-change"
                          onClick={() => handleEdit("displayName")}
                        >
                          Change
                        </button>
                      )}
                    </div>
                  </div>

                  <ChangeAvatarPopup
                    OpenChangeProfile={OpenChangeProfile}
                    setOpenChangeProfile={setOpenChangeProfile}
                    screenWidtht={screenWidtht}
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    uploadedImage={uploadedImage}
                    setUploadedImage={setUploadedImage}
                    email={Data.getUserData.email}
                    image={Data?.getUserData?.profilePicture}
                    croppedImage={croppedImage}
                  />

                  <CropProfilePopup
                    setOpenCrop={setOpenCrop}
                    openCrop={openCrop}
                    screenWidtht={screenWidtht}
                    setOpenChangeProfile={setOpenChangeProfile}
                    uploadedImage={uploadedImage}
                    image={Data?.getUserData?.profilePicture}
                    setCroppedImage={setCroppedImage}
                  />

                  <div className="profile-cards">
                    <div>
                      <p className="user-name-details">Profile Picture</p>
                    </div>
                    <div
                      className="user-btn"
                      onClick={() => setOpenChangeProfile(true)}
                    >
                      <button className="user-btn-change">Change</button>
                    </div>
                  </div>
                  <div className="profile-cards">
                    <div>
                      <p className="user-name-details">Email Address</p>
                      <p className="user-login-email-details">
                        {decodeURIComponent(Data?.getUserData?.email)}
                      </p>
                    </div>
                  </div>
                  <div className="profile-cards">
                    <div>
                      <p className="user-name-details">Password</p>
                      <p className="user-login-email-details">
                        You signed up via Google, so you don’t have a password.
                      </p>
                    </div>
                  </div>
                  <div className="profile-cards">
                    <div className="deletation-text-left">
                      <p className="user-name-details">Deletion</p>
                      <p className="user-login-email-details">
                        Permanently delete your account.
                        <span className="support-text">Learn More</span>
                      </p>
                    </div>
                    <div className="user-btn" ref={confirmDeleteRef}>
                      <button
                        onClick={() => {
                          setConfirmDelete(!confirmDelete);
                          userdelete();
                        }}
                        className={`user-btn-change ${
                          confirmDelete ? "confirm-user-btn-change" : ""
                        } `}
                        style={{
                          height:
                            confirmDelete && screenWidtht < 975 ? "40px" : "",
                          fontSize:
                            confirmDelete && screenWidtht < 975 ? "12px" : "",
                        }}
                      >
                        {!confirmDelete ? "Delete" : "Confirm"}
                      </button>
                      {confirmDelete && (
                        <button
                          onClick={(event) => handleDelete(event, "delete")}
                          className="cancel_confirm-button"
                          // ref={confirmDeleteRef}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="email-notification">
                  <h4>Email Notification</h4>
                  <p className="email-helper-text">
                    What would you like to be notified of via email?
                  </p>
                  <div>
                    <div className="email-notification-checkboxes">
                      <input
                        type="checkbox"
                        checked={checkbox1}
                        onChange={() => handleCheckboxChange("checkbox1")}
                      />
                      <p className="email-notification-paragraph">
                        Emails for new characters
                      </p>
                    </div>
                    <div className="email-notification-checkboxes">
                      <input
                        type="checkbox"
                        checked={checkbox2}
                        onChange={() => handleCheckboxChange("checkbox2")}
                      />
                      <p className="email-notification-paragraph">
                        Emails when your chats get rated by others
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <MyChats
                  // currentUser={decodeURIComponent(Data?.getUserData?.email)}
                  currentUser={Data?.getUserData?.email}
                />
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserProfile;
