import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import VectorUpImage from "../../assets/images/VectorUp.svg";
import VectorImage from "../../assets/images/Vector.svg";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  getBestActivityMyChat,
  getRecentActivityMyChat,
} from "../../redux/MyChatBestRecentSlice";

const timeperiod = [
  { name: "Last 7 Days", id: "7" },
  { name: "Last 15 Days", id: "15" },
  { name: "Last 30 Days", id: "30" },
];

export default function MyChatCharacterDropdown({
  getAllCharacter,
  characterId,
  setCharacterId,
  setBest,
  setRecent,
  currentUser,
}: any) {
  const dispatch: any = useDispatch();

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [characterdaysago, setCharacterdaysago] = React.useState<any>();

  const [timePeriodDropDown, setTimePeriodDropDown] =
    React.useState("Last 7 Days");
  const [menuItemDropDown, setMenuItemDropDown] =
    React.useState("All Characters");

  const DropFunction = (
    characterId: any,
    characterdaysago: any,
    currentUser: any
  ) => {
    if ((characterId || characterdaysago) && currentUser) {
      setRecent([]);
      setBest([]);

      dispatch(
        getRecentActivityMyChat({
          characterId,
          characterdaysago,
          user: decodeURIComponent(currentUser),
        })
      );
      dispatch(
        getBestActivityMyChat({
          characterId,
          characterdaysago,
          user: decodeURIComponent(currentUser),
        })
      );
    }
  };

  interface Character {
    id: string;
    status: string;
    name: string;
  }
  const sortedCharacters: Character[] | undefined = getAllCharacter?.characters
    ?.slice()
    .sort((a: Character, b: Character) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

  const anchorRef1 = React.useRef<HTMLButtonElement>(null);
  const anchorRef2 = React.useRef<HTMLButtonElement>(null);

  const handleToggle1 = () => {
    setOpen1((prevOpen: any) => !prevOpen);
  };

  const handleClose1 = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef1.current &&
      anchorRef1.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen1(false);
  };

  const handleCloseTimePeriod = (
    event: Event | React.SyntheticEvent,
    item: any
  ) => {
    if (
      anchorRef1.current &&
      anchorRef1.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setTimePeriodDropDown(item.name);
    setOpen1(false);
  };

  const handleToggle2 = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const handleClose2 = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef2.current &&
      anchorRef2.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen2(false);
  };

  const handleCloseMenuItem = (
    event: Event | React.SyntheticEvent,
    item?: any
  ) => {
    if (
      anchorRef2.current &&
      anchorRef2.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setMenuItemDropDown(item);
    setOpen2(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1(false);
      setOpen2(false);
    } else if (event.key === "Escape") {
      setOpen1(false);
      setOpen2(false);
    }
  }

  const prevOpen1 = React.useRef(open1);
  const prevOpen2 = React.useRef(open2);

  React.useEffect(() => {
    if (prevOpen1.current === true && open1 === false) {
      anchorRef1.current!.focus();
    }
    if (prevOpen2.current === true && open2 === false) {
      anchorRef2.current!.focus();
    }

    prevOpen1.current = open1;
    prevOpen2.current = open2;
  }, [open1, open2]);

  return (
    <Box
      sx={{
        display: "flex",
        // marginLeft: "57px",

        "&::-webkit-scrollbar": {
          width: 0,
          height: 0,
          scrollbarWidth: "none" /* Firefox */,
          scrollbarColor: "transparent transparent" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,
        },

        "@media (max-width:775px)": {
          // marginLeft: "5px",

          overflowX: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none" /* IE and Edge */,

          marginBottom: "-35px",
          position: "absolute",
          left: 0,
          right: 0,
        },
      }}
    >
      {/* First Dropdown */}
      <Box
        sx={{
          marginRight: "0px",
          marginTop: "20px",
          marginBottom: "40px",
          zIndex: "999",
          // marginLeft: "57px",
          ["@media (max-width:775px)"]: {
            marginLeft: "30px",
          },
          ["@media (max-width:575px)"]: {
            marginLeft: "20px",
          },
        }}
      >
        <Button
          ref={anchorRef1}
          id="composition-button-1"
          aria-controls={open1 ? "composition-menu-1" : undefined}
          aria-expanded={open1 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle1}
          sx={{
            width: "244px",
            height: "68px",
            color: "white",
            lineHeight: "30px",
            borderRadius: !open1 ? "10px" : "10px 10px 0 0",
            display: "flex",
            gap: "40px",
            alignItems: "center",
            padding: "19px 24px 19px 24px",
            backgroundColor: "var(--turquoise-2)",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: "var(--turquoise-2)",
            },
            ["@media (max-width:575px)"]: {
              width: "200px !important",
              whiteSpace: "nowrap",
              marginLeft: "0px",
              height: "40px",
              gap: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between !important",
              gap: "10px",
              color: "var(--white)",
              fontFamily: "var(--font-family-dm_mono)",
              fontSize: "var(--font-size-xl)",
              letterSpacing: "0px",
              fontWeight: 500,
              textTransform: "capitalize",
              ["@media (max-width:575px)"]: {
                fontSize: "16px !important",
              },
            }}
            // className={classes.dropdown_btn}
          >
            {timePeriodDropDown}

            <div style={{ position: "relative", bottom: "3px", left: "8px" }}>
              {open1 ? (
                <img
                  src={VectorUpImage}
                  style={{ width: "12px", height: "6px" }}
                />
              ) : (
                <img
                  src={VectorImage}
                  style={{ width: "12px", height: "6px" }}
                />
              )}
            </div>
          </Box>
        </Button>

        <Popper
          open={open1}
          anchorEl={anchorRef1.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{
            width: "244px",
            maxHeight: "200px",
            overflowY: "hidden",
            borderRadius: "0px 0px 10px 10px",
            "& .MuiList-root": {
              borderRadius: "0px 0px 10px 10px !important",
              padding: "5px 10px",
            },
            ["@media (max-width:775px)"]: {
              width: "244px !important",
              position: "relative !important",
              transform: "translate3d(-19px, 0px, 0px) !important",
              inset: "0px auto auto 19px !important",
            },
            ["@media (max-width:575px)"]: {
              width: "200px !important",
            },
          }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                borderRadius: "0 0 10px 10px",
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose1}>
                  <MenuList
                    autoFocusItem={open1}
                    id="composition-menu-1"
                    aria-labelledby="composition-button-1"
                    onKeyDown={handleListKeyDown}
                  >
                    {timeperiod
                      ?.slice()
                      ?.sort()
                      ?.map((item: any) => {
                        return (
                          <MenuItem
                            onClick={(event) => {
                              handleCloseTimePeriod(event, item);
                              setCharacterdaysago(item.id);
                              DropFunction(characterId, item?.id, currentUser);
                            }}
                            sx={{
                              fontWeight: 600,
                              padding: "15px 10px !important",
                              "&:hover": {
                                backgroundColor:
                                  "rgb(229, 229, 229) !important",
                                borderRadius: "10px",
                              },
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      {/* Second Dropdown */}
      <div
        style={{
          margin: "20px",
          marginTop: "20px",
          marginBottom: "40px",
          zIndex: "999",
        }}
      >
        <Button
          ref={anchorRef2}
          id="composition-button-2"
          aria-controls={open2 ? "composition-menu-2" : undefined}
          aria-expanded={open2 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle2}
          sx={{
            width: "326px",
            height: "68px",
            color: "white",
            justifyContent: "flex-start !important",
            padding: "19px 24px 19px 24px",
            borderRadius: !open2 ? "10px" : "10px 10px 0 0",
            display: "flex",
            gap: "40px",
            alignItems: "center",
            backgroundColor: "var(--turquoise-2)",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: "var(--turquoise-2)",
            },
            ["@media (max-width:575px)"]: {
              width: "250px",
              whiteSpace: "nowrap",
              marginLeft: "0px",
              height: "40px",
              gap: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between !important",
              gap: "40px",
              color: "var(--white)",
              fontFamily: "var(--font-family-dm_mono)",
              fontSize: "var(--font-size-xl)",
              letterSpacing: "0px",
              fontWeight: 500,
              textTransform: "capitalize",
              width: "98%",
              ["@media (max-width:575px)"]: {
                fontSize: "16px !important",
              },
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              {menuItemDropDown}
            </div>
            <div
              style={{
                position: "relative",
                bottom: "3px",
              }}
            >
              {open2 ? (
                <img
                  src={VectorUpImage}
                  style={{ width: "12px", height: "6px" }}
                />
              ) : (
                <img
                  src={VectorImage}
                  style={{ width: "12px", height: "6px" }}
                />
              )}
            </div>
          </Box>
        </Button>

        <Popper
          open={open2}
          anchorEl={anchorRef2.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{
            maxHeight: "200px",
            width: "326px",
            autoFocus: "false",
            height: "auto",
            borderRadius: "0px 0px 10px 10px",
            "& .MuiList-root": {
              padding: "5px 10px",
            },
            ["@media (max-width:775px)"]: {
              width: "326px !important",
              position: "relative !important",
              transform: "translate3d(-19px, 0px, 0px) !important",
              inset: "0px auto auto 19px !important",
            },
            ["@media (max-width:575px)"]: {
              width: "250px !important",
            },
          }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                paddingRight: "5px",
                borderRadius: "0 0 10px 10px",
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose2}>
                  <MenuList
                    sx={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#C2C2C2",
                        borderRadius: "8px",
                      },
                      "&::-webkit-scrollbar": {
                        width: "14px",
                        height: "8px",
                        backgroundColor: "transparent",
                        border: "2px solid #F0F0F0",
                        borderRadius: "8px",
                      },
                      "&::-webkit-scrollbar-track": {
                        margin: "5px",
                      },
                    }}
                    autoFocusItem={open2}
                    id="composition-menu-2"
                    aria-labelledby="composition-button-2"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={async (event) => {
                        await setRecent([]);
                        await setBest([]);

                        handleCloseMenuItem(event, "All Characters");
                        await setCharacterId("0");

                        await dispatch(
                          getRecentActivityMyChat({
                            characterId: null,
                            characterdaysago: "30",
                            user: decodeURIComponent(currentUser),
                          })
                        );

                        await dispatch(
                          getBestActivityMyChat({
                            characterId: null,
                            characterdaysago: "30",
                            user: decodeURIComponent(currentUser),
                          })
                        );
                      }}
                      sx={{
                        fontWeight: 600,
                        padding: "15px 10px !important",
                        "&:hover": {
                          backgroundColor: "rgb(229, 229, 229) !important",
                          borderRadius: "8px",
                        },
                      }}
                    >
                      All Characters
                    </MenuItem>

                    {sortedCharacters?.map((item: any) => {
                      return (
                        <MenuItem
                          onClick={(event) => {
                            handleCloseMenuItem(event, item.name);
                            setCharacterId(item?._id);
                            DropFunction(
                              item?._id,
                              characterdaysago,
                              currentUser
                            );
                          }}
                          sx={{
                            fontWeight: 600,
                            padding: "15px 10px !important",
                            "&:hover": {
                              backgroundColor: "rgb(229, 229, 229) !important",
                              borderRadius: "8px",
                            },
                          }}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Box>
  );
}
