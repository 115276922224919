import React, { useEffect, useRef, useState } from "react";
import "./BestAndRecentPage.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Conversation from "../../components/conversation/Conversation";
import LightBulbImage from "../../assets/images/light-bulb-turquoise.svg";
import StarImage from "../../assets/images/star.svg";
// import Ads from "../../components/Ads/Ads";
import BestAndRecentMobile from "./BestAndRecentMobile";
import { enablePageScroll } from "scroll-lock";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import DropdownOption from "./DropdownOption";
import { useDispatch, useSelector } from "react-redux";
// import { getAllCharacter } from "../../redux/ConversationSlice";

import {
  getBestActivity1,
  getRecentActivity1,
  getAllCharacter,
  getBestActivity1UpdatedState,
  getRecentActivity1UpdatedState,
} from "../../redux/BestRecentSlice";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import RectangleSkeleton from "../../components/Skeleton/RectangleSkeleton";

const BestAndRecentPage: React.FC = () => {
  useEffect(() => {
    document.title = "Fictiono.us - Best & Recent chats";
    fetch("/bestandrecent")
  }, []);

  const dispatch: any = useDispatch();
  const dropdownRef = useRef(null);
  const callOnce = useRef(0);

  const [showDropdown, setShowDropdown] = useState(false);
  const [ads, setads] = useState(true);
  const [characterId, setCharacterId] = React.useState<any>();

  const getAllCharacterData = useSelector(
    (state: any) => state.BestRecentSlice.getAllCharacterData1
  );

  const RecentActivity = useSelector(
    (state: any) => state.BestRecentSlice.getRecentActivityData1
  );

  const BestActivity = useSelector(
    (state: any) => state.BestRecentSlice.getBestActivityData1
  );
  // getBestActivity1UpdatedState
  const BestActivityUpdated = useSelector(
    (state: any) => state.BestRecentSlice.bestUpdatedState
  );
  const RecentActivityUpdated = useSelector(
    (state: any) => state.BestRecentSlice.recentUpdatedState
  );

  const status = useSelector((state: any) => state.BestRecentSlice);

  useEffect(() => {
    if (callOnce.current < 1) {
      if (RecentActivityUpdated.length === 0) {
        dispatch(getRecentActivity1({ characterId: null }));
      }

      if (BestActivityUpdated.length === 0) {
        dispatch(getBestActivity1({ characterId: null }));
      }

      dispatch(getAllCharacter());
      callOnce.current += 1;
    }
  }, []);

  const isKeyboardOpenLib = useDetectKeyboardOpen();

  useEffect(() => {
    enablePageScroll();
  }, [isKeyboardOpenLib]);

  useEffect(() => {
    //@ts-ignore
    const handleClickOutside = (event) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    if (showDropdown) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  const RecentSee = () => {
    if (RecentActivity?.cursor) {
      dispatch(
        getRecentActivity1({
          characterId: characterId,
          SeeMoreId: RecentActivity?.cursor,
        })
      );
    }
  };

  const BestSee = () => {
    if (BestActivity?.cursor) {
      dispatch(
        getBestActivity1({
          characterId: characterId,
          SeeMoreId: BestActivity?.cursor,
        })
      );
    }
  };

  const [recent, setRecent] = useState<any[]>(RecentActivityUpdated);
  const [best, setBest] = useState<any[]>(BestActivityUpdated);

  useEffect(() => {
    setRecent(RecentActivityUpdated);
  }, [RecentActivityUpdated]);

  useEffect(() => {
    setBest(BestActivityUpdated);
  }, [BestActivityUpdated]);
  
  useEffect(() => {
    if (RecentActivity?.conversations) {
      setRecent((prevRecent) => {
        const existingIds = new Set(
          prevRecent.map((conversation) => conversation._id)
        );
        const uniqueConversations = RecentActivity.conversations.filter(
          (conversation: { _id: any }) => !existingIds.has(conversation._id)
        );
        dispatch(getRecentActivity1UpdatedState( [...prevRecent, ...uniqueConversations]))
        return [...prevRecent, ...uniqueConversations];
      });
    }
  }, [RecentActivity]);

  useEffect(() => {
    if (BestActivity?.conversations) {
      setBest((prevBest) => {
        const existingIds = new Set(
          prevBest.map((conversation) => conversation._id)
        );
        const uniqueConversations = BestActivity.conversations.filter(
          (conversation: { _id: any }) => !existingIds.has(conversation._id)
        );

        dispatch(getBestActivity1UpdatedState( [...prevBest, ...uniqueConversations]))
        return [...prevBest, ...uniqueConversations];
      });
    }
  }, [BestActivity]);

  return (
    <div className="container">
      <Header />

      <div className="best-recent-page-main">
        <div
          className={
            ads
              ? "best-recent-page character-list-recent-page"
              : "best-recent-page-noAds"
          }
        >
          <div className="scrool-dropdown-content">
            <DropdownOption
              getAllCharacter={getAllCharacterData}
              setCharacterId={setCharacterId}
              characterId={characterId}
              setBest={setBest}
              setRecent={setRecent}
            />
          </div>

          <div
            className={`main-content-best-recentpage ${
              ads ? "" : "main-content-best-recentpage-noAds"
            }`}
          >
            <div
              className={`best-recent-frame ${
                ads ? "" : "best-recent-frame-noAds"
              }`}
            >
              <div className="recentorbest-header">
                <img src={LightBulbImage} alt="light-bulb" />
                <div className="subtitle">Recent Chats</div>
              </div>
              <div className="frame-conversation-overview">
                {recent.length < 0
                  ? Array.from({ length: 8 }, (_, index) => (
                      <RectangleSkeleton />
                    ))
                  : recent?.map((conversation: any) => (
                      <Conversation
                        id={conversation._id}
                        avatar={
                          conversation.avatar
                            ? conversation.avatar
                            : DefaultImage
                        }
                        content={
                          conversation.content ? conversation.content : "N/A"
                        }
                        score={conversation.score}
                        emojiImage={conversation.emojiImage}
                        characterName={conversation.characterName}
                        characterId={conversation.characterId}
                        promptId={conversation.promptId}
                        visible={conversation.visible}
                        userId={conversation.userId}
                      />
                    ))}

                <div
                  className="see-more"
                  style={{
                    cursor:
                      RecentActivity?.cursor !== null
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={RecentSee}
                >
                  <div
                    className="see-more-btn"
                    style={{
                      cursor:
                        RecentActivity?.cursor !== null
                          ? "pointer"
                          : "not-allowed",
                    }}
                    onClick={RecentSee}
                  >
                    {status.recentStatus ? "Loading" : "See More"}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`best-recent-frame ${
                ads ? "" : "best-recent-frame-noAds"
              }`}
            >
              <div className="recentorbest-header">
                <img src={StarImage} alt="start" />
                <div className="subtitle">Best Chats</div>
              </div>
              <div className="frame-conversation-overview">
                {best.length < 0
                  ? Array.from({ length: 8 }, (_, index) => (
                      <RectangleSkeleton />
                    ))
                  : best?.map((conversation: any) => (
                      <Conversation
                        id={conversation._id}
                        avatar={
                          conversation.avatar
                            ? conversation.avatar
                            : DefaultImage
                        }
                        content={
                          conversation.content ? conversation.content : "N/A"
                        }
                        score={conversation.score}
                        emojiImage={conversation.emojiImage}
                        characterName={conversation.characterName}
                        characterId={conversation.characterId}
                        promptId={conversation.promptId}
                        visible={conversation.visible}
                        userId={conversation.userId}
                      />
                    ))}
                <div
                  className="see-more"
                  style={{
                    cursor:
                      BestActivity?.cursor !== null ? "pointer" : "not-allowed",
                  }}
                  onClick={BestSee}
                >
                  <div
                    className="see-more-btn"
                    style={{
                      cursor:
                        BestActivity?.cursor !== null
                          ? "pointer"
                          : "not-allowed",
                    }}
                    onClick={BestSee}
                  >
                    {status.bestStatus ? "Loading" : "See More"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Ads section */}

        {/* <div className={ads ? "best-recent-page-ads" : "best-recent-noads"}>
          <Ads />
        </div> */}
      </div>

      <div className="best-resent-commontab best-recent-page-for-dropdown">
        <BestAndRecentMobile
          best={best}
          recent={recent}
          BestSee={BestSee}
          RecentSee={RecentSee}
          bestStatus={status.bestStatus}
          recentStatus={status.recentStatus}
        />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BestAndRecentPage;
