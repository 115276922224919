import React from "react";
import "./HomePage.css";
import Header from "../../components/header/Header";
import SummaryBestAndRecentPage from "../bestandrecent/BestAndRecentOverview";
import Footer from "../../components/footer/Footer";
import PopularCharactersPage from "../characters/PopularCharacters";

const HomePage: React.FC = () => {
  React.useEffect(() => {
    document.title = `Fictiono.us - delightfully short AI chat`;
  }, []);

  return (
    <div className="home-page">
      <Header />
      <div className="main-content">
        <SummaryBestAndRecentPage />
        <PopularCharactersPage />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
