import React from "react";
import "./AdminNavigation.css";
import Logo from "../../assets/images/logo.svg";
import AdminCharsImage from "../../assets/images/admin-characters.png";
import AdminPromptsImage from "../../assets/images/admin-prompts.png";
import AdminChatsImage from "../../assets/images/admin-chats.png";
import AdminGlobalConfigImage from "../../assets/images/admin-globalconfig.png";
import AdminReportsImage from "../../assets/images/admin-reports.png";
import AdminLogoutImage from "../../assets/images/admin-logout.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AdminNavigation: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="admin-navigation">
      <div className="logo" onClick={() => navigate("/")}>
        <img src={Logo} alt="logo" className="logo" />
      </div>

      <div className="space"></div>

      <div className="tabs">
        <Tab
          title="Characters"
          icon={AdminCharsImage}
          to="/administration/listcharacters"
        />
        <Tab
          title="Prompts"
          icon={AdminPromptsImage}
          to="/administration/listprompts"
        />
        <Tab
          title="Chats"
          icon={AdminChatsImage}
          to="/administration/listcharacters"
        />
        <Tab
          title="Global Config"
          icon={AdminGlobalConfigImage}
          to="/administration/globalconfiguration"
        />
        <Tab
          title="Reports"
          icon={AdminReportsImage}
          to="/administration/listcharacters"
        />
        <Tab title="Log Out" icon={AdminLogoutImage} to="/" />
      </div>
    </div>
  );
};

function Tab(props: { title: string; icon: string; to: string }) {
  return (
    <div className="tab">
      <img className="icon" src={props.icon} alt="icon-chars" />
      <Link className="tab-text" to={props.to}>
        <div className="navtext inter-semi-bold-white-14px">{props.title}</div>
      </Link>
    </div>
  );
}

export default AdminNavigation;
