import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <p className="footer-text valign-text-middle secondaryregular>">
        <span>
          <span className="secondaryregular">
          Remember: you are chatting with fictional characters and these conversations are public. &nbsp;
          </span>
        </span>
        <span
          className="span1 secondaryregular"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://bit.ly/fic-TOS", "_blank")}
        >
          Terms of Service
        </span>
      </p>
    </div>
  );
};

export default Footer;
