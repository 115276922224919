import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Globalconfig } from "../datamodel/GlobalConfig";
import { toast } from "react-toastify";
import apiClient from "../utils/interceptors/axioInterceptor";

interface GlobalConfigState {
  data: Globalconfig | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
}

const initialState: GlobalConfigState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchGlobalConfig = createAsyncThunk(
  "globalConfig/fetchGlobalConfig",
  async () => {
    const response = await apiClient.get("/globalconfig");
    return response.data;
  }
);

export const updateGlobalConfig = createAsyncThunk(
  "globalConfig/updateGlobalConfig",
  async (config: Globalconfig) => {
    try {
      const response = await apiClient.put("/globalconfig", config);
      if (response.status >= 200 && response.status < 300) {
        toast.success("Global config created successfully!");
      } else {
        toast.error("Failed to create global config.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to create global config.");
      return error;
    }
  }
);

export const globalConfigSlice = createSlice({
  name: "globalConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalConfig.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchGlobalConfig.fulfilled, (state, action) => {
      state.status = "succeed";
      state.data = action.payload;
    });
    builder.addCase(fetchGlobalConfig.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });
  },
});

export default globalConfigSlice.reducer;
