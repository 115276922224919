import React from "react";
import "./AdministrationPage.css";
import { Link } from "react-router-dom";
import Select from "react-select";

export function PageTitle(props: { title: string }) {
  return <h1 className="title titleh2">{props.title}</h1>;
}

export function ItemSingleInput(props: {
  title: string;
  defaultInput: string;
}) {
  return (
    <div className="item-simple-input ">
      <div className="text-title bodybold ">{props.title}</div>
      <InputDefaultWithoutIcon defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemWithSubtitle(props: {
  title: string;
  subtitle: string;
  defaultInput: string;
}) {
  return (
    <div className="item-simple-input">
      <TextGroup title={props.title} subtitle={props.subtitle} />
      <InputDefaultWithoutIcon defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemStringWithSubtitle(props: {
  title: string;
  subtitle: string;
  defaultInput: string | undefined;
}) {
  return (
    <div className="item-simple-input">
      <TextGroup title={props.title} subtitle={props.subtitle} />
      <InputStringDefaultWithoutIcon defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemNumberWithSubtitle(props: {
  title: string;
  subtitle: string;
  defaultInput: number | undefined;
}) {
  return (
    <div className="item-simple-input">
      <TextGroup title={props.title} subtitle={props.subtitle} />
      <InputNumberDefaultWithoutIcon defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemWithLargeInput(props: {
  title: string;
  defaultInput: string;
}) {
  return (
    <div className="item-simple-input">
      <div className="text-title bodybold">{props.title}</div>
      <InputDefaultLarge defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemWithLargeInputAndSubtitle(props: {
  title: string;
  subtitle: string;
  defaultInput: string;
}) {
  return (
    <div className="item-simple-input">
      <TextGroup title={props.title} subtitle={props.subtitle} />
      <InputDefaultLarge defaultInput={props.defaultInput} />
    </div>
  );
}

export function ItemWithInputAndDropdown(props: {
  title: string;
  defaultInput: string;
  dropdown: string[];
}) {
  return (
    <div className="item-title-input-dropdown">
      <div className="text-title bodybold">{props.title}</div>
      <InputDefaultWithoutIcon defaultInput={props.defaultInput} />
      <DropDown dropdown={props.dropdown} />
    </div>
  );
}

export function ItemWithDropdown(props: { title: string; dropdown: any[] }) {
  return (
    <div className="item-title-input-dropdown">
      <div className="text-title bodybold">{props.title}</div>
      <Select
        className="dropdown-content"
        options={props.dropdown}
        isDisabled={true}
        value={props.dropdown[0]}
      />
    </div>
  );
}

export function ItemWithSubtitleAndDropdown(props: {
  title: string;
  subtitle: string;
  dropdown: any[];
}) {
  return (
    <div className="item-title-input-dropdown">
      <TextGroup title={props.title} subtitle={props.subtitle} />
      <Select
        className="dropdown-content"
        options={props.dropdown}
        isDisabled={true}
        value={props.dropdown[0]}
      />
    </div>
  );
}

export function ItemWithButtons(props: {
  title: string;
  buttontitle: string[];
}) {
  return (
    <div className="item-no-input">
      <div className="text-title bodybold">{props.title}</div>
      {props.buttontitle.map((item, index) => (
        <div className="button" key={item + index}>
          <div className="button-label">{item}</div>
        </div>
      ))}
    </div>
  );
}

export function DropDown(props: { dropdown: string[] }) {
  return (
    <div className="dropdown">
      <div className="dropdown-content">
        {props.dropdown.map((item) => (
          <div className="dropdown-item" key={item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export function SingleDropdown(props: { dropdown: any[] }) {
  return (
    <div className="item-title-input-dropdown">
      <Select
        className="dropdown-content"
        options={props.dropdown}
        isDisabled={true}
        value={props.dropdown[0]}
      />
    </div>
  );
}

export function TextGroup(props: { title: string; subtitle: string }) {
  return (
    <div className="text-group">
      <div className="text-title bodybold">{props.title}</div>
      <p className="text-subtitle">{props.subtitle}</p>
    </div>
  );
}

export function InputDefaultWithoutIcon(props: { defaultInput: string }) {
  return (
    <div className="input-default-without-icon">
      <div className="input-label">{props.defaultInput}</div>
    </div>
  );
}

export function InputStringDefaultWithoutIcon(props: {
  defaultInput: string | undefined;
}) {
  return (
    <div className="input-default-without-icon">
      <div className="input-label">{props.defaultInput}</div>
    </div>
  );
}

export function InputNumberDefaultWithoutIcon(props: {
  defaultInput: number | undefined;
}) {
  return (
    <div className="input-default-without-icon">
      <div className="input-label">{props.defaultInput}</div>
    </div>
  );
}

export function InputDefaultLarge(props: { defaultInput: string }) {
  return (
    <div className="input-default-large">
      <p className="input-label">{props.defaultInput}</p>
    </div>
  );
}

export function SaveChanges() {
  return (
    <div className="button-action">
      <div className="button-save bodybold">Save Changes</div>
    </div>
  );
}

export function AddACharacter() {
  return (
    <div className="button-action">
      <Link to="/administration/addcharacter" className="button-save bodybold">
        Add Character
      </Link>
    </div>
  );
}

export function AddAPrompt() {
  return (
    <div className="button-action">
      <Link to="/administration/addprompt" className="button-save bodybold">
        Add Prompt
      </Link>
    </div>
  );
}
