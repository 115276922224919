import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import ConversationMessageProps from "../datamodel/ConversationMessageProps";

interface ConversationMessageState {
  conversationMessages: ConversationMessageProps[];
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
  GetCharacterByIdData: any;
  GetConversationByIdData: any;
}

const initialState: ConversationMessageState = {
  conversationMessages: [],
  status: "idle",
  error: null,
  GetCharacterByIdData: [],
  GetConversationByIdData: [],
};

export const fetchConversationMessages = createAsyncThunk(
  "conversations/fetchConversations",
  async (conversationId: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/messages?conversationId=${conversationId}`
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch conversations.");
      throw error;
    }
  }
);

export const conversationMessageSlice = createSlice({
  name: "conversationsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConversationMessages.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchConversationMessages.fulfilled, (state, action) => {
      state.status = "succeed";
      state.conversationMessages = action.payload;
    });
    builder.addCase(fetchConversationMessages.rejected, (state, action) => {
      state.status = "failed";
      state.error = "Failed to fetch conversations.";
    });
  },
});

export default conversationMessageSlice.reducer;
