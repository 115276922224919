import React from "react";
import "./preview.css";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchConversationMessages } from "../../redux/ConversationMessageSlice";
// import { getConversationById } from "../../redux/ConversationSlice";

function PreviewNew(props: any) {
  const { ConversionMessages, sourceImg, sourceName } = props;

  return (
    <>
      <div className="container-preview" id="new-image-container">
        <img
          alt="Your Image Description"
          src={sourceImg}
          className="profile-pic"
        />
        <div className="contents-position">
          <div className="contents-container">
            <h1 className="contents-header">{sourceName}</h1>

            <div className="contents-chats">
              {ConversionMessages?.map((item: any, index: number) => {
                return (
                  <>
                    {item.role === "assistant" ? (
                      <div className="user-container-ai fontCss" key={index}>
                        {item.content}
                      </div>
                    ) : (
                      <div className="user-container fontCss" key={index}>
                        {item.content}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {/* fiction-logo */}
        <div className="site-logo">
          <svg
            width="187"
            height="54"
            viewBox="0 0 187 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2285_1975)">
              <path
                d="M41.0007 44.2379L0 28.1752V32.9406C0 35.5728 1.72222 38.3806 3.84642 39.2116L11.6207 42.2576V51.1243L24.3589 47.2473L41.0007 53.767C43.1249 54.5998 44.8471 53.1411 44.8471 50.5089C44.8471 47.8767 43.1249 45.0689 41.0007 44.2379Z"
                fill="#26DAF0"
              />
              <path
                d="M31.3171 34.7453L0 22.4779V18.8912C0 15.6088 2.14845 13.7903 4.79936 14.8282L36.1165 27.0956V30.6822C36.1165 33.9647 33.968 35.7832 31.3171 34.7453Z"
                fill="#FC1D89"
              />
              <path
                d="M43.8815 20.8124L8.58105 6.9838C8.58105 1.72114 12.0255 -1.19619 16.2756 0.469368L36.1869 8.26861C40.437 9.93243 43.8815 15.5498 43.8815 20.8124Z"
                fill="#947F37"
              />
              <path
                d="M42.3661 23.8461L7.06567 10.0175C7.06567 4.75483 10.5101 1.83751 14.7602 3.50306L34.6715 11.3023C38.9216 12.9661 42.3661 18.5834 42.3661 23.8461Z"
                fill="#FFD850"
              />
              <mask
                id="path-5-outside-1_2285_1975"
                maskUnits="userSpaceOnUse"
                x="8.00244"
                y="9.4502"
                width="18"
                height="31"
                fill="black"
              >
                <rect
                  fill="white"
                  x="8.00244"
                  y="9.4502"
                  width="18"
                  height="31"
                />
                <path d="M12.3571 18.8774H13.4763V17.4604C13.4763 15.9861 13.6635 14.8161 14.036 13.9468C14.4085 13.0775 15.0392 12.4447 15.9297 12.0465C16.8186 11.6501 18.0383 11.4502 19.5873 11.4502C22.33 11.4502 23.7005 12.123 23.7005 13.4704C23.7005 13.9085 23.5567 14.2823 23.2709 14.5935C22.9832 14.9047 22.6454 15.0595 22.2555 15.0595C22.0719 15.0595 21.7565 15.0247 21.3095 14.9552C20.8625 14.8856 20.4831 14.8508 20.1729 14.8508C19.3239 14.8508 18.7782 15.1012 18.5373 15.6019C18.2965 16.1026 18.1752 16.8189 18.1752 17.7525V18.8756H19.3291C21.1189 18.8756 22.0147 19.4163 22.0147 20.4995C22.0147 21.2714 21.7756 21.7599 21.3009 21.9668C20.8244 22.1737 20.1677 22.278 19.3309 22.278H18.1769V34.7645C18.1769 35.6963 17.9552 36.4074 17.5151 36.8977C17.0733 37.3862 16.5032 37.6314 15.8033 37.6314C15.1033 37.6314 14.5835 37.3862 14.1417 36.8977C13.6999 36.4092 13.4798 35.6981 13.4798 34.7645V22.278H12.189C11.489 22.278 10.9502 22.1198 10.5707 21.8034C10.1913 21.487 10.0024 21.0749 10.0024 20.569C10.0024 19.4407 10.7891 18.8756 12.3605 18.8756L12.3571 18.8774Z" />
              </mask>
              <path
                d="M12.3571 18.8774H13.4763V17.4604C13.4763 15.9861 13.6635 14.8161 14.036 13.9468C14.4085 13.0775 15.0392 12.4447 15.9297 12.0465C16.8186 11.6501 18.0383 11.4502 19.5873 11.4502C22.33 11.4502 23.7005 12.123 23.7005 13.4704C23.7005 13.9085 23.5567 14.2823 23.2709 14.5935C22.9832 14.9047 22.6454 15.0595 22.2555 15.0595C22.0719 15.0595 21.7565 15.0247 21.3095 14.9552C20.8625 14.8856 20.4831 14.8508 20.1729 14.8508C19.3239 14.8508 18.7782 15.1012 18.5373 15.6019C18.2965 16.1026 18.1752 16.8189 18.1752 17.7525V18.8756H19.3291C21.1189 18.8756 22.0147 19.4163 22.0147 20.4995C22.0147 21.2714 21.7756 21.7599 21.3009 21.9668C20.8244 22.1737 20.1677 22.278 19.3309 22.278H18.1769V34.7645C18.1769 35.6963 17.9552 36.4074 17.5151 36.8977C17.0733 37.3862 16.5032 37.6314 15.8033 37.6314C15.1033 37.6314 14.5835 37.3862 14.1417 36.8977C13.6999 36.4092 13.4798 35.6981 13.4798 34.7645V22.278H12.189C11.489 22.278 10.9502 22.1198 10.5707 21.8034C10.1913 21.487 10.0024 21.0749 10.0024 20.569C10.0024 19.4407 10.7891 18.8756 12.3605 18.8756L12.3571 18.8774Z"
                fill="white"
              />
              <path
                d="M12.3571 18.8774H13.4763V17.4604C13.4763 15.9861 13.6635 14.8161 14.036 13.9468C14.4085 13.0775 15.0392 12.4447 15.9297 12.0465C16.8186 11.6501 18.0383 11.4502 19.5873 11.4502C22.33 11.4502 23.7005 12.123 23.7005 13.4704C23.7005 13.9085 23.5567 14.2823 23.2709 14.5935C22.9832 14.9047 22.6454 15.0595 22.2555 15.0595C22.0719 15.0595 21.7565 15.0247 21.3095 14.9552C20.8625 14.8856 20.4831 14.8508 20.1729 14.8508C19.3239 14.8508 18.7782 15.1012 18.5373 15.6019C18.2965 16.1026 18.1752 16.8189 18.1752 17.7525V18.8756H19.3291C21.1189 18.8756 22.0147 19.4163 22.0147 20.4995C22.0147 21.2714 21.7756 21.7599 21.3009 21.9668C20.8244 22.1737 20.1677 22.278 19.3309 22.278H18.1769V34.7645C18.1769 35.6963 17.9552 36.4074 17.5151 36.8977C17.0733 37.3862 16.5032 37.6314 15.8033 37.6314C15.1033 37.6314 14.5835 37.3862 14.1417 36.8977C13.6999 36.4092 13.4798 35.6981 13.4798 34.7645V22.278H12.189C11.489 22.278 10.9502 22.1198 10.5707 21.8034C10.1913 21.487 10.0024 21.0749 10.0024 20.569C10.0024 19.4407 10.7891 18.8756 12.3605 18.8756L12.3571 18.8774Z"
                stroke="#0D2F62"
                stroke-width="4"
                mask="url(#path-5-outside-1_2285_1975)"
              />
              <mask
                id="path-6-outside-2_2285_1975"
                maskUnits="userSpaceOnUse"
                x="22.9031"
                y="18.163"
                width="9"
                height="30"
                fill="black"
              >
                <rect
                  fill="white"
                  x="22.9031"
                  y="18.163"
                  width="9"
                  height="30"
                />
                <path d="M27.2785 24.7771C26.6236 24.7771 26.0657 24.5755 25.5996 24.1721C25.1352 23.7688 24.9031 23.1985 24.9031 22.4614C24.9031 21.7937 25.1404 21.2444 25.6169 20.8115C26.0934 20.3803 26.6461 20.163 27.2785 20.163C27.9109 20.163 28.4255 20.3594 28.8968 20.7506C29.3663 21.1418 29.6019 21.712 29.6019 22.4596C29.6019 23.2072 29.3715 23.7531 28.9141 24.1599C28.4549 24.5685 27.9092 24.7737 27.2785 24.7737V24.7771ZM29.6019 29.6469V43.2738C29.6019 44.2179 29.3784 44.9324 28.9314 45.4158C28.4844 45.8991 27.9161 46.1407 27.2265 46.1407C26.5369 46.1407 25.979 45.8939 25.5476 45.3984C25.1162 44.9029 24.9031 44.1953 24.9031 43.2738V29.786C24.9031 28.8541 25.1179 28.1517 25.5476 27.6788C25.9773 27.2077 26.5369 26.9712 27.2265 26.9712C27.9161 26.9712 28.4827 27.2077 28.9314 27.6788C29.3784 28.1517 29.6019 28.8072 29.6019 29.6469Z" />
              </mask>
              <path
                d="M27.2785 24.7771C26.6236 24.7771 26.0657 24.5755 25.5996 24.1721C25.1352 23.7688 24.9031 23.1985 24.9031 22.4614C24.9031 21.7937 25.1404 21.2444 25.6169 20.8115C26.0934 20.3803 26.6461 20.163 27.2785 20.163C27.9109 20.163 28.4255 20.3594 28.8968 20.7506C29.3663 21.1418 29.6019 21.712 29.6019 22.4596C29.6019 23.2072 29.3715 23.7531 28.9141 24.1599C28.4549 24.5685 27.9092 24.7737 27.2785 24.7737V24.7771ZM29.6019 29.6469V43.2738C29.6019 44.2179 29.3784 44.9324 28.9314 45.4158C28.4844 45.8991 27.9161 46.1407 27.2265 46.1407C26.5369 46.1407 25.979 45.8939 25.5476 45.3984C25.1162 44.9029 24.9031 44.1953 24.9031 43.2738V29.786C24.9031 28.8541 25.1179 28.1517 25.5476 27.6788C25.9773 27.2077 26.5369 26.9712 27.2265 26.9712C27.9161 26.9712 28.4827 27.2077 28.9314 27.6788C29.3784 28.1517 29.6019 28.8072 29.6019 29.6469Z"
                fill="white"
              />
              <path
                d="M27.2785 24.7771C26.6236 24.7771 26.0657 24.5755 25.5996 24.1721C25.1352 23.7688 24.9031 23.1985 24.9031 22.4614C24.9031 21.7937 25.1404 21.2444 25.6169 20.8115C26.0934 20.3803 26.6461 20.163 27.2785 20.163C27.9109 20.163 28.4255 20.3594 28.8968 20.7506C29.3663 21.1418 29.6019 21.712 29.6019 22.4596C29.6019 23.2072 29.3715 23.7531 28.9141 24.1599C28.4549 24.5685 27.9092 24.7737 27.2785 24.7737V24.7771ZM29.6019 29.6469V43.2738C29.6019 44.2179 29.3784 44.9324 28.9314 45.4158C28.4844 45.8991 27.9161 46.1407 27.2265 46.1407C26.5369 46.1407 25.979 45.8939 25.5476 45.3984C25.1162 44.9029 24.9031 44.1953 24.9031 43.2738V29.786C24.9031 28.8541 25.1179 28.1517 25.5476 27.6788C25.9773 27.2077 26.5369 26.9712 27.2265 26.9712C27.9161 26.9712 28.4827 27.2077 28.9314 27.6788C29.3784 28.1517 29.6019 28.8072 29.6019 29.6469Z"
                stroke="#0D2F62"
                stroke-width="4"
                mask="url(#path-6-outside-2_2285_1975)"
              />
              <mask
                id="path-7-outside-3_2285_1975"
                maskUnits="userSpaceOnUse"
                x="31.5417"
                y="21.9028"
                width="22"
                height="24"
                fill="black"
              >
                <rect
                  fill="white"
                  x="31.5417"
                  y="21.9028"
                  width="22"
                  height="24"
                />
                <path d="M51.3063 37.269C51.3063 37.8566 51.1313 38.4843 50.7813 39.1519C50.4313 39.8195 49.8977 40.4558 49.1804 41.0608C48.4631 41.6658 47.5587 42.1526 46.4688 42.5195C45.379 42.8881 44.1506 43.0723 42.7853 43.0723C39.8814 43.0723 37.6151 42.2239 35.9865 40.5253C34.3578 38.8267 33.5417 36.551 33.5417 33.6945C33.5417 31.7594 33.9143 30.0504 34.661 28.5657C35.406 27.0809 36.4855 25.9317 37.8976 25.1198C39.3096 24.3079 40.9955 23.9028 42.9585 23.9028C44.1748 23.9028 45.2906 24.0819 46.306 24.4383C47.3213 24.7947 48.1824 25.2554 48.8876 25.8205C49.5927 26.3838 50.1316 26.9853 50.5058 27.6251C50.8783 28.2649 51.0655 28.8595 51.0655 29.4124C51.0655 29.9652 50.8558 30.4538 50.4365 30.8467C50.0172 31.2379 49.5096 31.4343 48.9136 31.4343C48.5237 31.4343 48.1997 31.3335 47.9416 31.1318C47.6834 30.9301 47.394 30.605 47.0718 30.1565C46.4983 29.282 45.8988 28.6248 45.2733 28.1884C44.6478 27.7503 43.8526 27.5312 42.8892 27.5312C41.5014 27.5312 40.3821 28.0754 39.5331 29.1638C38.6842 30.2521 38.2597 31.7403 38.2597 33.6284C38.2597 34.5151 38.3688 35.3287 38.5871 36.0728C38.8055 36.8152 39.1208 37.4481 39.5331 37.9731C39.9455 38.4964 40.4462 38.8946 41.0301 39.164C41.6158 39.4352 42.2586 39.5691 42.9585 39.5691C43.8994 39.5691 44.705 39.3501 45.3773 38.9137C46.0478 38.4756 46.6421 37.8079 47.1584 36.9108C47.4443 36.3806 47.7544 35.9668 48.0871 35.6678C48.4198 35.3687 48.8269 35.2192 49.3086 35.2192C49.8821 35.2192 50.3586 35.4383 50.738 35.8764C51.1174 36.3145 51.3063 36.7804 51.3063 37.2759V37.269Z" />
              </mask>
              <path
                d="M51.3063 37.269C51.3063 37.8566 51.1313 38.4843 50.7813 39.1519C50.4313 39.8195 49.8977 40.4558 49.1804 41.0608C48.4631 41.6658 47.5587 42.1526 46.4688 42.5195C45.379 42.8881 44.1506 43.0723 42.7853 43.0723C39.8814 43.0723 37.6151 42.2239 35.9865 40.5253C34.3578 38.8267 33.5417 36.551 33.5417 33.6945C33.5417 31.7594 33.9143 30.0504 34.661 28.5657C35.406 27.0809 36.4855 25.9317 37.8976 25.1198C39.3096 24.3079 40.9955 23.9028 42.9585 23.9028C44.1748 23.9028 45.2906 24.0819 46.306 24.4383C47.3213 24.7947 48.1824 25.2554 48.8876 25.8205C49.5927 26.3838 50.1316 26.9853 50.5058 27.6251C50.8783 28.2649 51.0655 28.8595 51.0655 29.4124C51.0655 29.9652 50.8558 30.4538 50.4365 30.8467C50.0172 31.2379 49.5096 31.4343 48.9136 31.4343C48.5237 31.4343 48.1997 31.3335 47.9416 31.1318C47.6834 30.9301 47.394 30.605 47.0718 30.1565C46.4983 29.282 45.8988 28.6248 45.2733 28.1884C44.6478 27.7503 43.8526 27.5312 42.8892 27.5312C41.5014 27.5312 40.3821 28.0754 39.5331 29.1638C38.6842 30.2521 38.2597 31.7403 38.2597 33.6284C38.2597 34.5151 38.3688 35.3287 38.5871 36.0728C38.8055 36.8152 39.1208 37.4481 39.5331 37.9731C39.9455 38.4964 40.4462 38.8946 41.0301 39.164C41.6158 39.4352 42.2586 39.5691 42.9585 39.5691C43.8994 39.5691 44.705 39.3501 45.3773 38.9137C46.0478 38.4756 46.6421 37.8079 47.1584 36.9108C47.4443 36.3806 47.7544 35.9668 48.0871 35.6678C48.4198 35.3687 48.8269 35.2192 49.3086 35.2192C49.8821 35.2192 50.3586 35.4383 50.738 35.8764C51.1174 36.3145 51.3063 36.7804 51.3063 37.2759V37.269Z"
                fill="white"
              />
              <path
                d="M51.3063 37.269C51.3063 37.8566 51.1313 38.4843 50.7813 39.1519C50.4313 39.8195 49.8977 40.4558 49.1804 41.0608C48.4631 41.6658 47.5587 42.1526 46.4688 42.5195C45.379 42.8881 44.1506 43.0723 42.7853 43.0723C39.8814 43.0723 37.6151 42.2239 35.9865 40.5253C34.3578 38.8267 33.5417 36.551 33.5417 33.6945C33.5417 31.7594 33.9143 30.0504 34.661 28.5657C35.406 27.0809 36.4855 25.9317 37.8976 25.1198C39.3096 24.3079 40.9955 23.9028 42.9585 23.9028C44.1748 23.9028 45.2906 24.0819 46.306 24.4383C47.3213 24.7947 48.1824 25.2554 48.8876 25.8205C49.5927 26.3838 50.1316 26.9853 50.5058 27.6251C50.8783 28.2649 51.0655 28.8595 51.0655 29.4124C51.0655 29.9652 50.8558 30.4538 50.4365 30.8467C50.0172 31.2379 49.5096 31.4343 48.9136 31.4343C48.5237 31.4343 48.1997 31.3335 47.9416 31.1318C47.6834 30.9301 47.394 30.605 47.0718 30.1565C46.4983 29.282 45.8988 28.6248 45.2733 28.1884C44.6478 27.7503 43.8526 27.5312 42.8892 27.5312C41.5014 27.5312 40.3821 28.0754 39.5331 29.1638C38.6842 30.2521 38.2597 31.7403 38.2597 33.6284C38.2597 34.5151 38.3688 35.3287 38.5871 36.0728C38.8055 36.8152 39.1208 37.4481 39.5331 37.9731C39.9455 38.4964 40.4462 38.8946 41.0301 39.164C41.6158 39.4352 42.2586 39.5691 42.9585 39.5691C43.8994 39.5691 44.705 39.3501 45.3773 38.9137C46.0478 38.4756 46.6421 37.8079 47.1584 36.9108C47.4443 36.3806 47.7544 35.9668 48.0871 35.6678C48.4198 35.3687 48.8269 35.2192 49.3086 35.2192C49.8821 35.2192 50.3586 35.4383 50.738 35.8764C51.1174 36.3145 51.3063 36.7804 51.3063 37.2759V37.269Z"
                stroke="#0D2F62"
                stroke-width="4"
                stroke-linejoin="round"
                mask="url(#path-7-outside-3_2285_1975)"
              />
              <path
                d="M55.6604 25.8665H56.1767V23.0343C56.1767 22.2746 56.1958 21.6783 56.2374 21.2471C56.2772 20.8159 56.3899 20.4439 56.5735 20.1327C56.7572 19.811 57.0205 19.5485 57.3653 19.3468C57.7101 19.1451 58.093 19.0443 58.5192 19.0443C59.1153 19.0443 59.6558 19.2686 60.1375 19.7171C60.458 20.0162 60.6625 20.3813 60.7491 20.8142C60.8358 21.2453 60.8773 21.8591 60.8773 22.6536V25.8665H62.5978C63.2632 25.8665 63.7708 26.0247 64.1208 26.3411C64.4708 26.6575 64.6458 27.0644 64.6458 27.5581C64.6458 28.1909 64.3963 28.6343 63.8973 28.8881C63.3983 29.142 62.6845 29.2689 61.754 29.2689H60.8756V37.9391C60.8756 38.6763 60.9016 39.2431 60.9536 39.6395C61.0056 40.0359 61.1424 40.3592 61.3659 40.6061C61.5894 40.853 61.9533 40.9782 62.4592 40.9782C62.7347 40.9782 63.1072 40.9295 63.5785 40.8321C64.048 40.7348 64.4154 40.6861 64.6804 40.6861C65.0599 40.6861 65.3995 40.8391 65.7044 41.1433C66.0076 41.4493 66.1601 41.8248 66.1601 42.2751C66.1601 43.0349 65.7477 43.6173 64.9213 44.0189C64.0948 44.4223 62.908 44.624 61.359 44.624C59.8897 44.624 58.7774 44.3771 58.0202 43.8816C57.2631 43.3861 56.7658 42.7011 56.5319 41.8266C56.2963 40.9521 56.1785 39.7838 56.1785 38.3199V29.2706H55.5582C54.8807 29.2706 54.3644 29.1089 54.0092 28.7873C53.654 28.4656 53.4756 28.0571 53.4756 27.5616C53.4756 27.0661 53.6627 26.661 54.0352 26.3446C54.4077 26.0282 54.95 25.87 55.6622 25.87L55.6604 25.8665Z"
                fill="#FFD850"
              />
              <path
                d="M70.256 23.2603C69.6011 23.2603 69.0432 23.0586 68.5771 22.6553C68.1128 22.2519 67.8806 21.6817 67.8806 20.9445C67.8806 20.2769 68.118 19.7275 68.5945 19.2946C69.0709 18.8634 69.6236 18.6461 70.256 18.6461C70.8884 18.6461 71.403 18.8426 71.8743 19.2338C72.3438 19.6249 72.5795 20.1952 72.5795 20.9428C72.5795 21.6904 72.349 22.2363 71.8916 22.6431C71.4325 23.0517 70.8867 23.2568 70.256 23.2568V23.2603ZM72.5795 28.13V41.757C72.5795 42.701 72.356 43.4156 71.909 43.8989C71.4619 44.3822 70.8936 44.6239 70.2041 44.6239C69.5145 44.6239 68.9566 44.377 68.5252 43.8815C68.0955 43.386 67.8806 42.6784 67.8806 41.757V28.2691C67.8806 27.3372 68.0955 26.6349 68.5252 26.162C68.9548 25.6908 69.5145 25.4544 70.2041 25.4544C70.8936 25.4544 71.4602 25.6908 71.909 26.162C72.356 26.6349 72.5795 27.2903 72.5795 28.13Z"
                fill="#FFD850"
              />
              <path
                d="M95.0238 35.0374C95.0238 36.4422 94.8055 37.7374 94.3689 38.9231C93.9322 40.1088 93.3016 41.1276 92.4751 41.9795C91.6487 42.8314 90.6628 43.4851 89.5141 43.9389C88.3671 44.3944 87.0763 44.6204 85.6417 44.6204C84.2071 44.6204 82.9388 44.3892 81.8039 43.9302C80.6673 43.4695 79.6832 42.8106 78.8515 41.9534C78.0199 41.0963 77.3892 40.0827 76.9578 38.9144C76.5281 37.7461 76.3132 36.4526 76.3132 35.0374C76.3132 33.6222 76.5315 32.3026 76.9664 31.1169C77.403 29.9312 78.0285 28.9176 78.8429 28.0779C79.6572 27.2382 80.6448 26.5897 81.8039 26.1342C82.963 25.6787 84.2417 25.4526 85.6417 25.4526C87.0416 25.4526 88.3549 25.6839 89.5141 26.1429C90.6732 26.6036 91.666 27.259 92.4924 28.1109C93.3189 28.9628 93.9461 29.9764 94.3775 31.1499C94.8072 32.3252 95.0238 33.6205 95.0238 35.0357V35.0374ZM90.3076 35.0374C90.3076 33.1145 89.8866 31.6176 89.0428 30.5467C88.199 29.4757 87.0659 28.9402 85.6434 28.9402C84.7251 28.9402 83.916 29.1801 83.216 29.6565C82.516 30.1346 81.9772 30.8387 81.5977 31.7724C81.2183 32.7042 81.0294 33.7926 81.0294 35.0357C81.0294 36.2787 81.2165 37.3445 81.5891 38.2659C81.9616 39.1874 82.4952 39.8915 83.19 40.3818C83.8848 40.8721 84.7026 41.1154 85.6434 41.1154C87.0659 41.1154 88.199 40.5782 89.0428 39.5003C89.8866 38.4241 90.3076 36.9359 90.3076 35.0357V35.0374Z"
                fill="#FFD850"
              />
              <path
                d="M102.992 28.0605V28.6308C103.819 27.5372 104.721 26.734 105.704 26.2211C106.684 25.7082 107.812 25.4527 109.086 25.4527C110.359 25.4527 111.432 25.7239 112.407 26.2646C113.383 26.8053 114.11 27.572 114.594 28.5612C114.904 29.1367 115.105 29.7591 115.197 30.4267C115.289 31.0943 115.334 31.9462 115.334 32.9824V41.7553C115.334 42.6993 115.119 43.4139 114.689 43.8972C114.259 44.3805 113.7 44.6222 113.01 44.6222C112.321 44.6222 111.742 44.3753 111.305 43.8798C110.869 43.3843 110.652 42.6767 110.652 41.7553V33.8969C110.652 32.3426 110.437 31.1534 110.006 30.3311C109.576 29.5088 108.719 29.0967 107.433 29.0967C106.596 29.0967 105.832 29.3471 105.144 29.8478C104.456 30.3485 103.95 31.037 103.63 31.9115C103.399 32.6139 103.285 33.9265 103.285 35.8493V41.7553C103.285 42.7115 103.063 43.4278 102.623 43.9059C102.181 44.384 101.611 44.6222 100.911 44.6222C100.211 44.6222 99.6777 44.3753 99.2428 43.8798C98.8062 43.3843 98.5879 42.6767 98.5879 41.7553V28.1283C98.5879 27.2295 98.7819 26.5601 99.1735 26.1168C99.5634 25.6735 100.097 25.4509 100.774 25.4509C101.187 25.4509 101.561 25.5483 101.894 25.7447C102.226 25.9412 102.493 26.235 102.694 26.6262C102.895 27.0174 102.996 27.4955 102.996 28.0605H102.992Z"
                fill="#FFD850"
              />
              <path
                d="M137.605 35.0374C137.605 36.4422 137.386 37.7374 136.95 38.9231C136.513 40.1088 135.882 41.1276 135.056 41.9795C134.229 42.8314 133.244 43.4851 132.095 43.9389C130.948 44.3944 129.657 44.6204 128.222 44.6204C126.788 44.6204 125.52 44.3892 124.385 43.9302C123.25 43.4712 122.264 42.8106 121.432 41.9534C120.601 41.0963 119.97 40.0827 119.539 38.9144C119.109 37.7461 118.894 36.4526 118.894 35.0374C118.894 33.6222 119.112 32.3026 119.547 31.1169C119.984 29.9312 120.609 28.9176 121.424 28.0779C122.238 27.2382 123.226 26.5897 124.385 26.1342C125.544 25.6787 126.823 25.4526 128.222 25.4526C129.622 25.4526 130.936 25.6839 132.095 26.1429C133.254 26.6036 134.247 27.259 135.073 28.1109C135.9 28.9628 136.527 29.9764 136.958 31.1499C137.388 32.3252 137.605 33.6205 137.605 35.0357V35.0374ZM132.888 35.0374C132.888 33.1145 132.467 31.6176 131.624 30.5467C130.78 29.4757 129.647 28.9402 128.224 28.9402C127.306 28.9402 126.497 29.1801 125.797 29.6565C125.097 30.1346 124.558 30.8387 124.179 31.7724C123.799 32.7042 123.61 33.7926 123.61 35.0357C123.61 36.2787 123.797 37.3445 124.17 38.2659C124.542 39.1874 125.076 39.8915 125.771 40.3818C126.466 40.8721 127.283 41.1154 128.224 41.1154C129.647 41.1154 130.78 40.5782 131.624 39.5003C132.467 38.4241 132.888 36.9359 132.888 35.0357V35.0374Z"
                fill="#FFD850"
              />
              <path
                d="M142.664 46.4441C141.952 46.4441 141.339 46.2128 140.822 45.7538C140.306 45.2931 140.048 44.6481 140.048 43.8188C140.048 43.1164 140.294 42.5131 140.788 42.0055C141.281 41.4996 141.89 41.2457 142.612 41.2457C143.335 41.2457 143.951 41.4961 144.463 41.9968C144.974 42.4975 145.228 43.1043 145.228 43.8188C145.228 44.6359 144.974 45.2775 144.463 45.7452C143.951 46.2111 143.352 46.4441 142.664 46.4441Z"
                fill="#FFD850"
              />
              <path
                d="M160.663 41.9638V41.3588C160.1 42.0733 159.51 42.6714 158.891 43.1547C158.272 43.6381 157.595 43.9979 156.86 44.2344C156.126 44.4708 155.289 44.5891 154.348 44.5891C153.211 44.5891 152.194 44.3526 151.293 43.8815C150.392 43.4103 149.696 42.7583 149.202 41.9308C148.617 40.9294 148.324 39.4898 148.324 37.6139V28.2708C148.324 27.3268 148.535 26.6209 148.961 26.155C149.386 25.689 149.947 25.4561 150.647 25.4561C151.347 25.4561 151.933 25.6925 152.368 26.1637C152.804 26.6365 153.021 27.3389 153.021 28.2708V35.818C153.021 36.9115 153.113 37.8295 153.296 38.5719C153.48 39.3142 153.809 39.8967 154.286 40.3156C154.762 40.7364 155.407 40.9468 156.223 40.9468C157.039 40.9468 157.76 40.7103 158.46 40.2392C159.16 39.768 159.671 39.1508 159.991 38.391C160.255 37.7234 160.386 36.2613 160.386 34.0046V28.2708C160.386 27.3389 160.605 26.6365 161.039 26.1637C161.476 25.6925 162.043 25.4561 162.744 25.4561C163.446 25.4561 164.006 25.689 164.43 26.155C164.855 26.6209 165.068 27.3268 165.068 28.2708V41.9325C165.068 42.8314 164.863 43.5042 164.456 43.9527C164.049 44.4013 163.524 44.6256 162.881 44.6256C162.238 44.6256 161.708 44.3926 161.289 43.9267C160.87 43.4607 160.66 42.807 160.66 41.9673L160.663 41.9638Z"
                fill="#FFD850"
              />
              <path
                d="M184.878 38.4572C184.878 39.7576 184.563 40.872 183.932 41.7987C183.302 42.7254 182.37 43.4278 181.136 43.9059C179.902 44.384 178.402 44.6222 176.635 44.6222C174.867 44.6222 173.502 44.3631 172.298 43.845C171.094 43.3269 170.203 42.6802 169.63 41.9013C169.056 41.1241 168.769 40.3435 168.769 39.5612C168.769 39.0431 168.952 38.5997 169.32 38.2312C169.687 37.8626 170.151 37.6783 170.714 37.6783C171.208 37.6783 171.586 37.8 171.851 38.0417C172.114 38.2833 172.367 38.6223 172.608 39.0605C173.09 39.9019 173.667 40.5278 174.337 40.9433C175.008 41.3571 175.923 41.5657 177.082 41.5657C178.022 41.5657 178.794 41.3554 179.396 40.9346C179.999 40.5139 180.301 40.0341 180.301 39.4934C180.301 38.6641 179.987 38.059 179.364 37.68C178.738 37.2993 177.709 36.9377 176.274 36.5917C174.656 36.1883 173.339 35.7659 172.324 35.3225C171.309 34.8792 170.496 34.295 169.888 33.57C169.28 32.8451 168.975 31.9532 168.975 30.8926C168.975 29.9486 169.255 29.0567 169.819 28.2152C170.38 27.3755 171.21 26.7044 172.305 26.2037C173.4 25.703 174.724 25.4526 176.273 25.4526C177.489 25.4526 178.582 25.5796 179.551 25.8334C180.521 26.0872 181.328 26.4262 181.978 26.8522C182.626 27.2781 183.12 27.751 183.458 28.2691C183.796 28.7872 183.965 29.2949 183.965 29.7886C183.965 30.3293 183.785 30.7727 183.423 31.1186C183.061 31.4646 182.548 31.6367 181.883 31.6367C181.401 31.6367 180.99 31.4994 180.653 31.223C180.315 30.9465 179.927 30.5328 179.492 29.9799C179.137 29.5192 178.717 29.1506 178.236 28.8742C177.754 28.5977 177.099 28.4604 176.274 28.4604C175.45 28.4604 174.72 28.6412 174.157 29.0045C173.594 29.3679 173.313 29.8199 173.313 30.3606C173.313 30.8561 173.519 31.2612 173.933 31.5776C174.346 31.8941 174.904 32.1566 175.604 32.3635C176.304 32.5704 177.267 32.8242 178.495 33.1232C179.953 33.4796 181.143 33.9056 182.066 34.4011C182.99 34.8966 183.69 35.4807 184.166 36.1536C184.643 36.8264 184.88 37.5948 184.88 38.4589L184.878 38.4572Z"
                fill="#FFD850"
              />
            </g>
            <defs>
              <clipPath id="clip0_2285_1975">
                <rect width="187" height="54" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </>
  );
}

export default PreviewNew;
