import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Character from "../datamodel/Character";
import { toast } from "react-toastify";

interface CharactersState {
  characters: Character[];
  character: Character | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
  names: string[];
}

const initialState: CharactersState = {
  characters: [],
  character: null,
  status: "idle",
  error: null,
  names: [],
};

export const getCharacters = createAsyncThunk(
  "characters/getCharacters",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/characters?limit=1000`
    );
    const characterPrompt = response.data.nameAndPrompts;
    const characterData: Character[] = response.data.characters;
    characterData.forEach((character) => {
      if (characterPrompt[character.name]) {
        character.prompts = [];
        character.prompts.push(...characterPrompt[character.name]);
      }
    });
    return characterData;
  }
);

export const getCharacterNames = createAsyncThunk(
  "characters/getCharacterNames",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/characters?select=name`
    );
    return response.data.characters.map(
      (character: Character) => character.name
    );
  }
);

export const getCharacterById = createAsyncThunk(
  "character/getCharacter",
  async (id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/characters/${id}`
    );
    return response.data;
  }
);

export const saveCharacter = createAsyncThunk(
  "character/saveCharacter",
  async (character: Character) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/characters`,
        character
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Character saved successfully!");
      } else {
        toast.error("Failed to save character.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to save character.");
      return error;
    }
  }
);

export const deleteCharacter = createAsyncThunk(
  "characters/deleteCharacter",
  async (characterId: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API_URL}/characters/${characterId}`
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Character deleted successfully!");
      } else {
        toast.error("Failed to delete character.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to delete character.");
      return error;
    }
  }
);

export const updateCharacter = createAsyncThunk(
  "characters/updateCharacter",
  async ({
    characterId,
    character,
  }: {
    characterId: string | undefined;
    character: Character | undefined;
  }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_URL}/characters/${characterId}`,
        character
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Character updated successfully!");
      } else {
        toast.error("Failed to update character.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to update character.");
      return error;
    }
  }
);

export const GenerateAvatarUrl = createAsyncThunk(
  "GenerateAvatarUrl",
  async (props: any) => {
    const { file, handleClose } = props;
    const body = new FormData();
    body.append("image", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/users/uploadprofilepic/`,
        body
      );

      if (res.status >= 200 && res.status < 300) {
        handleClose();
        return res?.data?.imageUrl;
      } else {
        toast.error("Something is wrong : Upload Character Avatar");
      }
    } catch (error) {
      toast.error("Character Avatar error");
    }
  }
);

export const listCharactersSlice = createSlice({
  name: "characters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Characters
    builder.addCase(getCharacters.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCharacters.fulfilled, (state, action) => {
      state.status = "succeed";
      state.characters = action.payload;
    });
    builder.addCase(getCharacters.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get a Character
    builder.addCase(getCharacterById.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCharacterById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.character = action.payload;
    });
    builder.addCase(getCharacterById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Character Names
    builder.addCase(getCharacterNames.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCharacterNames.fulfilled, (state, action) => {
      state.status = "succeed";
      state.names = action.payload;
    });
    builder.addCase(getCharacterNames.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Save Character
    builder.addCase(saveCharacter.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      saveCharacter.fulfilled,
      (state, action: PayloadAction<Character>) => {
        state.status = "succeed";
        state.character = action.payload;
        state.characters.push(action.payload);
      }
    );
    builder.addCase(saveCharacter.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });
  },
});

export default listCharactersSlice.reducer;
