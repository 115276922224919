import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { SentMessage, ReceivedMessage } from "../datamodel/Message";
import { toast } from "react-toastify";

interface FictionoaiState {
  message: ReceivedMessage | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
}

const initialState: FictionoaiState = {
  message: null,
  status: "idle",
  error: null,
};

export const createMessage = createAsyncThunk(
  "fictionoai/createMessage",
  async (message: SentMessage) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/fictionoai`,
        message
      );
      if (response.status < 200 && response.status >= 300) {
        toast.error("Failed to create message.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to create message.");
      return error;
    }
  }
);

export const fictionoaiSlice = createSlice({
  name: "fictionoai",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create message
    builder.addCase(createMessage.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createMessage.fulfilled, (state, action) => {
      state.status = "succeed";
      state.message = action.payload;
    });
    builder.addCase(createMessage.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });
  },
});

export default fictionoaiSlice.reducer;
