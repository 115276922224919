import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Prompt } from "../datamodel/Prompt";
import { toast } from "react-toastify";

interface PromptsState {
  prompts: Prompt[];
  prompt: Prompt | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
}

const initialState: PromptsState = {
  prompts: [],
  prompt: null,
  status: "idle",
  error: null,
};

export const getPrompts = createAsyncThunk("prompts/getPrompts", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_API_URL}/prompts?limit=1000`
  );
  return response.data.prompts;
});

export const getPromptById = createAsyncThunk(
  "prompts/getPrompt",
  async (id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/prompts/${id}`
    );
    return response.data;
  }
);

export const savePrompt = createAsyncThunk(
  "prompts/savePrompt",
  async (prompt: Prompt) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/prompts`,
        prompt
      );
      if (response.status >= 200 && response.status < 300) {
        toast.success("Prompt saved successfully!");
      } else {
        toast.error("Failed to save prompt.");
      }

      return response.data;
    } catch (error) {
      toast.error("Failed to save prompt.");
      return error;
    }
  }
);

export const deletePrompt = createAsyncThunk(
  "prompts/deletePrompt",
  async (promptId: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_API_URL}/prompts/${promptId}`
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Prompt deleted successfully!");
      } else {
        toast.error("Failed to delete prompt.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to delete prompt.");
      return error;
    }
  }
);

export const updatePrompt = createAsyncThunk(
  "prompts/updatePrompt",
  async ({
    promptId,
    prompt,
  }: {
    promptId: string | undefined;
    prompt: Prompt | undefined;
  }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_API_URL}/prompts/${promptId}`,
        prompt
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("Prompt updated successfully!");
      } else {
        toast.error("Failed to update prompt.");
      }
      return response.data;
    } catch (error) {
      toast.error(
        "Failed to update prompt, please check whether the character exists."
      );
      return error;
    }
  }
);

export const listPromptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Prompts
    builder.addCase(getPrompts.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPrompts.fulfilled, (state, action) => {
      state.status = "succeed";
      state.prompts = action.payload;
    });
    builder.addCase(getPrompts.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Prompt
    builder.addCase(getPromptById.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPromptById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.prompt = action.payload;
    });
    builder.addCase(getPromptById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Save Prompt
    builder.addCase(savePrompt.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(
      savePrompt.fulfilled,
      (state, action: PayloadAction<Prompt>) => {
        state.status = "succeed";
        state.prompt = action.payload;
        state.prompts.push(action.payload);
      }
    );
    builder.addCase(savePrompt.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });
  },
});

export default listPromptsSlice.reducer;
