import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../pages/counter/counterSlice";
import globalConfigReducer from "../redux/GlobalConfigSlice";
import charactersReducer from "../redux/CharactersSlice";
import promptsReducer from "../redux/PromptsSlice";
import conversationReducer from "../redux/ConversationSlice";
import fictionoaiReducer from "../redux/FictionoaiSlice";
import conversationMessagesReducer from "../redux/ConversationMessageSlice";
import conversationRateReducer from "../redux/ConversationRateSlice";
import LoginSlice from "../redux/LoginSlice";
import BestRecentSlice from "../redux/BestRecentSlice";
import MyChatBestRecentSlice from "../redux/MyChatBestRecentSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    globalConfig: globalConfigReducer,
    characters: charactersReducer,
    prompts: promptsReducer,
    conversation: conversationReducer,
    aiMessage: fictionoaiReducer,
    conversationMessages: conversationMessagesReducer,
    conversationRateReducer: conversationRateReducer,
    LoginSlice: LoginSlice,
    BestRecentSlice: BestRecentSlice,
    MyChatBestRecentSlice: MyChatBestRecentSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
