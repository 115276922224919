import React, { useEffect, useState, useRef } from "react";
import CharacterTile from "../../components/character/CharacterTile";
import Character from "../../datamodel/Character";
import Button from "@mui/material/Button";
import "./CharactersListPage.css";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import "../../components/character/CharacterTile.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getCharacters } from "../../redux/CharactersSlice";
import Header from "../../components/header/Header";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
// import Ads from "../../components/Ads/Ads";
import VectorImage from "../../assets/images/Vector.svg";
import LoginImage from "../../assets/images/chevron-down.svg";
import VectorUpImage from "../../assets/images/VectorUp.svg";
import leftImage from "../../assets/images/leftArrow.svg";
import rightImage from "../../assets/images/rightArrow.svg";
import CharacterCard from "../../components/character/CharacterCard";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import {
  getAllCharacter,
  getCharacterById,
} from "../../redux/ConversationSlice";
import CardSkeleton from "../../components/Skeleton/CardSkeleton";

const CharactersListPage: React.FC = () => {
  useEffect(() => {
    document.title = "Find & Share characters on Fictiono.us";
    fetch("/characters", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    }).catch((error) => console.error("Error hitting /characters:", error));
  }, []);

  const dispatch: any = useDispatch();
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const [isScrollableRight, setIsScrollableRight] = useState(true);
  // const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  // const [activeUser, setActiveUser] = useState(null);
  // const [activetimeperiod, setActivetimeperiod] = useState(null);
  // const anchorRef2 = React.useRef<HTMLButtonElement>(null);
  const anchorRef3 = React.useRef<HTMLButtonElement>(null);
  const [ads, setads] = useState(true);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scale, setScale] = useState(1);

  const [menuItemDropDown, setMenuItemDropDown] =
    React.useState("All Characters");
  const [menuItemDropDown2, setMenuItemDropDown2] = React.useState("All Tags");

  const containerRef = useRef<HTMLDivElement>(null);

  const [CharacterId, setCharacterId] = useState<string>("");

  const allTags = useSelector(
    (state: RootState) => state.conversation?.getAllCharacterData.allTags
  );

  // const getDiscoverCharacter = useSelector(
  //   (state: RootState) => state.conversation.character
  // );

  // screen-width
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);
  const updateScreenHeight = () => {
    setScreenWidtht(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const characters = useSelector(
    (state: RootState) => state.characters?.characters
  );

  const charactersStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  // All dispatch in this file
  useEffect(() => {
    dispatch(getAllCharacter());
  }, [CharacterId]);

  useEffect(() => {
    dispatch(getCharacters() as any);
  }, []);

  // const handleToggle2 = () => {
  //   setOpen2((prevOpen) => !prevOpen);
  // };
  const handleToggle3 = () => {
    setOpen3((prevOpen) => !prevOpen);
  };

  // function handleListKeyDown(event: React.KeyboardEvent) {
  //   if (event.key === "Tab") {
  //     event.preventDefault();
  //     setOpen2(false);
  //   } else if (event.key === "Escape") {
  //     setOpen2(false);
  //   }
  // }
  function handleListKeyDown2(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen3(false);
    } else if (event.key === "Escape") {
      setOpen3(false);
    }
  }
  // const handleCloseMenuItem = (
  //   event: Event | React.SyntheticEvent,
  //   item: any
  // ) => {
  //   if (
  //     anchorRef2.current &&
  //     anchorRef2.current.contains(event.target as HTMLElement)
  //   ) {
  //     return;
  //   }
  //   setMenuItemDropDown(item);
  //   setOpen2(false);
  // };
  const handleCloseMenuItem2 = (
    event: Event | React.SyntheticEvent,
    item: any
  ) => {
    if (
      anchorRef3.current &&
      anchorRef3.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setMenuItemDropDown2(item);
    setOpen3(false);
  };

  // const handleClose2 = (event: Event | React.SyntheticEvent) => {
  //   if (
  //     anchorRef2.current &&
  //     anchorRef2.current.contains(event.target as HTMLElement)
  //   ) {
  //     return;
  //   }
  //   setOpen2(false);
  // };
  const handleClose3 = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef3.current &&
      anchorRef3.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen3(false);
  };

  // const handleUserClick = (username: any) => {
  //   setActiveUser(username);
  //   closeDropdown();
  // };

  // const handletimeperiodClick = (username: any) => {
  //   setActivetimeperiod(username);
  //   closeDropdown();
  // };

  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      const isAtLeft = container.scrollLeft === 0;
      const isAtRight = container.scrollLeft === maxScrollLeft;

      setIsScrollableLeft(!isAtLeft);
      setIsScrollableRight(!isAtRight);
    }
  };

  const handlePrev = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft -= container.clientWidth; // Scroll the width of one card
      setIsScrollableRight(true); // Always enable the "next" button
      if (container.scrollLeft <= 0) {
        setIsScrollableLeft(false); // Hide the "prev" button when at the beginning
      }
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft += container.clientWidth;
      setIsScrollableLeft(true);
      const maxScroll = container.scrollWidth - container.clientWidth;
      if (container.scrollLeft >= maxScroll) {
        setIsScrollableRight(false);
      }
    }
  };

  const character = [];

  for (let i = 0; i < characters.length; i++) {
    character.push(characters[i]);
  }

  if (character.length < 12) {
    const numberOfObjectsToAdd = 12 - character.length;
    for (let i = 0; i < numberOfObjectsToAdd; i++) {
      character.push(character[i]);
    }
  }

  // next-prev button conditions
  const isDesktop = screenWidtht < 1300;
  const mappedArray = character.map((item) => item);

  const isButtonVisible =
    mappedArray &&
    mappedArray.filter(
      (data) => data?.featuredCharacter && data?.status === "Active"
    ).length > 5;

  // page return condition
  // if (charactersStatus === "loading") {
  //   return <div className="loading">Loading characters...</div>;
  // }
  // if (charactersStatus === "failed") {
  //   return <div className="loading">Failed to load characters</div>;
  // }

  return (
    <div className="container">
      <div className="charaterList-container">
        <Header buttonText="slogin" imageSrc={LoginImage} />
        <div className="ads-section-char">
          <div
            className={
              !ads
                ? "best-recent-page best-recent-with-ads"
                : "best-recent-page-noAds"
            }
          >
            <div className="best-recent-filters featured-heading">
              <h1 className="characterlist-heading">Featured</h1>
            </div>

            <div className="scroll-bar-view scrool-charcterlist-page">
              <button
                id="slideBack"
                className="prev-next-btn-char"
                type="button"
                onClick={handlePrev}
                style={{
                  visibility: isScrollableLeft ? "visible" : "hidden",
                }}
              >
                <img
                  className="prev-next-btn-img ripple"
                  src={leftImage}
                  alt="Button Icon"
                />
              </button>

              <button
                id="slides"
                className="prev-next-btn-char next prev-btn-characterlist"
                type="button"
                onClick={handleNext}
                style={{
                  visibility:
                    isScrollableRight && (isButtonVisible || isDesktop)
                      ? "visible"
                      : "hidden",
                }}
              >
                <img
                  className="prev-next-btn-img ripple"
                  src={rightImage}
                  alt="Button Icon"
                />
              </button>
            </div>

            <div className="content-container">
              <div
                className={`character-list-page ${
                  ads ? "" : "character-list-page-noAds"
                }`}
              >
                <div
                  className="main_featured_card"
                  ref={containerRef}
                  onScroll={handleScroll}
                >
                  {charactersStatus === "loading" ||
                  charactersStatus === "failed"
                    ? Array.from({ length: 2 }, (_, index) => (
                        <CardSkeleton key={index} margin="0" />
                      ))
                    : mappedArray
                        ?.filter(
                          (data: any) =>
                            data?.featuredCharacter && data?.status === "Active"
                        )
                        .map((character: Character, rowIndex: number) =>
                          character ? (
                            <div
                              className="character-row"
                              id="solomon"
                              key={rowIndex}
                              style={{
                                transform: `scale(${scale})`,
                                transformOrigin: "top left",
                              }}
                            >
                              <CharacterTile
                                key={character?.name}
                                character={character}
                                showAds={ads}
                              />
                            </div>
                          ) : null
                        )}
                </div>
              </div>
            </div>

            {/* Dicover Section */}

            <div className="content-container">
              <h1 className="characterlist-discover-heading">Discover</h1>

              <Stack
                className="characterlist-discover-heading "
                // direction={"row"}
                // sx={selectDropStyle}
              >
                {/* <div
                  style={{
                    margin: "0px",
                    marginBottom: "8px",
                    zIndex: "99",
                  }}
                >
                  <Button
                    ref={anchorRef2}
                    id="composition-button-2"
                    aria-controls={open2 ? "composition-menu-2" : undefined}
                    aria-expanded={open2 ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle2}
                    sx={{
                      width: "326px",
                      height: "68px",
                      color: "white",
                      borderRadius: !open2 ? "10px" : "10px 10px 0 0",
                      display: "flex",
                      justifyContent: "flex-start !important",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "var(--turquoise-2)",
                      overflow: "hidden",
                      "&:hover": {
                        backgroundColor: "var(--turquoise-2)",
                      },
                      ["@media (max-width:480px)"]: {
                        width: "197px !important",
                        height: "40px",
                        padding: "0px",
                        whiteSpace: "nowrap",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    <Box sx={boxStyle}>
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }}
                        >
                          {menuItemDropDown}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          bottom: "3px",
                        }}
                      >
                        {open2 ? (
                          <img
                            src={VectorUpImage}
                            style={{ width: "12px", height: "6px" }}
                          />
                        ) : (
                          <img
                            src={VectorImage}
                            style={{ width: "12px", height: "6px" }}
                          />
                        )}
                      </div>
                    </Box>
                  </Button>

                  <Popper
                    open={open2}
                    anchorEl={anchorRef2.current} // Use the same anchor element as the first dropdown
                    role={undefined}
                    placement="bottom-start"
                    transition
                    sx={popperStyle}
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left top",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose2}>
                            <MenuList
                              sx={menuListStyle}
                              autoFocusItem={open2}
                              id="composition-menu-2"
                              aria-labelledby="composition-button-2"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={(event) => {
                                  handleCloseMenuItem(event, "All Characters");
                                  setCharacterId("");
                                }}
                                sx={{
                                  fontWeight: 600,
                                  padding: "15px 10px !important",
                                  "&:hover": {
                                    backgroundColor:
                                      "rgb(229, 229, 229) !important",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                All Characters
                              </MenuItem>

                              {characters?.map((item: any) => {
                                return (
                                  <MenuItem
                                    onClick={(event) => {
                                      handleCloseMenuItem(event, item.name);
                                      setCharacterId(item?._id);
                                    }}
                                    sx={{
                                      fontWeight: 600,
                                      padding: "15px 10px !important",
                                      "&:hover": {
                                        backgroundColor:
                                          "rgb(229, 229, 229) !important",
                                        borderRadius: "8px",
                                      },
                                    }}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div> */}
                <div
                  style={{
                    margin: "0px",
                    marginBottom: "8px",
                    zIndex: "99",
                  }}
                >
                  <Button
                    ref={anchorRef3}
                    id="composition-button-2"
                    aria-controls={open3 ? "composition-menu-2" : undefined}
                    aria-expanded={open3 ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle3}
                    sx={{
                      width: "326px",
                      height: "68px",
                      color: "white",
                      borderRadius: !open3 ? "10px" : "10px 10px 0 0",
                      display: "flex",
                      justifyContent: "flex-start !important",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "var(--turquoise-2)",
                      overflow: "hidden",
                      "&:hover": {
                        backgroundColor: "var(--turquoise-2)",
                      },
                      ["@media (max-width:480px)"]: {
                        width: "197px !important",
                        height: "40px",
                        padding: "0px",
                        whiteSpace: "nowrap",
                        marginLeft: "0px",
                      },
                    }}
                  >
                    <Box sx={boxStyle}>
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }}
                        >
                          {menuItemDropDown2}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          bottom: "3px",
                        }}
                      >
                        {open3 ? (
                          <img
                            src={VectorUpImage}
                            style={{ width: "12px", height: "6px" }}
                          />
                        ) : (
                          <img
                            src={VectorImage}
                            style={{ width: "12px", height: "6px" }}
                          />
                        )}
                      </div>
                    </Box>
                  </Button>

                  <Popper
                    open={open3}
                    anchorEl={anchorRef3.current} // Use the same anchor element as the first dropdown
                    role={undefined}
                    placement="bottom-start"
                    transition
                    sx={popperStyle}
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          borderRadius: "0 0 10px 10px",
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left top",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose3}>
                            <MenuList
                              sx={menuListStyle}
                              autoFocusItem={open3}
                              id="composition-menu-2"
                              aria-labelledby="composition-button-2"
                              onKeyDown={handleListKeyDown2}
                            >
                              <MenuItem
                                onClick={(event) => {
                                  handleCloseMenuItem2(event, "All Tags");
                                  // setCharacterId("");
                                }}
                                sx={{
                                  fontWeight: 600,
                                  padding: "15px 10px !important",
                                  "&:hover": {
                                    backgroundColor:
                                      "rgb(229, 229, 229) !important",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                All Tags
                              </MenuItem>

                              {allTags
                                ?.slice()
                                ?.sort()
                                ?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      onClick={(event) => {
                                        handleCloseMenuItem2(event, item);
                                        // setCharacterId(item?._id);
                                      }}
                                      sx={{
                                        fontWeight: 600,
                                        padding: "15px 10px !important",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgb(229, 229, 229) !important",
                                          borderRadius: "8px",
                                        },
                                      }}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Stack>
            </div>

            {/* cards */}
            <div className="content-container character-image">
              <div
                className={`character-list-page ${
                  ads ? "" : "character-list-page-noAds"
                }`}
              >
                <div
                  style={{ marginTop: "30px" }}
                  className="main_discover_card characterlist-discover-heading"
                >
                  {charactersStatus === "loading"
                    ? Array.from({ length: 2 }, (_, index) => (
                        <CardSkeleton key={index} margin="0 auto" />
                      ))
                    : characters
                        ?.filter((data) =>
                          data.status === "Active" &&
                          menuItemDropDown2 === "All Tags"
                            ? true
                            : data?.tag?.includes(menuItemDropDown2)
                        )
                        .map((character: Character, rowIndex: number) =>
                          character ? (
                            <div
                              className="character-row character-row-avatar discover_card"
                              key={rowIndex}
                            >
                              <CharacterCard
                                key={character.name}
                                character={character}
                                showAds={ads}
                              />
                            </div>
                          ) : null
                        )}
                </div>
              </div>
            </div>
          </div>

          {/* Ads Section */}
          <div
            className={
              ads
                ? "best-recent-page-ads character-page-ads"
                : "best-recent-noads"
            }
            style={{ display: "none" }}
          >
            {/* <Ads /> */}
          </div>
        </div>

        {/* footer */}
        <div className="footer-character">
          <p className="footer-text valign-text-middle secondaryregular>">
            <span>
              <span className="secondaryregular">
              Remember: you are chatting with fictional characters and these conversations are public. &nbsp;
              </span>
            </span>
            <span
              className="span1 secondaryregular"
              style={{ cursor: "pointer" }}
              onClick={() => window.open("https://bit.ly/fic-TOS", "_blank")}
            >
              Terms of Service
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CharactersListPage;

const boxStyle = {
  display: "flex",
  justifyContent: "space-between !important",
  gap: "40px",
  color: "var(--white)",
  fontFamily: "var(--font-family-dm_mono)",
  fontSize: "var(--font-size-xl)",
  letterSpacing: "0px",
  fontWeight: 500,
  textTransform: "capitalize",
  width: "96%",
  padding: "10px 14px !important",
  ["@media (max-width:480px)"]: {
    fontSize: "16px !important",
    width: "100%",
    gap: "10px",
  },
};

const popperStyle = {
  maxHeight: "200px",
  width: "326px !important",
  // overflowY: "scroll",
  height: "auto",
  borderRadius: "0px 0px 10px 10px",
  "& .MuiList-root": {
    // borderRadius: "0px 0px 10px 10px !important",
    padding: "5px 10px",
  },
  ["@media (max-width:775px)"]: {
    // position: "relative !important",
    top: "24.5%",
    left: "8%",
    transform: "translate3d(0px, 0px, 0px) !important",
    // inset: "0px auto auto 19px !important",
    inset: "auto !important",
  },
  ["@media (max-width:500px)"]: {
    width: "197px !important",
  },
  // ...
};

const menuListStyle = {
  maxHeight: "200px", // Set max height for the menu items
  overflowY: "auto", // Enable vertical scrolling
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#C2C2C2",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "8px",
    backgroundColor: "transparent",
    border: "2px solid #F0F0F0",
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-track": {
    margin: "5px",
  },
};

// const selectDropStyle = {
//   gap: "15px",
//   overflowX: "scroll",
//   "&::-webkit-scrollbar": {
//     width: 0,
//     height: 0,
//     scrollbarWidth: "none" /* Firefox */,
//     scrollbarColor: "transparent transparent" /* Firefox */,
//     msOverflowStyle: "none" /* IE and Edge */,
//   },

//   "@media (max-width:775px)": {
//     // marginLeft: "5px",

//     overflowX: "scroll",
//     scrollbarWidth: "none" /* Firefox */,
//     msOverflowStyle: "none" /* IE and Edge */,

//     // marginBottom: "-35px",
//     // position: "absolute",
//     // left: 0,
//     // right: 0,
//   },
// };
