import React from "react";
import "./AdministrationPage.css";
import AdminNavigation from "./AdminNavigation";

const AdministrationPage: React.FC = () => {
  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background"></div>
    </div>
  );
};

export default AdministrationPage;
