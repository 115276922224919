import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "../../assets/images/x.svg";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import "cropperjs/dist/cropper.css";
import { Cropper, ReactCropperElement } from "react-cropper";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropProfilePopup(props: any) {
  const {
    setOpenCrop,
    openCrop,
    screenWidtht,
    setOpenChangeProfile,
    uploadedImage,
    setCroppedImage,
  } = props;

  const handleClose = () => {
    setOpenCrop(false);
  };
  const isMobile = screenWidtht < 775;

  const cropperRef = useRef<ReactCropperElement | null>(null);

  const getCropData = async () => {
    const croppedCanvas = cropperRef?.current?.cropper?.getCroppedCanvas();
    if (croppedCanvas) {
      displayUploadedImage(croppedCanvas.toDataURL());
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          setCroppedImage(blob);
          const reader = new FileReader();
          reader.onloadend = () => {
            const dataUrl = reader.result as string;
            displayUploadedImage(dataUrl);
          };
          reader.readAsDataURL(blob);
        }
      }, "image/jpeg");
    }
  };

  const displayUploadedImage = (imageData: string) => {
    const profileImage = document.getElementById(
      "profilePic"
    ) as HTMLImageElement;
    if (profileImage) {
      profileImage.src = imageData;
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={isMobile}
        open={openCrop}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: isMobile ? "0px" : "20px",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: isMobile ? "auto" : "400px",
            padding: "10px 20px",
            borderBottom: "1px solid #CECECE",
          }}
        >
          <Typography
            component="div"
            style={{
              color: "#26DAF0",
              fontFamily: "var(--font-family-dm_mono)",
              fontSize: "var(--font-size-xl)",
              fontWeight: "regular",
            }}
          >
            Crop profile pic
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <img className="menu" src={Close} alt="menu" />
          </IconButton>
        </Box>

        <Box
          style={{
            height: "100%",
            padding: isMobile ? "20px" : "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            background: isMobile ? "rgba(149, 149, 149, 0.2)" : "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Cropper
              aspectRatio={1}
              style={{ minHeight: "300px", maxHeight: "400px", width: "400px" }}
              guides={true}
              src={uploadedImage}
              ref={cropperRef as React.RefObject<ReactCropperElement>}
              dragMode={"move"}
              checkOrientation={true}
            />
          </div>

          {/* preview 
           <img
            style={{ height: "auto", width: "400px", marginTop: "20px" }}
            src={cropData}
            alt="cropped"
          /> */}

          <div style={{ display: "flex", gap: "15px", padding: "15px 0" }}>
            <button
              style={greyBtn}
              onClick={() => {
                setOpenCrop(false);
                setOpenChangeProfile(true);
              }}
            >
              Cancel
            </button>
            <button
              style={gradientBtn}
              onClick={() => {
                getCropData();
                setOpenCrop(false);
                setOpenChangeProfile(true);
              }}
            >
              Done
            </button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
}

const gradientBtn: any = {
  width: "100%",
  textAlign: "center",
  color: "white",
  borderRadius: "8px",
  border: "none",
  padding: "8px ",
  fontFamily: "var(--font-family-inter)",
  fontSize: "var(--font-size-m)",
  fontWeight: "semi-bold",
  background:
    "linear-gradient(75deg,rgba(38, 218, 240, 1), rgba(252, 29, 137,1)",
};

const greyBtn: any = {
  width: "100%",
  textAlign: "center",
  background: "#959595",
  color: "white",
  borderRadius: "8px",
  border: "none",
  padding: "8px ",
  fontFamily: "var(--font-family-inter)",
  fontSize: "var(--font-size-m)",
  fontWeight: "semi-bold",
};
