import React, { useState, FormEvent, useEffect } from "react";
import "./EditPromptPage.css";
import AdminNavigation from "../AdminNavigation";
import { Prompt } from "../../../datamodel/Prompt";
import {
  ItemStringWithSubtitle,
  ItemWithButtons,
  ItemWithDropdown,
  PageTitle,
  TextGroup,
} from "../Items";
import {
  deletePrompt,
  getPromptById,
  updatePrompt,
} from "../../../redux/PromptsSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import { getCharacterNames } from "../../../redux/CharactersSlice";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Deactive", label: "Deactive" },
];

const EditPromptPage: React.FC = () => {
  const { promptId } = useParams<{
    promptId: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editingPrompt, setEditingPrompt] = useState<Prompt | undefined>(
    undefined
  );
  // Get prompt by id from the store
  const prompt = useSelector((state: RootState) => state.prompts?.prompt);
  const promptStatus = useSelector((state: RootState) => state.prompts?.status);

  // Get character names from the store
  const existingCharacterNames = useSelector(
    (state: RootState) => state.characters?.names
  );
  const characterNamesStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  useEffect(() => {
    if (promptId) {
      dispatch(getPromptById(promptId) as any);
    }
  }, [dispatch, promptId]);

  // Watch for changes in `prompt` and update local state
  useEffect(() => {
    if (prompt) {
      setEditingPrompt(prompt);
    }
  }, [prompt]);

  useEffect(() => {
    dispatch(getCharacterNames() as any);
  }, [dispatch]);

  // Status
  if (promptStatus === "loading") {
    return <div className="loading">Loading prompt {promptId} ...</div>;
  }

  if (promptStatus === "failed") {
    return <div className="loading">Failed to load prompt {promptId}</div>;
  }

  if (characterNamesStatus === "loading") {
    return <div className="loading">Loading character names...</div>;
  }

  if (characterNamesStatus === "failed") {
    return <div className="loading">Failed to load character names</div>;
  }

  const handleUpdate = async (event: FormEvent) => {
    event.preventDefault();
    await dispatch(updatePrompt({ promptId, prompt: editingPrompt }) as any);
  };

  const handleDelete = async (event: FormEvent) => {
    event.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this prompt?"
    );

    if (isConfirmed) {
      await dispatch(deletePrompt(promptId!) as any);
      navigate("/administration/listprompts");
    }
  };

  const characterNameOptions = existingCharacterNames.map((name) => ({
    value: name,
    label: name,
  }));

  const canBeUpdated =
    editingPrompt?.characterName !== "" &&
    editingPrompt?.status !== "" &&
    editingPrompt?.prompt !== "" &&
    editingPrompt?.notes !== "";

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <form>
          <div className="page-main">
            <PageTitle title="Edit Prompt" />
            <div className="items">
              <div className="item-simple-input">
                <div className="item-title-input-dropdown">
                  <TextGroup
                    title="Character Assignment*"
                    subtitle="Which character is this prompt for"
                  />
                  <Select
                    className="dropdown-content"
                    options={characterNameOptions}
                    value={{
                      value: editingPrompt?.characterName,
                      label: editingPrompt?.characterName,
                    }}
                    onChange={(e) =>
                      setEditingPrompt({
                        ...editingPrompt!,
                        characterName: e?.value!,
                      })
                    }
                  />
                </div>
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Status*</div>
                <Select
                  className="dropdown-content"
                  options={statusOptions}
                  value={{
                    value: editingPrompt?.status,
                    label: editingPrompt?.status,
                  }}
                  onChange={(e) =>
                    setEditingPrompt({
                      ...editingPrompt!,
                      status: e?.value!,
                    })
                  }
                />
              </div>
              <div className="item-simple-input">
                <TextGroup title="Prompt*" subtitle="500 characters max" />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input custom prompt here"
                  value={editingPrompt?.prompt}
                  onChange={(e) =>
                    setEditingPrompt({
                      ...editingPrompt!,
                      prompt: e.target.value,
                    })
                  }
                />
              </div>
              <div className="item-simple-input">
                <TextGroup
                  title="Notes*"
                  subtitle="Internal notes about this prompt"
                />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input notes here"
                  value={editingPrompt?.notes}
                  onChange={(e) =>
                    setEditingPrompt({
                      ...editingPrompt!,
                      notes: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="simple-space"></div>
          <div className="multi-actions">
            <button
              className={`${
                canBeUpdated ? "button-action" : "button-disabled-action"
              }`}
              disabled={!canBeUpdated}
              onClick={handleUpdate}
            >
              Update Changes
            </button>
            <button className="button-action" onClick={handleDelete}>
              Delete Prompt
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPromptPage;
