import React, { useEffect, useState } from "react";
// import Logo from "../../assets/images/ShareLogo.svg";
import Linksvg from "../../assets/images/link.svg";
import Facebook from "../../assets/images/Facebook.svg";
import Twitter from "../../assets/images/Twitter.svg";
// import pinterest from "../../assets/images/pinterest.svg";
import Alien from "../../assets/images/Alien.svg";
import Close from "../../assets/images/x.svg";
import "./SharePopup.css";
import { CircularProgress, Dialog } from "@mui/material";
// import OGImage from "../../OGImage";
import domtoimage from "dom-to-image";
import Preview from "./preview";
import { useDispatch, useSelector } from "react-redux";
import { OGImagePost } from "../../redux/ConversationSlice";

// shares-buttons
// import { FacebookProvider, ShareButton } from "react-facebook";
import {
  FacebookShareButton,
  TwitterShareButton,
  // PinterestShareButton,
  RedditShareButton,
} from "react-share";
// import axios from "axios";

let node: any;

interface SharePopupProps {
  conversationId: String;
  isSharePopupOpen: boolean;
  toggleSharePopup: () => void;
  OgImage: string | "";
}

const SharePopup: React.FC<SharePopupProps> = ({
  conversationId,
  isSharePopupOpen,
  toggleSharePopup,
  OgImage,
}) => {
  const OgLoading = useSelector((state: any) => state.conversation);

  const dispatch: any = useDispatch();
  const [copiedLink, setCopiedLink] = useState<string | null>(null);
  // const [OgImage, setOgImage] = useState("");
  // const [ogPinterest, setOgPinterest] = useState("");

  const shareLink = `${process.env.REACT_APP_BASE_URL}conversation/${conversationId}`;

  const handleCopyLink = async () => {
    // await convertNodeToImage();

    console.log("node4 >>>>");

    const link = `https://fictiono.demo4site.com/conversation/${conversationId}`;
    await navigator.clipboard.writeText(link).then(() => {
      setCopiedLink(link);
      console.log("node5 >>>>");
    });
  };

  // useEffect(() => {
  //   node = document.getElementById("image-container");
  // }, []);

  // const convertNodeToImage = () => {
  //   return new Promise((res) => {
  //     console.log("node1 >>>>", node);
  //     if (node) {
  //       domtoimage
  //         .toPng(node)
  //         .then(function (image: any) {
  //           if (image) {
  //             setOgImage(image);
  //             dispatch(OGImagePost({ file: image, convoId: conversationId }));
  //             res("complete");
  //             console.log("node2 >>>>", image);
  //           }
  //         })
  //         .catch(function (error: any) {
  //           console.error("Oops, something went wrong!", error);
  //           res("complete");
  //           console.log("node3 >>>>", error);
  //         });
  //     }
  //   });
  // };

  // const fetchOGData = async () => {
  //   try {
  //     const apiResponse = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}conversations/${conversationId}`
  //     );
  //     const apiResponseData = apiResponse.data;
  //     setOgPinterest(apiResponseData?.ogImageUrl);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (isSharePopupOpen) {
  //     convertNodeToImage();
  //   }
  // }, [isSharePopupOpen]);

  return (
    <>
      <Dialog onClose={toggleSharePopup} open={isSharePopupOpen}>
        <div className="share-main">
          <div className="share-header">
            <div className="share-header-text">
              <div className="share-text">Share</div>
            </div>
            <div onClick={toggleSharePopup}>
              <img className="menu" src={Close} alt="menu" />
            </div>
          </div>

          {OgImage && (
            <img
              id="preview-image"
              src={OgImage}
              alt="Something is wrong, loading"
            />
          )}

          <div className="share-container">
            <div className="share-buttons">
              <div className="share-btn facebook">
                <FacebookShareButton url={shareLink} hashtag={"FictionoUs"}>
                  <img src={Facebook} alt="Facebook" />
                </FacebookShareButton>
              </div>

              <div className="share-btn twitter">
                <TwitterShareButton
                  url={shareLink}
                  // title={"twitter title"}
                  hashtags={["fictiono", "incipientinfo"]}
                >
                  <img src={Twitter} alt="Twitter" />
                </TwitterShareButton>
              </div>

              {/* <div className="share-btn pinterest">
                <PinterestShareButton
                  url={shareLink}
                  media={ogPinterest}
                  description="fictiono pinterest"
                >
                  <img src={pinterest} alt="pinterest" />
                </PinterestShareButton>
              </div> */}

              <div className="share-btn alien">
                <RedditShareButton url={shareLink} title="Fictiono.Us">
                  <img src={Alien} alt="Alien" />
                </RedditShareButton>
              </div>
            </div>

            <div className="link-button" onClick={handleCopyLink}>
              <div className="link-button-text">
                {copiedLink ? "Copied Link" : "Copy Link"}
              </div>
              {OgLoading?.OGImagePostLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <img className="link-button-logo" src={Linksvg} alt="Link" />
              )}
            </div>
          </div>
        </div>
      </Dialog>

      {/* <Preview conversationId={conversationId} /> */}
    </>
  );
};

export default SharePopup;
