import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import CharactersListPage from "./pages/characters/CharactersListPage";
import BestAndRecentPage from "./pages/bestandrecent/BestAndRecentPage";
import AdministrationPage from "./pages/administration/AdministrationPage";
import ListCharactersPage from "./pages/administration/Characters/ListCharactersPage";
import AddCharacterPage from "./pages/administration/Characters/AddCharacterPage";
import PageNotFoundPage from "./pages/errors/PageNotFoundPage";
import GlobalConfiguration from "./pages/administration/GlobalConfiguration";
import { ToastContainer } from "react-toastify";
import ListPromptsPage from "./pages/administration/Prompts/ListPromptsPage";
import AddPromptPage from "./pages/administration/Prompts/AddPromptPage";
import NewConversationPage from "./pages/conversations/NewConversation";
import EditCharacterPage from "./pages/administration/Characters/EditCharacterPage";
import EditPromptPage from "./pages/administration/Prompts/EditPromptPage";
import SharedConversation from "./pages/conversations/SharedConversation";
import { Amplify, Auth, Hub } from "aws-amplify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { UserProvider, useUser } from "./components/contexts/UserContext";
import { updatedAwsConfig } from "./updatedAwsExports";
import UserProfile from "./components/user/UserProfile";
Amplify.configure(updatedAwsConfig);

interface HubPayload {
  event: string;
  data?: any;
  message?: string;
}

const App: React.FC = () => {
  const { user, setUser } = useUser();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", (data: { payload: HubPayload }) => {
      switch (data.payload.event) {
        case "signIn":
          setUser(data.payload.data);
          break;
        case "signOut":
          setUser(null);
          break;
      }
    });

    getUser();

    return unsubscribe;
  }, []);

  const getUser = async (): Promise<void> => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    } catch (error) {
      console.log("Not signed in");
    }
  };

  useEffect(() => {
    if (
      user !== null &&
      user !== undefined &&
      user.attributes?.["custom:accessrole"] === "admin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  return (
    <div className="main-container">
      <ToastContainer />
      <Router>
        <Routes>
          {/* users routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/*" element={<PageNotFoundPage />} />

          <Route path="/characters" element={<CharactersListPage />} />
          <Route path="/bestandrecent" element={<BestAndRecentPage />} />
          <Route
            path="/newconversation/:characterId/:promptId"
            element={<NewConversationPage />}
          />
          <Route
            path="/conversation/:conversationId"
            element={<SharedConversation />}
          />
          <Route path="/user-profile/:settingtype" element={<UserProfile />} />

          {/* Admin routes */}
          <Route
            path="/administration"
            element={isAdmin ? <AdministrationPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/listcharacters"
            element={isAdmin ? <ListCharactersPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/addcharacter"
            element={isAdmin ? <AddCharacterPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/character/:characterId"
            element={isAdmin ? <EditCharacterPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/listprompts"
            element={isAdmin ? <ListPromptsPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/addprompt"
            element={isAdmin ? <AddPromptPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/prompt/:promptId"
            element={isAdmin ? <EditPromptPage /> : <PageNotFoundPage />}
          />
          <Route
            path="/administration/globalconfiguration"
            element={isAdmin ? <GlobalConfiguration /> : <PageNotFoundPage />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
