import Character from "../../datamodel/Character";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import "./CharacterTile.css";
import { useNavigate } from "react-router-dom";
import { getRandomElement } from "../../utils/functions";
import commentIconSvg from "../../assets/images/commentIcon.svg";

interface CharacterProps {
  character: Character;
  showAds: Boolean;
}

const CharacterCard: React.FC<CharacterProps> = ({ character, showAds }) => {
  const navigate = useNavigate();

  const isValidCharacter =
    character.status === "Active" && character.prompts?.length;

  const handleClick = () => {
    if (isValidCharacter) {
      navigate(
        `/newconversation/${character._id!}/${getRandomElement(
          character.prompts!
        )}`
      );
    }
  };

  return (
    <div
      className={`${
        isValidCharacter
          ? `valid-character2 component-charcterlist character-list-new-card${
              showAds ? "" : "valid-character-noAds "
            }`
          : "invalid-character"
      }`}
      onClick={handleClick}
    >
      <div className="avatar-2 characterlist-avatar characters-page-new-card">
        <img
          className={`avatar-img2 avatar-img-charcterlist ${
            showAds ? "" : "avatar-img-noAds "
          }`}
          src={character.avatarUrl === "" ? DefaultImage : character.avatarUrl}
          alt="Characters.profile"
        />
        <div className="comment-count">
          <img src={commentIconSvg} alt="Comment" />
          <span className="comment-count-text">250K</span>
        </div>
      </div>

      <div
        className={`character-content character-content-characterlist${
          showAds ? "" : "character-content-noAds"
        }`}
      >
        <div className="gpts dmmono-medium-white-20px">{character.name}</div>
        <p className="description inter-normal-silver-14px description_character_card">
          {character.description}
        </p>
      </div>
    </div>
  );
};

export default CharacterCard;
