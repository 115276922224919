import React, { FormEvent, useEffect, useState } from "react";
import "./GlobalConfiguration.css";
import {
  ItemSingleInput,
  ItemWithLargeInput,
  ItemWithInputAndDropdown,
  ItemWithButtons,
  PageTitle,
  TextGroup,
} from "./Items";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGlobalConfig,
  updateGlobalConfig,
} from "../../redux/GlobalConfigSlice";
import { RootState } from "../../app/store";
import AdminNavigation from "./AdminNavigation";
import { Globalconfig } from "../../datamodel/GlobalConfig";

const defaultConfig: Globalconfig = {
  maxPrompts: 3,
  promptCharLimit: 250,
  headCode: "",
  footerCode: "",
  fictionousTwitterHandle: "",
  facebookAppID: "",
};

const GlobalConfiguration: React.FC = () => {
  const dispatch = useDispatch();
  const [editingGlobalConfig, setEditingGlobalConfig] = useState<
    Globalconfig | undefined
  >(undefined);

  const [configEdited, setConfigEdited] = useState<boolean>(false);

  const globalconfigs = useSelector(
    (state: RootState) => state.globalConfig?.data
  );
  const globalConfigStatus = useSelector(
    (state: RootState) => state.globalConfig?.status
  );

  useEffect(() => {
    if (globalConfigStatus === "idle" || configEdited) {
      dispatch(fetchGlobalConfig() as any);
      setConfigEdited(false);
    }
  }, [dispatch, globalConfigStatus, configEdited]);

  useEffect(() => {
    if (globalconfigs) {
      setEditingGlobalConfig(globalconfigs);
    }
  }, [globalconfigs]);

  if (globalConfigStatus === "loading") {
    return <div className="loading">Loading global config...</div>;
  }

  if (globalConfigStatus === "failed") {
    return <div className="loading">Failed to load global config</div>;
  }

  const handleUpdate = async (event: FormEvent) => {
    event.preventDefault();
    dispatch(updateGlobalConfig(editingGlobalConfig || defaultConfig) as any);
    setConfigEdited(true);
  };

  const canBeUpdated =
    !isNaN(editingGlobalConfig?.maxPrompts!) &&
    !isNaN(editingGlobalConfig?.promptCharLimit!);

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <div className="page-main">
          <PageTitle title="Global Configuration" />
          <div className="items">
            <div className="simple-space"></div>
            <ItemSingleInput
              title="OpenAI Key"
              defaultInput="*****************************"
            />

            <div className="item-simple-input">
              <TextGroup
                title="Max Prompts*"
                subtitle="Prompt limit per new chat"
              />
              <input
                className="input-label input-default-without-icon"
                type="number"
                value={editingGlobalConfig?.maxPrompts || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const parsedValue = inputValue ? parseInt(inputValue) : 0;
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    maxPrompts: parsedValue,
                  });
                }}
              />
            </div>

            <div className="item-simple-input">
              <TextGroup
                title="Prompt Char Limit*"
                subtitle="Character limit for user prompts"
              />

              <input
                className="input-label input-default-without-icon"
                type="number"
                value={editingGlobalConfig?.promptCharLimit || ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const parsedValue = inputValue ? parseInt(inputValue) : 0;
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    promptCharLimit: parsedValue,
                  });
                }}
              />
            </div>
            <div className="item-simple-input">
              <div className="text-title bodybold ">
                Fictionous Twitter Handle
              </div>
              <input
                className="input-label input-default-without-icon"
                type="string"
                value={editingGlobalConfig?.fictionousTwitterHandle || ""}
                onChange={(e) => {
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    fictionousTwitterHandle: e.target.value,
                  });
                }}
              />
            </div>
            <div className="item-simple-input">
              <div className="text-title bodybold ">Facebook App ID</div>
              <input
                className="input-label input-default-without-icon"
                type="string"
                value={editingGlobalConfig?.facebookAppID || ""}
                onChange={(e) => {
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    facebookAppID: e.target.value,
                  });
                }}
              />
            </div>
            <div className="item-simple-input">
              <div className="text-title bodybold">Head Code</div>
              <textarea
                className="input-default-large input-label"
                placeholder="Add code at the end of <head> tag"
                value={editingGlobalConfig?.headCode || ""}
                onChange={(e) => {
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    headCode: e.target.value,
                  });
                }}
              />
            </div>
            <div className="item-simple-input">
              <div className="text-title bodybold">Footer Code</div>
              <textarea
                className="input-default-large input-label"
                placeholder="Add code before </body> tag"
                value={editingGlobalConfig?.footerCode || ""}
                onChange={(e) => {
                  setEditingGlobalConfig({
                    ...editingGlobalConfig!,
                    footerCode: e.target.value,
                  });
                }}
              />
            </div>
            <ItemWithInputAndDropdown
              title="Banned Words"
              defaultInput="Add word"
              dropdown={["Bad word 1", "Bad word 2", "Bad word 3"]}
              // dropdown={globalconfigs?.bannedWords}
            />
            <ItemWithButtons
              title="Robots.txt"
              buttontitle={["View", "Replace"]}
            />
          </div>
          <div className="simple-space"></div>
        </div>
        <div className="actions">
          <button
            className={`${
              canBeUpdated ? "button-action" : "button-disabled-action"
            }`}
            disabled={!canBeUpdated}
            onClick={handleUpdate}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalConfiguration;
