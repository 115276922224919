import awsConfig from "./aws-exports";
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
// Now we have two redirect URIs, and the first is for localhost and second is for incipient
const [first, second, third, fouth, fifth] =
  awsConfig.oauth.redirectSignIn.split(",");
  
const [firsto, secondo, thirdo, foutho, fiftho] =
  awsConfig.oauth.redirectSignOut.split(",");
export const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? first : third,
    redirectSignOut: isLocalhost ? firsto : thirdo,
  },
};
