import React, { useState, useEffect, useRef } from "react";
import CharacterTile from "../../components/character/CharacterTile";
import RightArrowImage from "../../assets/images/right-arrow.svg";
import Character from "../../datamodel/Character";
import "./PopularCharacters.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getCharacters } from "../../redux/CharactersSlice";
import { Link, useNavigate } from "react-router-dom";
import leftImage from "../../assets/images/leftArrow.svg";
import rightImage from "../../assets/images/rightArrow.svg";
import { Box, Skeleton } from "@mui/material";
import CardSkeleton from "../../components/Skeleton/CardSkeleton";

const PopularCharactersPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const [isScrollableRight, setIsScrollableRight] = useState(true);

  const handleSeeAllClick = () => {
    navigate("/characters");
  };

  const characters = useSelector(
    (state: RootState) => state.characters?.characters
  );
  const charactersStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getCharacters() as any);
  }, [dispatch]);

  const handlePrev = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft -= 350;

      setIsScrollableRight(true);
      if (
        container.scrollLeft <=
        container.scrollWidth - container.clientWidth
      ) {
        setIsScrollableLeft(false);
      }
    }
  };

  const handleNext = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft += 350;

      setIsScrollableLeft(true);

      if (
        container.scrollLeft >=
        container.scrollWidth - container.clientWidth * 2
      ) {
        setIsScrollableRight(false);
      }
    }
  };

  // page return condition

  // if (charactersStatus === "loading") {
  //   return <div className="loading">Loading characters...</div>;
  // }

  // if (charactersStatus === "failed") {
  //   return <div className="loading">Failed to load characters</div>;
  // }

  return (
    <div className="popular-characters-main">
      <h1 className="chat-with-your-favor">
        Chat with your favorite characters, powered by AI!
      </h1>
      <div className="frame-pop-chars">
        <div className="popular-characters">Popular Characters </div>
        <div className="frame-see-all">
          <Link to="/characters" className="see-all">
            See All
          </Link>
          <img className="right" src={RightArrowImage} alt="Right" />
        </div>
      </div>
      <div
        className="frame-pop-chars-list"
        id="frame-pop-list"
        ref={containerRef}
      >
        {(charactersStatus === "loading" || charactersStatus === "failed")
          ? Array.from({ length: 3 }, (_, index) => (
              <CardSkeleton key={index} />
            ))
          : characters
              ?.filter(
                (data) => data?.featuredCharacter && data?.status === "Active"
              )
              .slice(0, 3)
              .map((character: Character) => (
                <CharacterTile
                  key={character._id}
                  character={character}
                  showAds={false}
                />
              ))}

        <div className="see-all-mobile-frame" onClick={handleSeeAllClick}>
          <div className="see-all-mobile" onClick={handleSeeAllClick}>
            <Link to="/characters" className="see-all-mobile-frame-text">
              See All Characters
            </Link>
          </div>
        </div>
      </div>

      <div className="scroll-bar-view">
        <button
          id="slideBack"
          className="prev-next-btn"
          type="button"
          onClick={handlePrev}
          style={{
            visibility: isScrollableLeft ? "visible" : "hidden",
          }}
        >
          <img
            className="prev-next-btn-img ripple"
            src={leftImage}
            alt="Button Icon"
          />
        </button>
        <button
          id="slides"
          className="prev-next-btn next"
          type="button"
          onClick={handleNext}
          style={{
            visibility: isScrollableRight ? "visible" : "hidden",
          }}
        >
          <img
            className="prev-next-btn-img ripple"
            src={rightImage}
            alt="Button Icon"
          />
        </button>
      </div>
      <div className="recent-best-conversation">
        Browse and rate recent and best conversations.
      </div>
    </div>
  );
};

export default PopularCharactersPage;
