import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

export const createLogin = createAsyncThunk(
  "createLogin",
  async ({ email, dispatch }: any) => {
    const data1 = {
      email,
    };

    const config: any = {
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_API_URL}/users`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data1,
    };

    try {
      const res = await axios(config);

      if (res.status === 201) {
        // toast.success("Login Created");
        dispatch(getUser({ email }));
        return res?.data;
      } else {
        toast.error("Login Created : Something is wrong");
      }
    } catch (error) {
      toast.error("Login Created Error");
    }
  }
);

export const getUser = createAsyncThunk("getUser", async ({ email }: any) => {
  const data1 = {
    email,
  };

  const config: any = {
    method: "GET",
    url: `${process.env.REACT_APP_BACKEND_API_URL}/users/getuser/${email}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios(config);

    if (res.status === 200) {
      // toast.success("User fetched");
      return res?.data;
    } else {
      toast.error("User fetched : Something is wrong");
    }
  } catch (error) {
    toast.error("User fetched error");
  }
});

export const checkUsernameAvailable = createAsyncThunk(
  "checkUsernameAvailable",
  async ({ username }: any) => {
    const config: any = {
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_API_URL}/users/checkusernameavailability/${username}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios(config);

      if (res.status === 200) {
        if (res?.data?.available) {
          // toast.success("Username Available");
          return res?.data;
        } else {
          // toast.info("Username Not-Available");
          return res?.data;
        }
      } else {
        toast.error("Username : Something is wrong");
      }
    } catch (error) {
      toast.error("Username error");
    }
  }
);

export const updateUser = createAsyncThunk("updateUser", async (props: any) => {
  const { useremail, username, displayname, id, dispatch } = props;

  const data1 = {
    _id: id,
    email: useremail,
    userName: username,
    displayName: displayname,
  };

  const config: any = {
    method: "PUT",
    url: `${process.env.REACT_APP_BACKEND_API_URL}/users/${useremail}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data1,
  };

  try {
    const res = await axios(config);

    if (res.status === 200) {
      toast.success("User Updated");
      dispatch(getUser({ email: useremail }));
      return res?.data;
    } else {
      toast.error("Something is wrong");
    }
  } catch (error) {
    toast.error("Update error");
  }
});

export const deleteUser = createAsyncThunk("deleteUser", async (props: any) => {
  const { useremail, navigate, setUser } = props;

  const config: any = {
    method: "DELETE",
    url: `${process.env.REACT_APP_BACKEND_API_URL}/users/${useremail}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios(config);

    if (res.status === 200) {
      toast.success("User Deleted");
      await Auth.signOut();
      setUser(null);
      navigate(`/`);

      return res?.data;
    } else {
      toast.error("Something is wrong : Delete");
    }
  } catch (error) {
    toast.error("Delete error");
  }
});

export const GenrateImageUrl = createAsyncThunk(
  "GenrateImageUrl",
  async (props: any) => {
    const { email, file, dispatch, handleClose } = props;
    const body = new FormData();

  

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/users/uploadprofilepic/`,
        body
      );

      if (res.status >= 200 && res.status < 300) {
        toast.success("User profile uploaded");
        if (res?.data?.imageUrl) {
          handleClose();
          dispatch(
            updateUserProperty({ email, file1: res?.data?.imageUrl, dispatch })
          );
        }

        return res?.data;
      } else {
        toast.error("Something is wrong : Upload");
      }
    } catch (error) {
      toast.error("url generate error");
    }
  }
);

export const updateUserProperty = createAsyncThunk(
  "updateUserProperty",
  async ({ email, file1, dispatch, newChar, whenRated }: any) => {
    const ubody: any = {
      profilePicture: file1,
      emailForNewCharacter: newChar,
      emailWhenRated: whenRated,
    };

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/users/${email}`,
        ubody
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success("User property updated successfully!");
        dispatch(getUser({ email }));
      } else {
        toast.error("Failed to update user property.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to update user property.");
      return error;
    }
  }
);

const initialState: any = {
  error: null,

  createLoginLoading: false,
  createData: [],

  getUserLoading: false,
  getUserData: [],

  checkUsernameLoading: false,
  checkUsernameData: [],

  updateUserPropertyLoading: false,
};

export const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create Login
    builder.addCase(createLogin.pending, (state, action) => {
      state.createLoginLoading = true;
    });
    builder.addCase(createLogin.fulfilled, (state, action) => {
      state.createLoginLoading = false;
      state.createData = action.payload;
    });
    builder.addCase(createLogin.rejected, (state, action) => {
      state.createLoginLoading = false;
    });

    // getUser
    builder.addCase(getUser.pending, (state, action) => {
      state.getUserLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.getUserLoading = false;
      state.getUserData = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.getUserLoading = false;
    });

    // checkUsernameAvailable
    builder.addCase(checkUsernameAvailable.pending, (state, action) => {
      state.checkUsernameLoading = true;
    });
    builder.addCase(checkUsernameAvailable.fulfilled, (state, action) => {
      state.checkUsernameLoading = false;
      state.checkUsernameData = action.payload;
    });
    builder.addCase(checkUsernameAvailable.rejected, (state, action) => {
      state.checkUsernameLoading = false;
    });

    // updateUser
    builder.addCase(updateUser.pending, (state, action) => {
      state.updateUserLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.updateUserLoading = false;
      state.updateUserData = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserLoading = false;
    });

    // updateUserProperty-Loading
    builder.addCase(updateUserProperty.pending, (state, action) => {
      state.updateUserPropertyLoading = true;
    });
    builder.addCase(updateUserProperty.fulfilled, (state, action) => {
      state.updateUserPropertyLoading = false;
    });
    builder.addCase(updateUserProperty.rejected, (state, action) => {
      state.updateUserPropertyLoading = false;
    });

    // GenrateImageUrl-Loading
    builder.addCase(GenrateImageUrl.pending, (state, action) => {
      state.updateUserPropertyLoading = true;
    });
    builder.addCase(GenrateImageUrl.fulfilled, (state, action) => {
      state.updateUserPropertyLoading = false;
    });
    builder.addCase(GenrateImageUrl.rejected, (state, action) => {
      state.updateUserPropertyLoading = false;
    });
  },
});

export default LoginSlice.reducer;
