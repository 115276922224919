import React from "react";
import { Box, Skeleton } from "@mui/material";

const CardSkeleton = ({ margin }: any) => {
  const style = { bgcolor: "grey.700" };
  return (
    <div
      style={{
        width: "230px",
        height: "330px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",

        margin: margin,
      }}
    >
      <Skeleton variant="rounded" height={150} sx={style} />
      <Skeleton sx={style} />
      <Skeleton width="60%" sx={style} />
      <Skeleton sx={style} />
      <Skeleton width="60%" sx={style} />
    </div>
  );
};

export default CardSkeleton;
