import React, { useEffect } from "react";
import "./ListPromptsPage.css";
import { AddAPrompt, PageTitle, SingleDropdown } from "../Items";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getPrompts } from "../../../redux/PromptsSlice";
import DefaultImage from "../../../assets/images/fic_default_user_pic.jpeg";
import AdminNavigation from "../AdminNavigation";
import { useNavigate } from "react-router-dom";

const ListPromptsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prompts = useSelector((state: RootState) => state.prompts?.prompts);
  const promptsStatus = useSelector(
    (state: RootState) => state.prompts?.status
  );

  useEffect(() => {
    dispatch(getPrompts() as any);
  }, [dispatch]);

  if (promptsStatus === "loading") {
    return <div className="loading">Loading prompts...</div>;
  }

  if (promptsStatus === "failed") {
    return <div className="loading">Failed to load prompts</div>;
  }

  const handleClick = (promptId: string) => {
    navigate(`/administration/prompt/${promptId}`);
  };

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <div className="page-main">
          <div className="page-title">
            <PageTitle title="Character Prompts" />
            <AddAPrompt />
          </div>
          <div className="items">
            <div className="simple-space"></div>
            <div className="item-empty-input">
              <SingleDropdown
                dropdown={[{ value: "status", label: "Status" }]}
              />
              <SingleDropdown
                dropdown={[{ value: "character", label: "Character" }]}
              />
            </div>
            <table className="table">
              <thead className="table-header">
                <tr className="table-row">
                  <th className="list-promts-table-column-left table-head-text">
                    Character
                  </th>
                  <th className="list-promts-table-column-middle table-head-text">
                    Status
                  </th>
                  <th className="list-promts-table-column-right table-head-text">
                    Prompt
                  </th>
                </tr>
              </thead>
              <tbody>
                {prompts.map((prompt, index) => (
                  <tr
                    className="table-row"
                    key={index}
                    style={
                      index !== prompts.length - 1
                        ? { borderBottom: "1px solid #2DA564CC" }
                        : {}
                    }
                    onClick={handleClick.bind(this, prompt?._id!)}
                  >
                    <td>
                      <CharacterThumbnail
                        avatar={DefaultImage}
                        name={prompt.characterName}
                      />
                    </td>
                    <td>
                      <div
                        className={`${
                          prompt.status === "Active"
                            ? "cell-active-border"
                            : "cell-deactive-border"
                        }`}
                      >
                        <div
                          className={`${
                            prompt.status === "Active"
                              ? "cell-active"
                              : "cell-deactive"
                          }`}
                        >
                          {prompt.status}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        {prompt.prompt?.length > 50
                          ? prompt?.prompt?.substring(0, 50) + "..."
                          : prompt.prompt}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function CharacterThumbnail(props: { avatar: string; name: string }) {
  return (
    <div className="table-item">
      <div className="table-item-group">
        <img className="table-item-avatar" src={props.avatar} alt="avatar" />
        <div className="table-item-details">
          <div className="table-item-name">{props.name}</div>
        </div>
      </div>
    </div>
  );
}

export default ListPromptsPage;
