import React from "react";
import { Box, Skeleton } from "@mui/material";

const RectangleSkeleton = () => {
  const style = { bgcolor: "grey.700", borderRadius: "10px" };

  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton variant="rounded" height={74} sx={style} />
    </Box>
  );
};

export default RectangleSkeleton;
