import React, { useEffect } from "react";
import "./ListCharactersPage.css";
import { AddACharacter, PageTitle } from "../Items";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getCharacters } from "../../../redux/CharactersSlice";
import DefaultImage from "../../../assets/images/fic_default_user_pic.jpeg";
import AdminNavigation from "../AdminNavigation";
import { useNavigate } from "react-router-dom";

const ListCharactersPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const characters = useSelector(
    (state: RootState) => state.characters?.characters
  );
  const charactersStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  useEffect(() => {
    dispatch(getCharacters() as any);
  }, [dispatch]);

  if (charactersStatus === "loading") {
    return <div className="loading">Loading characters...</div>;
  }

  if (charactersStatus === "failed") {
    return <div className="loading">Failed to load characters</div>;
  }

  const handleClick = (characterId: string) => {
    navigate(`/administration/character/${characterId}`);
  };

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <div className="page-main">
          <div className="page-title">
            <PageTitle title="Characters" />
            <AddACharacter />
          </div>
          <div className="items">
            <div className="simple-space"></div>
            <table className="table">
              <thead className="table-header">
                <tr className="table-row">
                  <th className="list-character-table-column-left table-head-text">
                    Characters
                  </th>
                  <th className="list-character-table-column-right table-head-text">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {characters.map((character, index) => (
                  <tr
                    className="table-row"
                    key={index}
                    style={
                      index !== characters.length - 1
                        ? { borderBottom: "1px solid #2DA564CC" }
                        : {}
                    }
                    onClick={handleClick.bind(this, character?._id!)}
                  >
                    <td className="list-character-table-column-left">
                      <CharacterThumbnail
                        avatar={
                          character.avatarUrl
                            ? character.avatarUrl
                            : DefaultImage
                        }
                        name={character.name}
                        description={character.description}
                      />
                    </td>
                    <td className="list-character-table-column-right">
                      <div
                        className={`${
                          character.status === "Active"
                            ? "cell-active-border"
                            : "cell-deactive-border"
                        }`}
                      >
                        <div
                          className={`${
                            character.status === "Active"
                              ? "cell-active"
                              : "cell-deactive"
                          }`}
                        >
                          {character.status}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function CharacterThumbnail(props: {
  avatar: string;
  name: string;
  description: string;
}) {
  return (
    <div className="table-item">
      <div className="table-item-group">
        <img className="table-item-avatar" src={props.avatar} alt="avatar" />
        <div className="table-item-details">
          <div className="table-item-name">{props.name}</div>
          <div className="table-item-description">{props.description}</div>
        </div>
      </div>
    </div>
  );
}

export default ListCharactersPage;
