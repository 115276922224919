import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BestAndRecentOverview.css";
import LightBulbImage from "../../assets/images/light-bulb.svg";
import LightBulbImageSelect from "../../assets/images/light-bulb-turquoise.svg";
import StarImageSelect from "../../assets/images/star.svg";
import StarImage from "../../assets/images/star-black.svg";
import Conversation from "../../components/conversation/Conversation";
import { useDispatch, useSelector } from "react-redux";
import {
  getBestActivity,
  getRecentActivity,
} from "../../redux/ConversationSlice";
import RectangleSkeleton from "../../components/Skeleton/RectangleSkeleton";

const SummaryBestAndRecentPage = (props: any) => {
  const { characterId } = props;

  const path = "/bestandrecent";
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [RecentBestSelected, setRecentBestSelected] = useState("recent");

  const RecentActivity = useSelector(
    (state: any) => state.conversation.getRecentActivityData
  );

  const BestActivity = useSelector(
    (state: any) => state.conversation.getBestActivityData
  );

  const location = useLocation();
  const lastPath = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const redirectToNewPage = () => {
    if (lastPath === "bestandrecent") {
      if (RecentActivity?.cursor && RecentBestSelected === "recent") {
        dispatch(
          getRecentActivity({
            characterId: characterId,
            SeeMoreId: RecentActivity?.cursor,
          })
        );
      }

      if (BestActivity?.cursor && RecentBestSelected === "best") {
        dispatch(
          getBestActivity({
            characterId: characterId,
            SeeMoreId: BestActivity?.cursor,
          })
        );
      }
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getRecentActivity({ characterId }));
    dispatch(getBestActivity({ characterId }));
  }, [characterId]);

  return (
    <>
      <div className="best-recent-summary">
        <header className="best-recent-header">
          <article
            onClick={() => {
              setRecentBestSelected("recent");
            }}
            className={
              RecentBestSelected === "recent"
                ? "recentorbest-selected"
                : "recentorbest-nonselected"
            }
          >
            <div
              className={
                RecentBestSelected === "recent"
                  ? "button-label-selected titlesubtitle"
                  : "button-label-nonselected titlesubtitle"
              }
            >
              Recent
            </div>
            {RecentBestSelected === "recent" ? (
              <img src={LightBulbImage} alt="light-bulb" />
            ) : (
              <img src={LightBulbImageSelect} alt="light-bulb" />
            )}
          </article>
          <article
            onClick={() => {
              setRecentBestSelected("best");
            }}
            className={
              RecentBestSelected === "best"
                ? "recentorbest-selected"
                : "recentorbest-nonselected"
            }
          >
            <div
              className={
                RecentBestSelected === "best"
                  ? "button-label-selected titlesubtitle"
                  : "button-label-nonselected titlesubtitle"
              }
            >
              Best
            </div>
            {RecentBestSelected === "best" ? (
              <img src={StarImage} alt="star" />
            ) : (
              <img src={StarImageSelect} alt="star" />
            )}
          </article>
        </header>

        <div className="frame-conversation-overview">
          {RecentActivity?.conversations?.length > 0
            ? RecentBestSelected === "recent"
              ? RecentActivity?.conversations?.map((data: any) => (
                  <Conversation
                    id={data._id}
                    avatar={data.avatar}
                    content={data.content ? data.content : "N/A"}
                    score={data.score}
                    emojiImage={data.emojiImage}
                    characterName={data.characterName}
                    characterId={data.characterId}
                    promptId={data.promptId}
                    visible={data.visible}
                    key={data._id}
                    userId={data.userId}
                  />
                ))
              : BestActivity?.conversations?.map((data: any) => (
                  <Conversation
                    id={data._id}
                    avatar={data.avatar}
                    content={data.content ? data.content : "N/A"}
                    score={data.score}
                    emojiImage={data.emojiImage}
                    characterName={data.characterName}
                    characterId={data.characterId}
                    promptId={data.promptId}
                    visible={data.visible}
                    key={data._id}
                    userId={data.userId}
                  />
                ))
            : Array.from({ length: 5 }, (_, index) => (
                <RectangleSkeleton key={index} />
              ))}

          <div className="see-more" onClick={redirectToNewPage}>
            <div className="see-more-btn" onClick={redirectToNewPage}>
              See More
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryBestAndRecentPage;
