import React from "react";
import "./PageNotFoundPage.css";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import LoginImage from "../../assets/images/chevron-down.svg";
import cryImage from "../../assets/images/cryimg.png";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const PageNotFoundPage: React.FC = () => {
  return (
    <div className="container">
      <Header buttonText="slogin" imageSrc={LoginImage} />
      <Stack className="centered-container">
        <h1 className="pageNoFound-heading">
          We really did try to find this page... Sadly, it just ain’t there.
        </h1>
        <h2 className="pageNoFound-subHeading">
          Head back to <Link to="/">homepage</Link>.
        </h2>
        <div className="cry-img">
          <img className="pageNoFound-img" src={cryImage} alt="Not Found" />
        </div>
      </Stack>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default PageNotFoundPage;
