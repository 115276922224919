import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../assets/images/FC-Logo-Web.svg";
import MenuImage from "../../assets/images/menu.png";
import CloseImage from "../../assets/images/close.png";
import LoginSignup from "../login-signup/LoginSignup";
import VectorImage from "../../assets/images/fic_default_user_pic.jpeg";
import LogoutIcon from "../../assets/images/arrow-up-right.svg";
import { useUser } from "../contexts/UserContext";
import { Auth } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import DownArrow from "../../assets/images/chevron-down.svg";
import { createLogin } from "../../redux/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

const Header = (props: any) => {
  const dropdownRef = React.useRef(null);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isAuthendicated, setIsAuthendicated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user, setUser } = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;

  const Data = useSelector((state: any) => state.LoginSlice);

  const LoginLoading = localStorage.getItem("LoginLoading") === "true";

  const handleSignOut = async () => {
    try {
      const isConfirmed = window.confirm("Are you sure you want to log out?");

      if (isConfirmed) {
        await Auth.signOut();
        setUser(null);
        setIsCollapsed(false);
        setShowLogin(true);
        navigate("/");
      }
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleImageClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const rotationStyle = {
    transition: "transform 0.3s ease",
    transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0)",
    transformOrigin: "center",
  };

  useEffect(() => {
    if (isCollapsed) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (props?.triggerCollapse) {
      setIsCollapsed(!isCollapsed);
    }
    if (!isCollapsed && props?.settriggerCollapse) {
      props?.settriggerCollapse(isCollapsed);
    }
  }, [props?.triggerCollapse, isCollapsed]);

  useEffect(() => {
    setIsLoginPopupOpen(props?.loginOpen);
  }, [props?.loginOpen]);

  useEffect(() => {
    // Attach a click event listener to the document
    //@ts-ignore
    const handleClickOutside = (event) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // If the click is outside the dropdown, close it
        setDropdownOpen(false);
      }
    };

    // Add the event listener when the dropdown is shown
    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      if (user?.attributes?.email) {
        dispatch(createLogin({ email: user?.attributes?.email, dispatch }));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user !== null && user !== undefined) {
      setIsAuthendicated(true);
      localStorage.setItem("LoginLoading", "false");
    } else {
      setIsAuthendicated(false);
    }
    if (
      user !== null &&
      user !== undefined &&
      user.attributes?.["custom:accessrole"] === "admin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    if (user === "SUCCESS") {
      toggleLoginPopup();
    }
  }, [user]);

  useLayoutEffect(() => {
    if (isAuthendicated) {
      setShowLogin(false);
    } else {
      setShowLogin(true);
    }
  }, [isAuthendicated]);

  const toggleLoginPopup = () => {
    setIsLoginPopupOpen(!isLoginPopupOpen);
    if (props?.setLoginOpen) {
      props?.setLoginOpen(false);
    }
  };

  // screen-width
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);
  const updateScreenHeight = () => {
    setScreenWidtht(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);
  const isMobile = screenWidtht < 775;

  return (
    <>
      {isLoading && (
        <div className="loader">
          <CircularProgress size={24} color="inherit" />
        </div>
      )}

      <header
        className="header"
        style={{
          zIndex: 1001,
        }}
      >
        <Link to="/">
          <img src={Logo} alt="logo" className="header-logo" />
        </Link>

        <button
          className="navbar-toggler"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {!isCollapsed ? (
            <img className="menu" src={MenuImage} alt="menu" />
          ) : (
            ""
          )}
        </button>

        <ul
          className={`nav-list ${isCollapsed ? "active" : ""}`}
          style={{
            display: props.isMobile ? (isCollapsed ? "flex" : "none") : "flex",
            // justifyContent: isMobile ? "" : "space-evenly",
          }}
        >
          <li className="header-collaped">
            <Link to="/">
              <img src={Logo} alt="logo" className="header-logo-2" />
            </Link>
            <img
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="close"
              src={CloseImage}
              alt="close"
            />
          </li>

          <li
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={`navitem ${pathname === "/" ? "selected" : ""}`}
          >
            <Link to="/">
              <div className="navtext">Home</div>
            </Link>
          </li>

          <li
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={`navitem ${
              pathname === "/characters" ? "selected" : ""
            }`}
          >
            <Link to="/characters">
              <div className="navtext">Characters</div>
            </Link>
          </li>

          <li
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={`navitem ${
              pathname === "/bestandrecent" ? "selected" : ""
            }`}
          >
            <Link to="/bestandrecent">
              <div className="navtext">Best & Recent</div>
            </Link>
          </li>

          {isMobile && user && (
            <li
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={`navitem ${
                pathname === "/user-profile/setting" ? "selected" : ""
              }`}
            >
              <Link to="/user-profile/setting">
                <div className="navtext">My Profile & Chats</div>
              </Link>
            </li>
          )}

          {isMobile && (
            <li
              onClick={() => {
                window.open("https://bit.ly/fic-TOS", "_blank");
                setIsCollapsed(!isCollapsed);
              }}
              className={`navitem ${pathname === "#" ? "selected" : ""}`}
            >
              <Link to="#">
                <div className="navtext">Terms of Service</div>
              </Link>
            </li>
          )}

          {isMobile && (
            <li
              onClick={() => {
                window.open("https://bit.ly/fic-feedback", "_blank");
                setIsCollapsed(!isCollapsed);
              }}
              className={`navitem ${pathname === "#" ? "selected" : ""}`}
            >
              <Link to="#">
                <div className="navtext">Feedback</div>
              </Link>
            </li>
          )}

          {isAdmin && (
            <li
              className="navitem"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <Link target="_blank" to="/administration">
                <div className="navtext">Administration</div>
              </Link>
            </li>
          )}

          <li>
            {!user && (
              <button onClick={toggleLoginPopup} className="login-button">
                <div className="login-text">
                  {!LoginLoading ? (
                    "Log In"
                  ) : (
                    <CircularProgress size={20} color="inherit" />
                  )}
                </div>
              </button>
            )}

            {user && isMobile && (
              <button onClick={handleSignOut} className="logout-button">
                <div className="login-text">Log Out</div>
              </button>
            )}
          </li>

          {user && !isMobile && (
            <li className="navitem">
              <button
                onClick={handleImageClick}
                className="login-button profile-details-button"
                ref={dropdownRef}
                style={{ textTransform: "capitalize" }}
              >
                <div className="login-text">
                  {Data?.getUserData?.displayName}
                </div>
                <img src={DownArrow} style={{ ...rotationStyle }} alt="Login" />
              </button>
            </li>
          )}
        </ul>

        {isDropdownOpen && (
          <div className="profile-dropdown-section">
            <div className="profile-dropdown-section-profile">
              <div className="avatar-m">
                <img
                  src={
                    Data?.getUserData?.profilePicture
                      ? Data?.getUserData?.profilePicture
                      : VectorImage
                  }
                  className="avatar-image"
                />
              </div>
              <div className="profile-dropdown-section-profile-name-login">
                <div className="profile-dropdown-section-profile-name">
                  {Data?.getUserData?.displayName}
                </div>
                <div className="profile-dropdown-section-profile-login">
                  @{Data?.getUserData?.userName}
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(`/user-profile/mychat`)}
              className="profile-dropdown-section-other-text"
            >
              My Chats
            </div>
            <div
              onClick={() => navigate(`/user-profile/setting`)}
              className="profile-dropdown-section-other-text"
            >
              Settings
            </div>
            <div
              onClick={() =>
                window.open("https://bit.ly/fic-feedback", "_blank")
              }
              className="profile-dropdown-section-other-text"
            >
              Feedback
            </div>
            <div
              onClick={handleSignOut}
              className="profile-dropdown-section-other-text"
            >
              Log Out
              <img className="log-out-icon" src={LogoutIcon} />
            </div>
          </div>
        )}
      </header>

      {isLoginPopupOpen && (
        <LoginSignup
          isLoginPopupOpen={isLoginPopupOpen}
          toggleLoginPopup={toggleLoginPopup}
        />
      )}
    </>
  );
};

export default Header;
