import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import "./AddCharacterPage.css";
import AdminNavigation from "../AdminNavigation";
import Character from "../../../datamodel/Character";
import { ItemStringWithSubtitle, PageTitle, TextGroup } from "../Items";
import { saveCharacter } from "../../../redux/CharactersSlice";
import { useDispatch } from "react-redux";
import Select, { SingleValue } from "react-select";
import { useNavigate } from "react-router-dom";
import DefaultAvatar from "../../../assets/images/fic_default_user_pic.jpeg";
import ChangeCharacterAvatarPopup from "../../../components/character/ChangeCharacterAvatarPopup";
import CropCharacterProfilePopup from "../../../components/character/CropCharacterProfilePopup";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Deactive", label: "Deactive" },
];
const featuredOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const adsOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const draftOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const AddCharacterPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [featuredCharacter, setFeatured] = useState<boolean | undefined>(
    undefined
  );
  const [advertising, setAds] = useState<boolean>(true);
  const [draft, setDraft] = useState<boolean>(true);
  const [notes, setCustomNotes] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>(DefaultAvatar);
  const [tag, setTag] = useState<string>("");

  // Change Avatar
  const [OpenChangeProfile, setOpenChangeProfile] = React.useState(false);
  const [openCrop, setOpenCrop] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [uploadedImage, setUploadedImage] = React.useState<
    string | ArrayBuffer | null
  >(DefaultAvatar);
  const [croppedImage, setCroppedImage] = React.useState<Blob | null>(null);

  const defaultCharacter: Character = {
    name: "",
    status: "",
    description: "",
    url: "",
    avatarUrl: "",
    bGImageUrl: "",
    featuredCharacter: false,
    advertising: true,
    draft: true,
    notes: "",
    tag: "",
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };
  const handleStatusChange = (
    select: SingleValue<{ value: string; label: string }>
  ) => {
    setStatus(select?.value || "");
  };
  const handleFeaturedChange = (
    select: SingleValue<{ value: boolean; label: string }>
  ) => {
    setFeatured(select?.value || false);
  };
  const handleAdsChange = (
    select: SingleValue<{ value: boolean; label: string }>
  ) => {
    setAds(select?.value || true);
  };
  const handleDraftChange = (
    select: SingleValue<{ value: boolean; label: string }>
  ) => {
    setDraft(select?.value || true);
  };
  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCustomNotes(event.target.value);
  };
  const handleTagChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const character = {
      ...defaultCharacter,
      avatarUrl,
      name,
      status,
      description,
      featuredCharacter: featuredCharacter!,
      advertising,
      draft,
      notes,
      tag,
    };
    await dispatch(saveCharacter(character) as any);

    // Reset the form
    setName("");
    setStatus("");
    setDescription("");
    setCustomNotes("");

    navigate("/administration/listcharacters");
  };

  const updateScreenHeight = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const canBeSaved =
    name !== "" &&
    status !== "" &&
    description !== "" &&
    notes !== "" &&
    featuredCharacter !== undefined;

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <form onSubmit={handleSubmit}>
          <div className="page-main">
            <PageTitle title="Add Character" />
            <div className="items">
              <div className="item-simple-input">
                <div className="text-title bodybold">Name*</div>
                <input
                  className="input-label input-default-without-icon"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Status*</div>
                <Select
                  className="dropdown-content"
                  options={statusOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div className="item-simple-input">
                <div className="text-title bodybold">Short Description*</div>
                <input
                  className="input-default-without-icon input-label"
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
              <div className="item-simple-input">
                <div className="text-title bodybold">Tag</div>
                <input
                  className="input-default-without-icon input-label"
                  type="text"
                  value={tag}
                  onChange={handleTagChange}
                />
              </div>
              <ItemStringWithSubtitle
                title="URL Slug"
                subtitle="www.fictiono.us/c/[slug]"
                defaultInput="GPT"
              />
              <div className="item-no-input">
                <div className="text-title bodybold">Character Avatar</div>
                <div
                  className="button-action"
                  onClick={() => setOpenChangeProfile(true)}
                >
                  <div className="button-label-white">Upload</div>
                </div>
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Featured Character*</div>
                <Select
                  className="dropdown-content"
                  options={featuredOptions}
                  onChange={handleFeaturedChange}
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Advertising</div>
                <Select
                  className="dropdown-content"
                  options={adsOptions}
                  onChange={handleAdsChange}
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Draft</div>
                <Select
                  className="dropdown-content"
                  options={draftOptions}
                  onChange={handleDraftChange}
                />
              </div>
              <div className="item-simple-input">
                <TextGroup
                  title="Notes*"
                  subtitle="Internal notes about this character"
                />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input notes here"
                  value={notes}
                  onChange={handleNotesChange}
                />
              </div>
            </div>
          </div>
          <ChangeCharacterAvatarPopup
            OpenChangeProfile={OpenChangeProfile}
            setOpenChangeProfile={setOpenChangeProfile}
            screenWidtht={screenWidth}
            openCrop={openCrop}
            setOpenCrop={setOpenCrop}
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            croppedImage={croppedImage}
            setAvatarUrl={setAvatarUrl}
          />

          <CropCharacterProfilePopup
            setOpenCrop={setOpenCrop}
            openCrop={openCrop}
            screenWidtht={screenWidth}
            setOpenChangeProfile={setOpenChangeProfile}
            uploadedImage={uploadedImage}
            setCroppedImage={setCroppedImage}
          />
          <div className="simple-space"></div>
          <div className="actions">
            <button
              className={`${
                canBeSaved ? "button-action" : "button-disabled-action"
              }`}
              disabled={!canBeSaved}
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCharacterPage;
