import React, { CSSProperties, useEffect, useRef, useState } from "react";
import "./SharedConversation.css";
import { useNavigate, useParams } from "react-router-dom";
import "./NewConversation.css";
import Header from "../../components/header/Header";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import thumbsdown from "../../assets/images/emoji_thumbsdown.svg";
import thumbsup from "../../assets/images/emoji_thumbsup.svg";
import domtoimage from "dom-to-image";

import rolling from "../../assets/images/emoji_rolling.svg";
import BotImage from "../../assets/images/conversationBot.svg";
import SendIcon from "../../assets/images/Send.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
// import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";

import LoginSignup from "../../components/login-signup/LoginSignup";
import { fetchConversationMessages } from "../../redux/ConversationMessageSlice";
import SummaryBestAndRecentPage from "../bestandrecent/BestAndRecentOverview";
import { RemoveScroll } from "react-remove-scroll";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import SharePopup from "../../components/share-popup/SharePopup";
import {
  getUserConversationRate,
  setUserConversationRate,
} from "../../redux/ConversationRateSlice";
import { useUser } from "../../components/contexts/UserContext";
import Rate from "../../datamodel/Rate";
import Voting from "../../datamodel/Voting";
import {
  OGImagePost,
  getConversationById,
} from "../../redux/ConversationSlice";
import { Skeleton } from "@mui/material";
import Preview from "../../components/share-popup/preview";

const SharedConversation: React.FC = () => {
  const dispatch: any = useDispatch();
  const MainChat = useRef(null);
  const ChatHeader = useRef(null);
  const ChatInput = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  const [score, setScore] = useState<number>(0);
  const [vote, setVote] = useState<Voting | null>(null);

  const [triggerCollapse, settriggerCollapse] = useState(false);

  const [IsLogin, setIsLogin] = useState(false);

  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);

  const isMobile = screenWidtht < 769;

  const [focused, setFocused] = useState(false);
  const { user, setUser } = useUser();
  const [loginOpen, setLoginOpen] = useState(false);
  const [screenHeightsMb, setScreenHeightsMb] = useState(
    window.screen.availHeight
  );

  // useSelector
  const ConversionMessages = useSelector(
    (state: RootState) => state.conversationMessages?.conversationMessages
  );
  const PreviousRate = useSelector(
    (state: RootState) => state.conversationRateReducer?.rate
  );
  const PreviousScore = useSelector(
    (state: RootState) => state.conversation?.conversation?.score
  );
  const CurrentConversation = useSelector(
    (state: RootState) => state?.conversation?.conversation
  );
  const CurrentCharacter = useSelector(
    (state: RootState) => state?.conversation?.character
  );

  // image converter
  const [sourceImg, setSourceImg] = useState("");
  const [OgImage, setOgImage] = useState("");
  const [ShareLoading, setShareLoading] = useState(false);

  const convertNodeToImage = () => {
    const node = document.getElementById("image-container");

    return new Promise((res) => {
      console.log("node1 >>>>", node);
      if (node) {
        domtoimage
          .toPng(node)
          .then(function (image: any) {
            if (image) {
              setOgImage(image);
              dispatch(OGImagePost({ file: image, convoId: conversationId }));
              setShareLoading(false);
              res("complete");
              console.log("node2 >>>>", image);
            }
          })
          .catch(function (error: any) {
            console.error("Oops, something went wrong!", error);
            res("complete");
            console.log("node3 >>>>", error);
          });
      }
    });
  };

  useEffect(() => {
    if (CurrentCharacter?.avatarUrl) {
      setShareLoading(true);

      fetch("/get-img-base64", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          img: CurrentCharacter?.avatarUrl,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setSourceImg(res?.image);
          setShareLoading(false);
        });
    }
  }, [CurrentCharacter]);

  const toggleSharePopup = async () => {
    if (!isSharePopupOpen) {
      setShareLoading(true);
      await convertNodeToImage();
    }
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  // Login-Loader
  const loginCheck = () => {
    if (user === null) {
      setIsLogin(true);
      localStorage.setItem("LoginLoading", "false");
    } else {
      setIsLogin(false);
    }
  };

  const toggleLoginPopup = () => {
    setIsLogin(!IsLogin);
  };

  useEffect(() => {
    if (PreviousScore !== undefined) {
      setScore(PreviousScore);
    }
  }, [PreviousScore]);

  useEffect(() => {
    if (PreviousRate) {
      setVote(PreviousRate?.vote);
    }
  }, [PreviousRate]);

  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidtht(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  function scrollToTop() {
    return new Promise((res) => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
      setTimeout(() => {
        res("scroll up");
      }, 100);
    });
  }

  const isKeyboardOpenLib = useDetectKeyboardOpen();

  useEffect(() => {
    const scrollableElement = document.querySelector("#enable-chat");
    if (isMobile) {
      // @ts-ignore
      setScreenHeightsMb(window.visualViewport?.height);
      scrollToTop().then(() => {
        disablePageScroll();
        if (scrollableElement) {
          enablePageScroll(scrollableElement);
        }
      });
    } else {
      enablePageScroll();
      setScreenHeightsMb(window.innerHeight);
    }
  }, [isKeyboardOpenLib, focused]);

  const initHEight = {
    chatScreenChange: 180,
  };

  const [focusedHeight, setFocusedHeight] = useState(initHEight);
  useEffect(() => {
    if (focused && isKeyboardOpenLib) {
      setFocusedHeight({
        chatScreenChange: 90,
      });
    } else {
      setFocusedHeight(initHEight);
    }
  }, [focused, isKeyboardOpenLib]);

  // In all below handle functions, we 1. change UX, 2, set the UX Score, 3. call the API to update the score
  const handleVoteUp = () => {
    if (user === null) {
      setIsLogin(true);
      localStorage.setItem("LoginLoading", "false");
    } else {
      if (vote === Voting.ThumbUp) {
        // unselect the vote
        setVote(Voting.Empty);
        setScore(score - 1);
        RateConversationApi(Voting.Empty);
      } else {
        setScore(updateCurrentScore(score, vote, Voting.ThumbUp));
        setVote(Voting.ThumbUp);
        RateConversationApi(Voting.ThumbUp);
      }
    }
  };
  const handleVoteDown = () => {
    if (user === null) {
      setIsLogin(true);
      localStorage.setItem("LoginLoading", "false");
    } else {
      if (vote === Voting.ThumbDown) {
        // unselect the vote
        setVote(Voting.Empty);
        setScore(score + 1);
        RateConversationApi(Voting.Empty);
      } else {
        setScore(updateCurrentScore(score, vote, Voting.ThumbDown));
        setVote(Voting.ThumbDown);
        RateConversationApi(Voting.ThumbDown);
      }
    }
  };
  const handleVoteSmily = () => {
    if (user === null) {
      setIsLogin(true);
      localStorage.setItem("LoginLoading", "false");
    } else {
      if (vote === Voting.Smily) {
        // unselect the vote
        setVote(Voting.Empty);
        setScore(score - 1);
        RateConversationApi(Voting.Empty);
      } else {
        setScore(updateCurrentScore(score, vote, Voting.Smily));
        setVote(Voting.Smily);
        RateConversationApi(Voting.Smily);
      }
    }
  };

  const { conversationId } = useParams<{
    conversationId: string;
  }>();

  useEffect(() => {
    dispatch(fetchConversationMessages(conversationId!) as any);
    dispatch(getConversationById({ conversationId, dispatch }));
  }, [dispatch, conversationId]);

  const RateConversationApi = (vote: Voting) => {
    const data: Rate = {
      // userId: user?.attributes?.email,
      userId: encodeURIComponent(user?.attributes?.email),
      conversationId: conversationId!,
      vote: vote,
    };
    if (data) {
      dispatch(setUserConversationRate(data));
    }
  };

  React.useLayoutEffect(() => {
    if (conversationId && user) {
      dispatch(
        getUserConversationRate({
          // userId: user?.attributes?.email,
          userId: encodeURIComponent(user?.attributes?.email),
          conversationId,
        }) as any
      );
    }
  }, [user, conversationId]);

  const backgroundImageStyle: CSSProperties = {
    width: "100%",
    minHeight: "100%",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "absolute",
    backgroundImage: `linear-gradient(to right, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    url(${CurrentCharacter?.avatarUrl || DefaultImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 0.2,
    paddingBottom: "20px",
  };

  const contentStyle: CSSProperties = {
    zIndex: 1,
  };

  // title of shared page
  useEffect(() => {
    document.title = `${CurrentCharacter?.name} on Fictiono.us - [${CurrentConversation?.content}]`;
  }, [CurrentCharacter, CurrentConversation]);

  return (
    <>
      {/* <Helmet>
        <meta property="og:title" content="Title - this is title" />
        <meta
          property="og:description"
          content="OG Description - this is OG description"
        />
        <meta
          property="og:image"
          content="https://cdn.pixabay.com/photo/2011/12/13/14/31/earth-11015_1280.jpg"
        />
      </Helmet> */}

      <div className="container">
        {showDropdown && (
          <div className="toggle-dropdown">
            <div className="new-conversation-dropdown-item">
              <a>EinsteinJobs</a>
            </div>
            <div className="new-conversation-dropdown-item">
              <a>JesusGPT</a>
            </div>
          </div>
        )}

        {!isMobile ? (
          <Header
            triggerCollapse={triggerCollapse}
            settriggerCollapse={settriggerCollapse}
            isMobile={isMobile}
            loginOpen={loginOpen}
            setLoginOpen={setLoginOpen}
          />
        ) : null}

        <div className="chat-main-container">
          <div className="header-page-maindiv">
            {isMobile ? (
              <Header
                triggerCollapse={triggerCollapse}
                settriggerCollapse={settriggerCollapse}
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}
              />
            ) : null}

            <div
              style={{
                height: isMobile ? "auto" : `${screenHeight - 140}px`,
              }}
              className="main-chat-page"
              ref={MainChat}
            >
              <div className="header-character" ref={ChatHeader}>
                <div className="character">
                  <img
                    className="avatar"
                    src={CurrentCharacter?.avatarUrl || DefaultImage}
                    alt="default"
                  />

                  <div className="title">
                    <div className="character-name">
                      <h1 className="name dmmono-medium-turquoise-24px">
                        {CurrentCharacter?.name
                          ? CurrentCharacter?.name
                          : "Loading..."}
                      </h1>
                    </div>
                    <div className="character-description">
                      {CurrentCharacter?.description
                        ? CurrentCharacter?.description
                        : "Description here..."}
                    </div>
                  </div>
                  <button onClick={toggleSharePopup} className="share-button">
                    <span className="share-button-text">Share</span>
                    {ShareLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <img
                        className="share-icon"
                        src={SendIcon}
                        alt="Share Icon"
                      />
                    )}
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  height: isMobile
                    ? `${
                        screenHeightsMb - focusedHeight.chatScreenChange + 5
                      }px`
                    : "40vh",
                }}
              >
                <>
                  {isMobile ? (
                    <RemoveScroll forwardProps>
                      <div
                        id="enable-chat"
                        className="message-window"
                        style={{
                          height: isMobile ? "100%" : "auto",
                          width: isMobile
                            ? "100vw"
                            : //@ts-ignore
                              MainChat?.current?.clientWidth,
                        }}
                      >
                        <div style={backgroundImageStyle}></div>
                        <div
                          style={{
                            ...contentStyle,
                            width: isMobile
                              ? "90%"
                              : //@ts-ignore
                                MainChat?.current?.clientWidth - 40,
                            height: `${
                              screenHeight -
                              125 -
                              //@ts-ignore
                              ChatHeader?.current?.clientHeight -
                              //@ts-ignore
                              ChatInput?.current?.clientHeight
                            }px`,
                            overflowY: "auto",
                            position: "absolute",
                          }}
                          className="message-body"
                        >
                          <div className={`message-Text`}>
                            <p className="message-start-text">So I asked</p>
                          </div>
                          {ConversionMessages?.map((message, index) => (
                            <div
                              key={index}
                              className={`message-${
                                message.senderRole === "user"
                                  ? "user"
                                  : "assistant"
                              }`}
                            >
                              <div
                                className={`message-role-${
                                  message.senderRole === "user"
                                    ? "user"
                                    : "assistant"
                                }`}
                              >
                                {message.senderRole === "user" ? (
                                  <img
                                    className="message-avartar"
                                    src={BotImage}
                                    alt="user"
                                  />
                                ) : (
                                  <img
                                    className="message-avartar"
                                    src={
                                      CurrentCharacter?.avatarUrl ||
                                      DefaultImage
                                    }
                                    alt="assistant"
                                  />
                                )}
                              </div>
                              <div
                                className={`message-content-${
                                  message.senderRole === "user"
                                    ? "user"
                                    : "assistant"
                                }`}
                              >
                                <p className={`message-text`}>{message.data}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </RemoveScroll>
                  ) : (
                    <div
                      id="enable-chat"
                      className="message-window"
                      style={{
                        height: isMobile ? "100%" : "auto",
                        width: isMobile
                          ? "100vw"
                          : //@ts-ignore
                            MainChat?.current?.clientWidth,
                      }}
                    >
                      <div style={backgroundImageStyle}></div>
                      <div
                        style={{
                          ...contentStyle,
                          width: MainChat
                            ? //@ts-ignore
                              isMobile
                              ? //@ts-ignore
                                MainChat?.current?.clientWidth - 40
                              : //@ts-ignore
                                MainChat?.current?.clientWidth - 80
                            : `${screenWidtht - 460}px`,
                          height: `${
                            screenHeight -
                            189 -
                            //@ts-ignore
                            ChatHeader?.current?.clientHeight -
                            //@ts-ignore
                            ChatInput?.current?.clientHeight
                          }px`,
                          overflowY: "auto",
                          position: "absolute",
                        }}
                        className="message-body"
                      >
                        <div className={`message-Text`}>
                          <p className="message-start-text">So I asked</p>
                        </div>
                        {ConversionMessages?.map((message, index) => (
                          <div
                            key={index}
                            className={`message-${
                              message.senderRole === "user"
                                ? "user"
                                : "assistant"
                            }`}
                          >
                            <div
                              className={`message-role-${
                                message.senderRole === "user"
                                  ? "user"
                                  : "assistant"
                              }`}
                            >
                              {message.senderRole === "user" ? (
                                <img
                                  className="message-avartar"
                                  src={BotImage}
                                  alt="user"
                                />
                              ) : (
                                <img
                                  className="message-avartar"
                                  src={
                                    CurrentCharacter?.avatarUrl || DefaultImage
                                  }
                                  alt="assistant"
                                />
                              )}
                            </div>
                            <div
                              className={`message-content-${
                                message.senderRole === "user"
                                  ? "user"
                                  : "assistant"
                              }`}
                            >
                              <p className={`message-text`}>{message.data}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              </div>

              <div
                className={isMobile ? "chat-window-mobo" : "chat-window"}
                ref={ChatInput}
              >
                <div
                  className={
                    isMobile ? "rating-options-mob" : "rating-options-no"
                  }
                >
                  <RatingComponent
                    vote={vote}
                    handleVoteUp={handleVoteUp}
                    handleVoteDown={handleVoteDown}
                    handleVoteSmily={handleVoteSmily}
                    score={score}
                  />
                </div>

                <div className="rating-option-mobile">
                  <button
                    className="reating-button"
                    onClick={() =>
                      navigate(
                        `/newconversation/${CurrentConversation?.characterId}/${CurrentConversation?.promptId}`
                      )
                    }
                  >
                    <span className="reating-button-text">New Chat</span>
                  </button>

                  <button
                    className="reating-button"
                    onClick={() => navigate(`/bestandrecent`)}
                  >
                    <span className="reating-button-text">Best</span>
                  </button>

                  <button
                    onClick={toggleSharePopup}
                    className="rating-share-button"
                  >
                    <span className="rating-share-button-text">Share</span>

                    {ShareLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <img
                        className="rating-share-icon"
                        src={SendIcon}
                        alt="Share Icon"
                      />
                    )}
                  </button>
                </div>

                <div className="rating-option-main">
                  <div className="rating-options">
                    <RatingComponent
                      vote={vote}
                      handleVoteUp={handleVoteUp}
                      handleVoteDown={handleVoteDown}
                      handleVoteSmily={handleVoteSmily}
                      score={score}
                    />
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <button
                      className="reating-button"
                      onClick={() =>
                        navigate(
                          `/newconversation/${CurrentConversation?.characterId}/${CurrentConversation?.promptId}`
                        )
                      }
                    >
                      <span className="reating-button-text">New Chat</span>
                    </button>

                    <button
                      className="reating-button"
                      onClick={() => navigate(`/bestandrecent`)}
                    >
                      <span className="reating-button-text">Best & Recent</span>
                    </button>
                  </div>
                </div>

                <p className="bottom-text">
                  <span>
                    <span className="">
                      Remember: you are chatting with fictional characters and
                      these conversations are public. &nbsp;
                    </span>
                  </span>
                  <span
                    className="span1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://bit.ly/fic-TOS", "_blank")
                    }
                  >
                    Terms of Service
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="best-recent-container">
            <SummaryBestAndRecentPage
              characterId={CurrentConversation?.characterId}
            />
          </div>
        </div>

        {isSharePopupOpen && OgImage ? (
          <SharePopup
            conversationId={conversationId ? conversationId : ""}
            isSharePopupOpen={isSharePopupOpen}
            toggleSharePopup={toggleSharePopup}
            OgImage={OgImage}
          />
        ) : (
          ""
        )}

        <div className="preview-style">
          <Preview
            conversationId={conversationId}
            sourceImg={sourceImg}
            sourceName={CurrentCharacter?.name}
            ConversionMessages={ConversionMessages}
          />
        </div>

        <LoginSignup
          isLoginPopupOpen={IsLogin}
          toggleLoginPopup={toggleLoginPopup}
        />
      </div>
    </>
  );
};

function RatingComponent(props: {
  vote: Voting | null;
  handleVoteUp: () => void;
  handleVoteDown: () => void;
  handleVoteSmily: () => void;
  score: number | null;
}) {
  return (
    <>
      <div className="rating-score">
        <div className="rating-number">{props.score}</div>
        <div className="rating-text">score</div>
      </div>
      <div className="rating-options-emoji">
        <div
          className={
            props.vote === Voting.ThumbUp
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteUp}
            className="rating-emoji"
            src={thumbsup}
            alt="rating-emoji"
          />
        </div>
        <div
          className={
            props.vote === Voting.ThumbDown
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteDown}
            className="rating-emoji emoji2"
            src={thumbsdown}
            alt="rating-emoji"
          />
        </div>
        <div
          className={
            props.vote === Voting.Smily
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteSmily}
            className="rating-emoji"
            src={rolling}
            alt="rating-emoji"
          />
        </div>
      </div>
    </>
  );
}

function updateCurrentScore(
  score: number,
  preVote: Voting | null,
  vote: Voting
): number {
  if (preVote === vote) {
    return score;
  }
  if (preVote === null || preVote === Voting.Empty) {
    return updateScoreDefaultly(score, vote);
  }

  switch (preVote) {
    case Voting.ThumbUp: // from up to down, -2, to empty -1, to smily is 0 since smily is also 1
      return vote === Voting.ThumbDown
        ? score - 2
        : vote === Voting.Empty
        ? score - 1
        : score;
    case Voting.ThumbDown: // from down to up or smily +2, to empty +1
      return vote === Voting.ThumbUp || vote === Voting.Smily
        ? score + 2
        : score + 1;
    case Voting.Smily: // from smily to down -2, to empty -1, to up is 0
      return vote === Voting.ThumbDown
        ? score - 2
        : vote === Voting.Empty
        ? score - 1
        : score;
    default:
      return score;
  }
}

function updateScoreDefaultly(score: number, vote: Voting): number {
  switch (vote) {
    case Voting.ThumbUp:
      return score + 1;
    case Voting.ThumbDown:
      return score - 1;
    case Voting.Smily:
      return score + 1;
    default:
      return score;
  }
}
export default SharedConversation;
