import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import Conversation from "../datamodel/Conversation";
import { toast } from "react-toastify";
// import Character from "../datamodel/Character";

interface ConversationState {
  // conversation: Conversation | null;
  recentStatus: boolean;
  bestStatus: boolean;
  error: string | null;
  // character: Character | null;
  getRecentActivityDataMyChat: any;
  getBestActivityDataMyChat: any;
  // getAllCharacterData1: any;
}

const initialState: ConversationState = {
  // conversation: null,
  recentStatus: false,
  bestStatus: false,
  error: null,
  // character: null,
  getRecentActivityDataMyChat: [],
  getBestActivityDataMyChat: [],
  // getAllCharacterData1: [],
};

// export const createConversation = createAsyncThunk(
//   "conversations/createConversation",
//   async (conversation: Conversation) => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BACKEND_API_URL}/conversations`,
//         conversation
//       );
//       if (response.status < 200 && response.status >= 300) {
//         toast.error("Failed to save conversation.");
//       }
//       return response.data;
//     } catch (error) {
//       toast.error("Failed to save conversation.");
//       return error;
//     }
//   }
// );

// export const getConversationById = createAsyncThunk(
//   "conversation/getConversationById",
//   async (props: any) => {
//     const { conversationId, dispatch } = props;
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND_API_URL}/conversations/${conversationId}`
//       );
//       if (response?.data?.characterId) {
//         dispatch(getCharacterById({ cid: response?.data?.characterId }));
//         return response.data;
//       }
//     } catch (error) {
//       toast.error("Failed to fetch conversations.");
//       throw error;
//     }
//   }
// );

// export const getCharacterById = createAsyncThunk(
//   "GetCharacterById",
//   async (props: any) => {
//     const { cid } = props;
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND_API_URL}/characters/${cid}`
//       );
//       return response.data;
//     } catch (error) {
//       toast.error("Failed to fetch conversations.");
//       throw error;
//     }
//   }
// );

export const getRecentActivityMyChat = createAsyncThunk(
  "getRecentActivityMyChat",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId, user } = props;

    try {
      let apiUrl = `${
        process.env.REACT_APP_BACKEND_API_URL
      }/conversations/recent?limit=10&user=${user?.replace("@", "%2540")}`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

export const getBestActivityMyChat = createAsyncThunk(
  "getBestActivityMyChat",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId, user } = props;
    try {
      let apiUrl = `${
        process.env.REACT_APP_BACKEND_API_URL
      }/conversations/best?limit=10&user=${user?.replace("@", "%2540")}`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

export const MyChatBestRecentSlice = createSlice({
  name: "MyChatBestRecentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Create conversation
    // builder.addCase(createConversation.pending, (state, action) => {
    //   state.status = true;
    // });
    // builder.addCase(createConversation.fulfilled, (state, action) => {
    //   state.status = true;
    //   state.conversation = action.payload;
    // });
    // builder.addCase(createConversation.rejected, (state, action) => {
    //   state.status = false;
    //   state.error = action.payload as string;
    // });

    // Get Conversation information, not messages
    // builder.addCase(getConversationById.pending, (state) => {
    //   state.status = true;
    // });
    // builder.addCase(getConversationById.fulfilled, (state, action) => {
    //   state.status = true;
    //   state.conversation = action.payload;
    // });
    // builder.addCase(getConversationById.rejected, (state, action) => {
    //   state.status = false;
    //   state.error = action.payload as string;
    // });

    // getCharacterById
    // builder.addCase(getCharacterById.pending, (state) => {
    //   state.status = true;
    // });
    // builder.addCase(getCharacterById.fulfilled, (state, action) => {
    //   state.status = true;
    //   state.character = action.payload;
    // });
    // builder.addCase(getCharacterById.rejected, (state, action) => {
    //   state.status = false;
    //   state.error = action.payload as string;
    // });

    // getRecentActivityMyChat
    builder.addCase(getRecentActivityMyChat.pending, (state, action) => {
      state.recentStatus = true;
    });
    builder.addCase(getRecentActivityMyChat.fulfilled, (state, action) => {
      state.recentStatus = false;
      state.getRecentActivityDataMyChat = action.payload;
    });
    builder.addCase(getRecentActivityMyChat.rejected, (state, action) => {
      state.recentStatus = false;
    });

    // getBestActivityMyChat
    builder.addCase(getBestActivityMyChat.pending, (state, action) => {
      state.bestStatus = true;
    });
    builder.addCase(getBestActivityMyChat.fulfilled, (state, action) => {
      state.bestStatus = false;
      state.getBestActivityDataMyChat = action.payload;
    });
    builder.addCase(getBestActivityMyChat.rejected, (state, action) => {
      state.bestStatus = false;
    });
  },
});

export default MyChatBestRecentSlice.reducer;
