import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BestAndRecentOverview.css";
import LightBulbImage from "../../assets/images/light-bulb.svg";
import LightBulbImageSelect from "../../assets/images/light-bulb-turquoise.svg";
import StarImageSelect from "../../assets/images/star.svg";
import StarImage from "../../assets/images/star-black.svg";
import Conversation from "../../components/conversation/Conversation";
import { useDispatch, useSelector } from "react-redux";
import {
  getBestActivity,
  getRecentActivity,
} from "../../redux/ConversationSlice";
import RectangleSkeleton from "../../components/Skeleton/RectangleSkeleton";

const BestAndRecentMobile = (props: any) => {
  const { best, recent, RecentSee, BestSee, recentStatus, bestStatus } = props;

  const [RecentBestSelected, setRecentBestSelected] = useState("recent");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="best-recent-summary">
        <header className="best-recent-header">
          <article
            onClick={() => {
              setRecentBestSelected("recent");
            }}
            className={
              RecentBestSelected === "recent"
                ? "recentorbest-selected"
                : "recentorbest-nonselected"
            }
          >
            <div
              className={
                RecentBestSelected === "recent"
                  ? "button-label-selected titlesubtitle"
                  : "button-label-nonselected titlesubtitle"
              }
            >
              Recent
            </div>
            {RecentBestSelected === "recent" ? (
              <img src={LightBulbImage} alt="light-bulb" />
            ) : (
              <img src={LightBulbImageSelect} alt="light-bulb" />
            )}
          </article>
          <article
            onClick={() => {
              setRecentBestSelected("best");
            }}
            className={
              RecentBestSelected === "best"
                ? "recentorbest-selected"
                : "recentorbest-nonselected"
            }
          >
            <div
              className={
                RecentBestSelected === "best"
                  ? "button-label-selected titlesubtitle"
                  : "button-label-nonselected titlesubtitle"
              }
            >
              Best
            </div>
            {RecentBestSelected === "best" ? (
              <img src={StarImage} alt="star" />
            ) : (
              <img src={StarImageSelect} alt="star" />
            )}
          </article>
        </header>

        <div className="frame-conversation-overview">
          {RecentBestSelected === "recent" ? (
            recentStatus ? (
              Array.from({ length: 5 }, (_, index) => <RectangleSkeleton />)
            ) : recent.length > 0 ? (
              recent?.map((data: any) => (
                <Conversation
                  id={data._id}
                  avatar={data.avatar}
                  content={data.content ? data.content : "N/A"}
                  score={data.score}
                  emojiImage={data.emojiImage}
                  characterName={data.characterName}
                  characterId={data.characterId}
                  promptId={data.promptId}
                  visible={data.visible}
                  key={data._id}
                  userId={data.userId}
                />
              ))
            ) : (
              <div className="no-data-found-screen">No Recent Chats</div>
            )
          ) : bestStatus ? (
            Array.from({ length: 5 }, (_, index) => <RectangleSkeleton />)
          ) : best.length > 0 ? (
            best?.map((data: any) => (
              <Conversation
                id={data._id}
                avatar={data.avatar}
                content={data.content ? data.content : "N/A"}
                score={data.score}
                emojiImage={data.emojiImage}
                characterName={data.characterName}
                characterId={data.characterId}
                promptId={data.promptId}
                visible={data.visible}
                key={data._id}
                userId={data.userId}
              />
            ))
          ) : (
            <div className="no-data-found-screen">No Best Chats</div>
          )}
          <div
            className="see-more"
            onClick={() => {
              RecentSee();
              BestSee();
            }}
          >
            <div
              className="see-more-btn"
              onClick={() => {
                RecentSee();
                BestSee();
              }}
            >
              See More
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestAndRecentMobile;
