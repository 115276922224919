import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/LoginLogo.svg";
import GoogleLogo from "../../assets/images/GoogleLogo.svg";
import facebookLogo from "../../assets/images/FacebookLoginLogo.svg";
import Close from "../../assets/images/x.svg";
import { Auth, Hub } from "aws-amplify";
import { useUser } from "../contexts/UserContext";
import "./LoginSignup.css";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { enablePageScroll } from "scroll-lock";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

interface LoginSignupProps {
  isLoginPopupOpen: boolean;
  toggleLoginPopup: () => void;
}
const array = ["login", "signUp"];

interface HubPayload {
  event: string;
  data?: any;
  message?: string;
}

const LoginSignup: React.FC<LoginSignupProps> = ({
  isLoginPopupOpen,
  toggleLoginPopup,
}) => {
  const MainWidth = useRef(null);
  const [isLoginMode, setIsLoginMode] = useState(true); // Use state to toggle between login and signup
  const [activeTab, setActiveTab] = useState("login");
  const [isChecked, setIsChecked] = useState(false);
  const [isBouncing, setIsBouncing] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);
  const isMobile = window.innerWidth <= 768;

  // Sign in sign up functions
  const { user, setUser } = useUser();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [isConfirmationView, setIsConfirmationView] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [checkboxError, setCheckboxError] = useState<string>("");
  const [codeError, setCodeError] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string) => {
    // Regular expression for a valid email address
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      return false;
    }

    setEmailError(""); // Clear the email error if email is valid
    return true;
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setCheckboxError(""); // Clear the checkbox error when the checkbox is checked/unchecked
  };

  const emailSignIn = async () => {
    if (!validateEmail(email)) {
      return; // Don't proceed if email is invalid
    }

    try {
      // console.log("Signing in..." + email + " " + password);
      Hub.listen("auth", (data: { payload: HubPayload }) => {
        if (data.payload.data?.message.toLowerCase().includes("email")) {
          setEmailError(data.payload.data?.message);
          setIsLoading(false);
        } else if (
          data.payload.data?.message.toLowerCase().includes("password")
        ) {
          setPasswordError(data.payload.data?.message);
          setIsLoading(false);
        } else if (data.payload.data?.message.toLowerCase().includes("")) {
          setPasswordError(data.payload.data?.message);
          setIsLoading(false);
        }
        //  setEmailError(data.payload.data?.message)
        // console.log("emailSignIn ", data.payload.data?.message);
        // console.log("emailSignIn data ", data);
      });
      setIsLoading(true);
      const user = await Auth.signIn(email, password);
      // const user =  {attributes: {"custom:accessrole": "admin"}};
      // console.log("User: ", user);
      if (user !== null && user !== undefined) {
        setIsLoading(false);
      }
      toggleLoginPopup();
      setUser(user);
    } catch (error) {
      console.error("Error signing in", error);
    }
  };

  const emailSignUp = async () => {
    try {
      // if (password !== confirmPassword) {
      //   window.confirm("Passwords do not match");
      //   return;

      // }
      if (!validateEmail(email)) {
        return; // Don't proceed if email is invalid
      }
      if (password !== confirmPassword) {
        setPasswordError("Passwords do not match");
        return;
      } else {
        setPasswordError("");
      }
      if (!isChecked) {
        setCheckboxError("Please agree to the terms and privacy policy");
        return; // Don't proceed if the checkbox is not checked
      }
      Hub.listen("auth", (data: { payload: HubPayload }) => {
        if (data.payload.data?.message.toLowerCase().includes("email")) {
          setEmailError(data.payload.data?.message);
        } else if (
          data.payload.data?.message.toLowerCase().includes("password")
        ) {
          setPasswordError(data.payload.data?.message);
        }
      });
      await Auth.signUp({
        username: email,
        password: password,
      });

      setIsConfirmationView(true);
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const googleLoginSignup = async () => {
    try {
      localStorage.setItem("LoginLoading", "true");
      setIsLoading(false);
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });

      if (user !== null && user !== undefined) {
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("Error googleLoginSignup:", error);
      setIsLoading(false);
    }
  };

  const confirmSignUp = async () => {
    if (!isChecked) {
      setCheckboxError("Please agree to the terms and privacy policy");
      setIsLoading(false);
      return; // Don't proceed if the checkbox is not checked
    }
    try {
      const user = await Auth.confirmSignUp(email, confirmationCode);

      if (user === "SUCCESS") {
        toast.success("User sign up successfully!");
        setActiveTab("login");
      } else {
        toast.error("Failed to sign up.");
      }
    } catch (error) {
      setCodeError("Invalid verification code");
      setIsLoading(false);
    }
  };

  // Other functions
  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    setIsBouncing(false);
    setTimeout(() => {
      setIsBouncing(true);
    }, 400);
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const toggleButton = document.getElementById(
    "toggleButton"
  ) as HTMLButtonElement | null;
  document.getElementById("toggleButton");

  let isToggled = false;

  function toggle() {
    isToggled = !isToggled; // Toggle the state
    toggleButton?.classList.toggle("toggled", isToggled); // Add/remove the "toggled" class
  }
  toggleButton?.addEventListener("click", toggle);

  const toggleMode = () => {
    setIsLoginMode(!isLoginMode); // Toggle between login and signup mode
  };

  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidtht(window.innerWidth);
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const isKeyboardOpenLib = useDetectKeyboardOpen();
  useEffect(() => {
    enablePageScroll();
  }, [isKeyboardOpenLib]);

  return (
    <Dialog
      fullScreen={isMobile}
      onClose={toggleLoginPopup}
      open={isLoginPopupOpen}
    >
      <div
        className="login-signup-main"
        style={{
          height: isMobile ? `${screenHeight}px` : "",
          overflowY: isMobile ? "scroll" : "visible",
        }}
      >
        <div className="login-signup-header">
          <div className="login-signup-header-logo">
            <img src={Logo} alt="logo" className="login-signup-logo-img" />
          </div>
          <div onClick={toggleLoginPopup}>
            <img className="menu" src={Close} alt="menu" />
          </div>
        </div>

        <div
          className="login-signup-container"
          style={{
            height: isMobile ? `${screenHeight}px` : "",
            overflowY: isMobile ? "scroll" : "visible",
          }}
        >
          <div className="login-signup-toggle" ref={MainWidth}>
            <button
              className={`login-signup-btns ${
                activeTab === "login" ? "active" : ""
              }`}
              onClick={() => {
                handleTabClick("login");
                setPasswordError("");
                setPassword("");
                setEmail("");
              }}
            >
              <span className="login-signup-btntext"> Log In</span>
            </button>
            <button
              className={`login-signup-btns ${
                activeTab === "signup" ? "active" : ""
              }`}
              onClick={() => {
                handleTabClick("signup");
                setPasswordError("");
                setPassword("");
                setEmail("");
              }}
            >
              <span className="login-signup-btntext">Sign Up</span>
            </button>

            <div
              style={{
                //@ts-ignore
                width: MainWidth?.current?.clientWidth / 2,
              }}
              className={`slide ${activeTab === "signup" ? "signup" : ""} ${
                isBouncing ? "bounce" : ""
              }`}
            >
              <div className="slide-container"></div>
            </div>
          </div>

          {activeTab === "login" ? (
            <>
              <div className="input-container">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <div className="error-message">{emailError}</div>
              </div>
              <div className="input-container">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type="password"
                />
                <div className="error-message">{passwordError}</div>
              </div>
              <div
                onClick={emailSignIn}
                className="login-signup-buttons-content login-google-button"
              >
                <div className="login-signup-btn">
                  {/* <button onClick={emailSignIn}> */}
                  <div className="login-signup-btn-text">
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Log In"
                    )}
                  </div>
                  {/* </button> */}
                </div>
              </div>

              <span className="terms-service-text">Forgot your password?</span>

              <div className="login-signup-toggle-cont"></div>

              <div
                className="login-signup-buttons-content"
                // onClick={async () =>

                //   await Auth.federatedSignIn({
                //     provider: CognitoHostedUIIdentityProvider.Google,
                //   })

                // }
                onClick={googleLoginSignup}
              >
                <div className="login-signup-btn">
                  <img
                    className="login-with-logo"
                    src={GoogleLogo}
                    alt="login-with-logo"
                  />
                  <div className="login-with">Log In with Google</div>
                </div>
              </div>
              <div
                className="login-signup-buttons-content apple"
                onClick={async () =>
                  await Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
              >
                <div className="login-signup-btn">
                  <img
                    className="login-with-logo"
                    src={facebookLogo}
                    alt="login-with-logo"
                  />
                  <div className="login-with apple-txt">
                    {/* <button
                    onClick={() =>
                      Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Facebook,
                      })
                    }
                  > */}
                    Log In with Facebook
                    {/* </button> */}
                  </div>
                </div>
              </div>
              <div className="login-signup-terms">
                <div className="login-signup-termstext">
                  By {isLoginMode ? "logging in" : "signing up"} you agree to
                  Fictiono.us
                  <span
                    className="terms-service-text"
                    onClick={() =>
                      window.open("https://bit.ly/fic-TOS", "_blank")
                    }
                  >
                    {" "}
                    Terms of Service
                  </span>{" "}
                  and
                  <span
                    className="terms-service-text"
                    onClick={() =>
                      window.open("https://bit.ly/fic-privacy", "_blank")
                    }
                  >
                    {" "}
                    Privacy Policy
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {isConfirmationView ? (
                <>
                  <div className="input-container">
                    <input
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                      placeholder="Confirmation Code"
                    />
                    <div className="error-message">{codeError}</div>
                  </div>
                  <button
                    className={`login-signup-buttons-content login-google-button ${
                      isChecked ? "" : "disabled"
                    } `}
                    onClick={confirmSignUp}
                    // disabled={!isChecked}
                  >
                    <div className="login-signup-btn">
                      <div className="login-signup-btn-text">
                        {isLoading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Confirm Sign Up"
                        )}
                      </div>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <div className="main-text-signuptext">
                    Sign Up to vote, chat with characters, and make the most of
                    fictiono. us
                  </div>
                  <div className="input-container">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                    <div className="error-message">{emailError}</div>
                  </div>
                  <div className="input-container">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      type="password"
                    />
                  </div>
                  <div className="input-container">
                    <input
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                      type="password"
                    />
                    <div className="error-message">{passwordError}</div>
                  </div>
                  <div
                    className={
                      checkboxError
                        ? "login-signup-terms-error"
                        : "login-signup-terms"
                    }
                  >
                    <div className="ant">
                      <div>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className={
                            checkboxError
                              ? "checkbox-default-error"
                              : "checkbox-default"
                          }
                        />
                      </div>
                      <span className="paragrapgh-text-signup">
                        I agree to the
                        <span
                          className="terms-service-text"
                          onClick={() =>
                            window.open("https://bit.ly/fic-TOS", "_blank")
                          }
                        >
                          {" "}
                          Terms of service{" "}
                        </span>
                        and
                        <span
                          className="terms-service-text"
                          onClick={() =>
                            window.open("https://bit.ly/fic-privacy", "_blank")
                          }
                        >
                          {" "}
                          Privacy Policy
                        </span>
                      </span>
                    </div>
                  </div>

                  <button
                    className={`login-signup-buttons-content login-google-button ${
                      isChecked ? "" : "disabled"
                    } `}
                    onClick={emailSignUp}
                    // disabled={!isChecked}
                  >
                    <div className="login-signup-btn">
                      <div className="login-signup-btn-text">Sign Up</div>
                    </div>
                  </button>

                  <div className="login-signup-toggle-cont"></div>

                  <div
                    className="login-signup-buttons-content-sign"
                    onClick={googleLoginSignup}
                    // onClick={async () =>
                    //   await Auth.federatedSignIn({
                    //     provider: CognitoHostedUIIdentityProvider.Google,
                    //   })
                    // }
                  >
                    <div className="login-signup-btn">
                      <img
                        className="login-with-logo"
                        src={GoogleLogo}
                        alt="login-with-logo"
                      />
                      <div className="login-with">Sign up with Google</div>
                    </div>
                  </div>
                  <div
                    className="login-signup-buttons-content-sign login-signup-buttons apple"
                    onClick={async () =>
                      await Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Facebook,
                      })
                    }
                  >
                    <div className="login-signup-btn">
                      <img
                        className="login-with-logo"
                        src={facebookLogo}
                        alt="login-with-logo"
                      />
                      <div className="login-with apple-txt">
                        Sign up with Facebook
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* <div
                className={
                  checkboxError
                    ? "login-signup-terms-error"
                    : "login-signup-terms"
                }
              >
                <div className="ant">
                  <div>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className={
                        checkboxError
                          ? "checkbox-default-error"
                          : "checkbox-default"
                      }
                    />
                  </div>
                  <span className="paragrapgh-text-signup">
                    I agree to the
                    <span className="terms-service-text">
                      {" "}
                      Terms of service{" "}
                    </span>
                    and
                    <span className="terms-service-text"> Privacy Policy</span>
                  </span>
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default LoginSignup;
