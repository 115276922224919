import React, { useEffect, useRef, useState } from "react";

import "../../pages/bestandrecent/BestAndRecentPage.css";
import "./MyChats.css";

import Conversation from "../../components/conversation/Conversation";
import LightBulbImage from "../../assets/images/light-bulb-turquoise.svg";
import StarImage from "../../assets/images/star.svg";
import BestAndRecentMobile from "../../pages/bestandrecent/BestAndRecentMobile";
import { enablePageScroll } from "scroll-lock";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import MyChatCharacterDropdown from "./MyChatCharacterDropdown";
import { useDispatch, useSelector } from "react-redux";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import RectangleSkeleton from "../Skeleton/RectangleSkeleton";

import {
  getBestActivityMyChat,
  getRecentActivityMyChat,
} from "../../redux/MyChatBestRecentSlice";
import { getAllCharacter } from "../../redux/BestRecentSlice";

interface MyChatsProps {
  currentUser: string;
}

const MyChats: React.FC<MyChatsProps> = ({ currentUser }) => {
  const dispatch: any = useDispatch();
  const dropdownRef = useRef(null);
  const callOnce = useRef(0);

  const [showDropdown, setShowDropdown] = useState(false);
  const [ads, setads] = useState(true);
  const [characterId, setCharacterId] = React.useState<any>();
  const getAllCharacterData = useSelector(
    (state: any) => state.BestRecentSlice.getAllCharacterData1
  );

  const RecentActivity = useSelector(
    (state: any) => state.MyChatBestRecentSlice.getRecentActivityDataMyChat
  );

  const BestActivity = useSelector(
    (state: any) => state.MyChatBestRecentSlice.getRecentActivityDataMyChat
  );

  const status = useSelector((state: any) => state.MyChatBestRecentSlice);

  useEffect(() => {
    if (callOnce.current < 1 && currentUser) {
      dispatch(
        getRecentActivityMyChat({
          characterId: null,
          user: decodeURIComponent(currentUser),
        })
      );
      dispatch(
        getBestActivityMyChat({
          characterId: null,
          user: decodeURIComponent(currentUser),
        })
      );
      dispatch(getAllCharacter());
      callOnce.current += 1;
    }
  }, [currentUser]);

  const isKeyboardOpenLib = useDetectKeyboardOpen();
  useEffect(() => {
    enablePageScroll();
  }, [isKeyboardOpenLib]);

  useEffect(() => {
    //@ts-ignore
    const handleClickOutside = (event) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    if (showDropdown) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  // see more click
  const RecentSee = () => {
    if (RecentActivity?.cursor && currentUser) {
      dispatch(
        getRecentActivityMyChat({
          characterId: characterId,
          SeeMoreId: RecentActivity?.cursor,
          user: decodeURIComponent(currentUser),
        })
      );
    }
  };

  const BestSee = () => {
    if (BestActivity?.cursor && currentUser) {
      dispatch(
        getBestActivityMyChat({
          characterId: characterId,
          SeeMoreId: BestActivity?.cursor,
          user: decodeURIComponent(currentUser),
        })
      );
    }
  };

  // for to merge previous data
  const [recent, setRecent] = useState<any[]>([]);
  const [best, setBest] = useState<any[]>([]);

  useEffect(() => {
    if (RecentActivity?.conversations) {
      setRecent((prevRecent) => {
        const existingIds = new Set(
          prevRecent.map((conversation) => conversation._id)
        );
        const uniqueConversations = RecentActivity.conversations.filter(
          (conversation: { _id: any }) => !existingIds.has(conversation._id)
        );

        return [...prevRecent, ...uniqueConversations];
      });
    }
  }, [RecentActivity]);

  useEffect(() => {
    if (BestActivity?.conversations) {
      setBest((prevBest) => {
        const existingIds = new Set(
          prevBest.map((conversation) => conversation._id)
        );
        const uniqueConversations = BestActivity.conversations.filter(
          (conversation: { _id: any }) => !existingIds.has(conversation._id)
        );

        return [...prevBest, ...uniqueConversations];
      });
    }
  }, [BestActivity]);

  return (
    <>
      <div>
        <div>
          <MyChatCharacterDropdown
            getAllCharacter={getAllCharacterData}
            setCharacterId={setCharacterId}
            characterId={characterId}
            setBest={setBest}
            setRecent={setRecent}
            currentUser={currentUser}
          />
        </div>

        <div className="main_recent_best">
          <div
            className={`mychat_best_recent_frame best-recent-frame ${
              ads ? "" : "best-recent-frame-noAds"
            }`}
          >
            <div className="recentorbest-header">
              <img src={LightBulbImage} alt="light-bulb" />
              <div className="subtitle">Recent Chats</div>
            </div>
            <div className="frame-conversation-overview">
              {status.recentStatus ? (
                Array.from({ length: 5 }, (_, index) => <RectangleSkeleton />)
              ) : recent.length > 0 ? (
                recent?.map((conversation: any) => (
                  <Conversation
                    id={conversation._id}
                    avatar={
                      conversation.avatar ? conversation.avatar : DefaultImage
                    }
                    content={
                      conversation.content ? conversation.content : "N/A"
                    }
                    score={conversation.score}
                    emojiImage={conversation.emojiImage}
                    characterName={conversation.characterName}
                    characterId={conversation.characterId}
                    promptId={conversation.promptId}
                    visible={conversation.visible}
                    userId={conversation.userId}
                  />
                ))
              ) : (
                <div className="no-data-found-screen">No Recent Chats</div>
              )}

              <div
                className="see-more"
                style={{
                  cursor:
                    RecentActivity?.cursor !== null ? "pointer" : "not-allowed",
                }}
                onClick={RecentSee}
              >
                <div
                  className="see-more-btn"
                  style={{
                    cursor:
                      RecentActivity?.cursor !== null
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onClick={RecentSee}
                >
                  See More
                </div>
              </div>
            </div>
          </div>

          <div
            className={`mychat_best_recent_frame best-recent-frame ${
              ads ? "" : "best-recent-frame-noAds"
            }`}
          >
            <div className="recentorbest-header">
              <img src={StarImage} alt="start" />
              <div className="subtitle">Best Chats</div>
            </div>
            <div className="frame-conversation-overview">
              {status.bestStatus ? (
                Array.from({ length: 5 }, (_, index) => <RectangleSkeleton />)
              ) : best.length > 0 ? (
                best?.map((conversation: any) => (
                  <Conversation
                    id={conversation._id}
                    avatar={
                      conversation.avatar ? conversation.avatar : DefaultImage
                    }
                    content={
                      conversation.content ? conversation.content : "N/A"
                    }
                    score={conversation.score}
                    emojiImage={conversation.emojiImage}
                    characterName={conversation.characterName}
                    characterId={conversation.characterId}
                    promptId={conversation.promptId}
                    visible={conversation.visible}
                    userId={conversation.userId}
                  />
                ))
              ) : (
                <div className="no-data-found-screen">No Best Chats</div>
              )}
              <div
                className="see-more"
                style={{
                  cursor:
                    BestActivity?.cursor !== null ? "pointer" : "not-allowed",
                }}
                onClick={BestSee}
              >
                <div
                  className="see-more-btn"
                  style={{
                    cursor:
                      BestActivity?.cursor !== null ? "pointer" : "not-allowed",
                  }}
                  onClick={BestSee}
                >
                  See More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* my-chats-mobile */}
      <div className="best-resent-mychat best-recent-page-for-dropdown-mychat">
        <BestAndRecentMobile
          best={best}
          recent={recent}
          BestSee={BestSee}
          RecentSee={RecentSee}
          bestStatus={status.bestStatus}
          recentStatus={status.recentStatus}
        />
      </div>
    </>
  );
};

export default MyChats;
