import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Conversation from "../datamodel/Conversation";
import { toast } from "react-toastify";
import Character from "../datamodel/Character";

interface ConversationState {
  conversation: Conversation | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
  character: Character | null;
  getRecentActivityData1: any;
  getBestActivityData1: any;
  getAllCharacterData1: any;
  // characterId: string;
  // characterLimit: Number | null;
  // characterDate: string;
  // getBestId: any;

  recentStatus: boolean;
  bestStatus: boolean;
  bestUpdatedState:any;
  recentUpdatedState:any;
}

const initialState: ConversationState = {
  conversation: null,
  status: "idle",
  error: null,
  character: null,
  getRecentActivityData1: [],
  getBestActivityData1: [],
  getAllCharacterData1: [],
  // characterId: "",
  // characterLimit: null,
  // characterDate: "",
  // getBestId: "",

  recentStatus: false,
  bestStatus: false,
  bestUpdatedState:[],
  recentUpdatedState:[]
};

export const createConversation = createAsyncThunk(
  "conversations/createConversation",
  async (conversation: Conversation) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/conversations`,
        conversation
      );
      if (response.status < 200 && response.status >= 300) {
        toast.error("Failed to save conversation.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to save conversation.");
      return error;
    }
  }
);

export const getConversationById = createAsyncThunk(
  "conversation/getConversationById",
  async (props: any) => {
    const { conversationId, dispatch } = props;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/conversations/${conversationId}`
      );
      if (response?.data?.characterId) {
        dispatch(getCharacterById({ cid: response?.data?.characterId }));
        return response.data;
      }
    } catch (error) {
      toast.error("Failed to fetch conversations.");
      throw error;
    }
  }
);

export const getCharacterById = createAsyncThunk(
  "GetCharacterById",
  async (props: any) => {
    const { cid } = props;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/characters/${cid}`
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch conversations.");
      throw error;
    }
  }
);

export const getAllCharacter = createAsyncThunk("getAllCharacter", async () => {
  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/characters`;

    const response = await axios.get(apiUrl);
    if (response.status === 200) {
      // toast.success("fetch dropown");
    }
    return response.data;
  } catch (error) {
    toast.error("dropown error");
    return error;
  }
});

export const getRecentActivity1 = createAsyncThunk(
  "getRecentActivity1",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId } = props;

    const em = encodeURIComponent("react1001@yopmail.com");

    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/conversations/recent?limit=10`;
      // let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/conversations/recent?limit=10&user=${em}`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

export const getBestActivity1 = createAsyncThunk(
  "getBestActivity1",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId } = props;
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/conversations/best?limit=10`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

export const getBestActivity1UpdatedState = createAsyncThunk(
  "getBestActivity1UpdatedState",
  async (props: any) => {
    const data=props
    try {
      return data
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);
export const getRecentActivity1UpdatedState = createAsyncThunk(
  "getRecentActivity1UpdatedState",
  async (props: any) => {
    const data=props
    try {
      return data
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);


export const BestRecentSlice = createSlice({
  name: "BestRecentSlice",
  initialState,
  reducers: {
    // resetCharacter(state) {
    //   state.getRecentActivityData1 = [];
    //   state.getBestActivityData1 = [];
    // },
    // setGlobalCharacterId(state, action) {
    //   state.characterId = action.payload.characterId;
    //   state.characterDate = action.payload.characterdaysago;
    // },
  },
  extraReducers: (builder) => {
    // Create conversation
    builder.addCase(createConversation.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.status = "succeed";
      state.conversation = action.payload;
    });
    builder.addCase(createConversation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Conversation information, not messages
    builder.addCase(getConversationById.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConversationById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.conversation = action.payload;
    });
    builder.addCase(getConversationById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // getCharacterById
    builder.addCase(getCharacterById.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCharacterById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.character = action.payload;
    });
    builder.addCase(getCharacterById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // getRecentActivity1
    builder.addCase(getRecentActivity1.pending, (state, action) => {
      state.recentStatus = true;
    });
    builder.addCase(getRecentActivity1.fulfilled, (state, action) => {
      state.recentStatus = false;
      state.getRecentActivityData1 = action.payload;
      // console.log("getRecentActivity1");
    });
    builder.addCase(getRecentActivity1.rejected, (state, action) => {
      state.recentStatus = false;
    });

    // getBestActivity1
    builder.addCase(getBestActivity1.pending, (state, action) => {
      state.bestStatus = true;
    });
    builder.addCase(getBestActivity1.fulfilled, (state, action) => {
      state.bestStatus = false;
      state.getBestActivityData1 = action.payload;
      // state.getBestId = Date.now();
    });
    builder.addCase(getBestActivity1.rejected, (state, action) => {
      state.bestStatus = false;
    });

    // getAllCharacter
    builder.addCase(getAllCharacter.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAllCharacter.fulfilled, (state, action) => {
      state.status = "succeed";
      state.getAllCharacterData1 = action.payload;
    });
    builder.addCase(getAllCharacter.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(getBestActivity1UpdatedState.fulfilled,(state,action)=>{
      state.bestUpdatedState=action.payload
    })
    
    builder.addCase(getRecentActivity1UpdatedState.fulfilled,(state,action)=>{
      state.recentUpdatedState=action.payload
    })
  },
});

// export const { resetCharacter, setGlobalCharacterId } = BestRecentSlice.actions;
export default BestRecentSlice.reducer;
