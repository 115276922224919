import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import Rate from "../datamodel/Rate";

interface GetUserConversationRateState {
  rate: Rate | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
}

const initialState: GetUserConversationRateState = {
  rate: null,
  status: "idle",
  error: null,
};

export const getUserConversationRate = createAsyncThunk(
  "rate/getUserConversationRate",
  async (params: { userId: string; conversationId: string }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/rate/getrate`,
        {
          params: {
            userId: params.userId,
            conversationId: params.conversationId,
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch user conversation rate.");
      throw error;
    }
  }
);
export const setUserConversationRate = createAsyncThunk(
  "rate/setUserConversationRate",
  async (rate: Rate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/rate`,
        rate
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to set user conversation rate.");
      throw error;
    }
  }
);

export const rateSlice = createSlice({
  name: "rate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserConversationRate.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getUserConversationRate.fulfilled, (state, action) => {
      state.status = "succeed";
      state.rate = action.payload;
    });
    builder.addCase(getUserConversationRate.rejected, (state, action) => {
      state.status = "failed";
      state.error = "Failed to fetch user conversation rate.";
    });

    //setUserConversationRate

    builder.addCase(setUserConversationRate.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(setUserConversationRate.fulfilled, (state, action) => {
      state.status = "succeed";
    });
    builder.addCase(setUserConversationRate.rejected, (state, action) => {
      state.status = "failed";
      state.error = "Failed to set user conversation rate.";
    });
  },
});

export default rateSlice.reducer;
