import React, { useState, FormEvent, useEffect } from "react";
import "./EditCharacterPage.css";
import AdminNavigation from "../AdminNavigation";
import Character from "../../../datamodel/Character";
import {
  ItemStringWithSubtitle,
  ItemWithDropdown,
  PageTitle,
  TextGroup,
} from "../Items";
import {
  deleteCharacter,
  getCharacterById,
  updateCharacter,
} from "../../../redux/CharactersSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import ChangeCharacterAvatarPopup from "../../../components/character/ChangeCharacterAvatarPopup";
import CropCharacterProfilePopup from "../../../components/character/CropCharacterProfilePopup";
import DefaultAvatar from "../../../assets/images/fic_default_user_pic.jpeg";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Deactive", label: "Deactive" },
];

const featuredOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const adsOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const draftOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const EditCharacterPage: React.FC = () => {
  const { characterId } = useParams<{
    characterId: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editingCharacter, setEditingCharacter] = useState<
    Character | undefined
  >(undefined);

  // Get character by id from the store
  const character = useSelector(
    (state: RootState) => state.characters?.character
  );
  const characterStatus = useSelector(
    (state: RootState) => state.characters?.status
  );

  // Change Avatar
  const [OpenChangeProfile, setOpenChangeProfile] = React.useState(false);
  const [openCrop, setOpenCrop] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [uploadedImage, setUploadedImage] = React.useState<
    string | ArrayBuffer | null
  >(null);
  const [croppedImage, setCroppedImage] = React.useState<Blob | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>(DefaultAvatar);

  useEffect(() => {
    if (characterId) {
      dispatch(getCharacterById(characterId) as any);
    }
  }, [dispatch, characterId]);

  // Watch for changes in `character` and update local state
  useEffect(() => {
    if (character) {
      setEditingCharacter(character);
      setUploadedImage(character.avatarUrl);
    }
  }, [character]);

  const updateScreenHeight = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  useEffect(() => {
    if (avatarUrl) {
      setEditingCharacter({
        ...editingCharacter!,
        avatarUrl: avatarUrl,
      });
    }
  }, [avatarUrl]);

  if (characterStatus === "loading") {
    return <div className="loading">Loading character {characterId} ...</div>;
  }

  if (characterStatus === "failed") {
    return (
      <div className="loading">Failed to load character {characterId}</div>
    );
  }

  const handleUpdate = async (event: FormEvent) => {
    event.preventDefault();
    await dispatch(
      updateCharacter({ characterId, character: editingCharacter }) as any
    );
  };

  const handleDelete = async (event: FormEvent) => {
    event.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this character?"
    );

    if (isConfirmed) {
      await dispatch(deleteCharacter(characterId!) as any);
      navigate("/administration/listcharacters");
    }
  };

  const canBeUpdated =
    editingCharacter?.name !== "" &&
    editingCharacter?.status !== "" &&
    editingCharacter?.featuredCharacter !== undefined &&
    editingCharacter?.description !== "" &&
    editingCharacter?.notes !== "";

  return (
    <div className="admin-page screen">
      <AdminNavigation />
      <div className="page-background">
        <form>
          <div className="page-main">
            <PageTitle title="Edit Character" />
            <div className="items">
              <div className="item-simple-input">
                <div className="text-title bodybold">Name*</div>
                <input
                  className="input-label input-default-without-icon"
                  type="text"
                  value={editingCharacter?.name}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Status*</div>
                <Select
                  className="dropdown-content"
                  options={statusOptions}
                  value={{
                    value: editingCharacter?.status,
                    label: editingCharacter?.status,
                  }}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      status: e?.value!,
                    })
                  }
                />
              </div>
              <div className="item-simple-input">
                <div className="text-title bodybold">Short Description*</div>
                <input
                  className="input-default-without-icon input-label"
                  type="text"
                  value={editingCharacter?.description}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="item-simple-input">
                <div className="text-title bodybold">Tag</div>
                <input
                  className="input-default-without-icon input-label"
                  type="text"
                  value={editingCharacter?.tag}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      tag: e.target.value,
                    })
                  }
                />
              </div>
              <ItemStringWithSubtitle
                title="URL Slug"
                subtitle="www.fictiono.us/c/[slug]"
                defaultInput="GPT"
              />
              <div className="item-no-input">
                <div className="text-title bodybold">Character Avatar</div>
                <div
                  className="button-action"
                  onClick={() => setOpenChangeProfile(true)}
                >
                  <div className="button-label-white">Upload</div>
                </div>
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Featured Character</div>
                <Select
                  className="dropdown-content"
                  options={featuredOptions}
                  value={{
                    value: editingCharacter?.featuredCharacter!,
                    label:
                      editingCharacter?.featuredCharacter! === true
                        ? "Yes"
                        : "No",
                  }}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      featuredCharacter: e?.value!,
                    })
                  }
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Avertising</div>
                <Select
                  className="dropdown-content"
                  options={adsOptions}
                  value={{
                    value: editingCharacter?.advertising!,
                    label:
                      editingCharacter?.advertising! === true ? "Yes" : "No",
                  }}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      advertising: e?.value!,
                    })
                  }
                />
              </div>
              <div className="item-title-input-dropdown">
                <div className="text-title bodybold">Draft</div>
                <Select
                  className="dropdown-content"
                  options={draftOptions}
                  value={{
                    value: editingCharacter?.draft!,
                    label: editingCharacter?.draft! === true ? "Yes" : "No",
                  }}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      draft: e?.value!,
                    })
                  }
                />
              </div>
              <div className="item-simple-input">
                <TextGroup
                  title="Notes*"
                  subtitle="Internal notes about this character"
                />
                <textarea
                  className="input-default-large input-label"
                  placeholder="Input notes here"
                  value={editingCharacter?.notes}
                  onChange={(e) =>
                    setEditingCharacter({
                      ...editingCharacter!,
                      notes: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <ChangeCharacterAvatarPopup
            OpenChangeProfile={OpenChangeProfile}
            setOpenChangeProfile={setOpenChangeProfile}
            screenWidtht={screenWidth}
            openCrop={openCrop}
            setOpenCrop={setOpenCrop}
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            croppedImage={croppedImage}
            setAvatarUrl={setAvatarUrl}
          />

          <CropCharacterProfilePopup
            setOpenCrop={setOpenCrop}
            openCrop={openCrop}
            screenWidtht={screenWidth}
            setOpenChangeProfile={setOpenChangeProfile}
            uploadedImage={uploadedImage}
            setCroppedImage={setCroppedImage}
          />
          <div className="simple-space"></div>
          <div className="multi-actions">
            <button
              className={`${
                canBeUpdated ? "button-action" : "button-disabled-action"
              }`}
              disabled={!canBeUpdated}
              onClick={handleUpdate}
            >
              Update Changes
            </button>
            <button className="button-action" onClick={handleDelete}>
              Delete Character
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCharacterPage;
