import ConversationProps from "../../datamodel/Conversation";
import "./Conversation.css";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import { useNavigate } from "react-router-dom";
import thumbsdown from "../../assets/images/emoji_thumbsdown.svg";
import thumbsup from "../../assets/images/emoji_thumbsup.svg";
import RectangleSkeleton from "../Skeleton/RectangleSkeleton";

const Conversation = (conversation: ConversationProps) => {
  const navigate = useNavigate();

  return (
    <div className="conversation">
      <div
        className="subject"
        style={{
          cursor: conversation.content === "N/A" ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          conversation.content !== "N/A" &&
            navigate(`/conversation/${conversation?.id}`);
        }}
      >
        <img
          className="avatar-small-round"
          src={conversation.avatar}
          alt="avatar"
          onError={(e: any) => {
            e.target.src = DefaultImage;
          }}
        />

        <p className="conversation-content valign-text-middle">
          {conversation.content}
        </p>

        <div className="score">
          <div className="number inter-semi-bold-cloud-12px">
            {conversation.score}
          </div>
          {conversation.score >= 0 ? (
            <img className="emoji" src={thumbsup} alt="e-plus" />
          ) : (
            <img className="down-emoji" src={thumbsdown} alt="e-minus" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Conversation;
