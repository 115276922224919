import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Conversation from "../datamodel/Conversation";
import { toast } from "react-toastify";
import Character from "../datamodel/Character";
import { Buffer } from "buffer";

interface ConversationState {
  conversation: Conversation | null;
  status: "idle" | "loading" | "succeed" | "failed";
  error: string | null;
  character: Character | null;
  getRecentActivityData: any;
  getBestActivityData: any;
  getAllCharacterData: any;
  characterId: string;
  characterLimit: Number | null;
  characterDate: string;
  getBestId: any;
  OGImagePostLoading: boolean;
}

const initialState: ConversationState = {
  conversation: null,
  status: "idle",
  error: null,
  character: null,
  getRecentActivityData: [],
  getBestActivityData: [],
  getAllCharacterData: [],
  characterId: "",
  characterLimit: null,
  characterDate: "",
  getBestId: "",
  OGImagePostLoading: false,
};

export const createConversation = createAsyncThunk(
  "conversations/createConversation",
  async (conversation: Conversation) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/conversations`,
        conversation
      );
      if (response.status < 200 && response.status >= 300) {
        toast.error("Failed to save conversation.");
      }
      return response.data;
    } catch (error) {
      toast.error("Failed to save conversation.");
      return error;
    }
  }
);

export const getConversationById = createAsyncThunk(
  "getConversationById",
  async (props: any) => {
    const { conversationId, dispatch } = props;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/conversations/${conversationId}`
      );
      if (response?.data?.characterId) {
        dispatch(getCharacterById({ id: response?.data?.characterId }));
        return response.data;
      }
    } catch (error) {
      toast.error("Failed to fetch conversations.");
      throw error;
    }
  }
);

export const getCharacterById = createAsyncThunk(
  "GetCharacterById",
  async (props: any) => {
    const { id } = props;
    let url = `${process.env.REACT_APP_BACKEND_API_URL}/characters`;

    if (id) {
      url += `/${id}`;
    }

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      toast.error("Failed getCharacterById");
      throw error;
    }
  }
);

export const getAllCharacter = createAsyncThunk("getAllCharacter", async () => {
  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/characters`;

    const response = await axios.get(apiUrl);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    toast.error("dropown error");
    return error;
  }
});

export const getRecentActivity = createAsyncThunk(
  "getRecentActivity",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId } = props;

    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/conversations/recent?limit=10`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

export const getBestActivity = createAsyncThunk(
  "getBestActivity",
  async (props: any) => {
    const { characterId, characterdaysago, SeeMoreId } = props;
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}/conversations/best?limit=10`;

      if (characterId) {
        apiUrl += `&character=${characterId}`;
      }

      if (characterdaysago) {
        apiUrl += `&daysAgo=${characterdaysago}`;
      }

      if (SeeMoreId) {
        apiUrl += `&cursor=${SeeMoreId}`;
      }

      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error("recent error");
      return error;
    }
  }
);

// OG-Image
export const OGImagePost = createAsyncThunk(
  "OGImagePost",
  async (props: any) => {
    const { file, convoId } = props;

    const blob = await fetch(file).then((res) => res.blob());
    const file1 = new File([blob], "image.png", { type: "image/png" });
    const body = new FormData();
    body.append("image", file1);
    
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/conversations/${convoId}/uploadogimage`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status >= 200 && res.status < 300) {
        toast.success("OG Image Set");
        return res?.data;
      } else {
        toast.info("OG-Image not supported");
      }
    } catch (error) {
      toast.error("OG-Image Error");
    }
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    resetCharacter(state) {
      state.getRecentActivityData = [];
      state.getBestActivityData = [];
    },
    setGlobalCharacterId(state, action) {
      state.characterId = action.payload.characterId;
      state.characterDate = action.payload.characterdaysago;
    },
  },
  extraReducers: (builder) => {
    // Create conversation
    builder.addCase(createConversation.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createConversation.fulfilled, (state, action) => {
      state.status = "succeed";
      state.conversation = action.payload;
    });
    builder.addCase(createConversation.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // Get Conversation information, not messages
    builder.addCase(getConversationById.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getConversationById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.conversation = action.payload;
    });
    builder.addCase(getConversationById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // getRecentActivity
    builder.addCase(getRecentActivity.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getRecentActivity.fulfilled, (state, action) => {
      state.status = "succeed";
      state.getRecentActivityData = action.payload;
    });
    builder.addCase(getRecentActivity.rejected, (state, action) => {
      state.status = "failed";
    });

    // getBestActivity
    builder.addCase(getBestActivity.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getBestActivity.fulfilled, (state, action) => {
      state.status = "succeed";
      state.getBestActivityData = action.payload;
      state.getBestId = Date.now();
    });
    builder.addCase(getBestActivity.rejected, (state, action) => {
      state.status = "failed";
    });

    // getCharacterById
    builder.addCase(getCharacterById.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCharacterById.fulfilled, (state, action) => {
      state.status = "succeed";
      state.character = action.payload;
    });
    builder.addCase(getCharacterById.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload as string;
    });

    // getAllCharacter
    builder.addCase(getAllCharacter.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getAllCharacter.fulfilled, (state, action) => {
      state.status = "succeed";
      state.getAllCharacterData = action.payload;
    });
    builder.addCase(getAllCharacter.rejected, (state, action) => {
      state.status = "failed";
    });

    // OGImagePost
    builder.addCase(OGImagePost.pending, (state, action) => {
      state.OGImagePostLoading = true;
    });
    builder.addCase(OGImagePost.fulfilled, (state, action) => {
      state.OGImagePostLoading = false;
      state.getAllCharacterData = action.payload;
    });
    builder.addCase(OGImagePost.rejected, (state, action) => {
      state.OGImagePostLoading = false;
    });
  },
});

export const { resetCharacter, setGlobalCharacterId } =
  conversationSlice.actions;
export default conversationSlice.reducer;
