import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./NewConversation.css";
import Header from "../../components/header/Header";
import DefaultImage from "../../assets/images/fic_default_user_pic.jpeg";
import threeDot from "../../assets/images/threedots.svg";
import SendIcon from "../../assets/images/Send.svg";
import thumbsdown from "../../assets/images/emoji_thumbsdown.svg";
import thumbsup from "../../assets/images/emoji_thumbsup.svg";
import domtoimage from "dom-to-image";

import rolling from "../../assets/images/emoji_rolling.svg";
import BotImage from "../../assets/images/conversationBot.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getCharacterById } from "../../redux/CharactersSlice";
import { setUserConversationRate } from "../../redux/ConversationRateSlice";
import { getPromptById } from "../../redux/PromptsSlice";
import Conversation from "../../datamodel/Conversation";
import ThumbUpImage from "../../assets/images/thumbup.svg";
import SendArrowenable from "../../assets/images/SendArrowenable.svg";
import SendArrowdisable from "../../assets/images/SendArrowdisable.svg";
// import DefaultProfileImage from "../../assets/images/fic_default_user_pic.jpeg";

import {
  OGImagePost,
  createConversation,
  getBestActivity,
  getConversationById,
  getRecentActivity,
} from "../../redux/ConversationSlice";
import { createMessage } from "../../redux/FictionoaiSlice";
import Character from "../../datamodel/Character";
import { ReceivedMessage, SentMessage } from "../../datamodel/Message";
import { fetchGlobalConfig } from "../../redux/GlobalConfigSlice";
import SummaryBestAndRecentPage from "../bestandrecent/BestAndRecentOverview";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import MenuImage from "../../assets/images/menu.png";
import SharePopup from "../../components/share-popup/SharePopup";
import { RemoveScroll } from "react-remove-scroll";
import { useUser } from "../../components/contexts/UserContext";
import Voting from "../../datamodel/Voting";
import Rate from "../../datamodel/Rate";
// import Preview from "../../components/share-popup/preview";
import { fetchConversationMessages } from "../../redux/ConversationMessageSlice";
import { CircularProgress } from "@mui/material";
import PreviewNew from "../../components/share-popup/previewNew";
// import OGImage from "../../OGImage";

const NewConversationPage: React.FC = () => {
  useEffect(() => {
    document.title = `Fictiono.us - ${character?.name}`;
  });

  const dispatch: any = useDispatch();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();
  const MainChat = useRef(null);
  const HeaderHeight = useRef(null);
  const ChatHeader = useRef(null);
  const ChatInput = useRef(null);
  const messageContainerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [newConversationId, setNewConversationId] = useState("");
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState<ReceivedMessage[]>([]);
  const [attempts, setAttempts] = useState(0);
  const [focused, setFocused] = useState(false);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidtht, setScreenWidtht] = useState(window.innerWidth);
  const isMobile = window.innerWidth <= 768;

  const [triggerCollapse, settriggerCollapse] = useState(false);
  const [textareaRows, setTextareaRows] = useState(1);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [responseLoad, setResponseLoad] = useState({
    isLoadStart: false,
    content: "",
  });
  const [vote, setVote] = useState<Voting | null>(null);
  const [score, setScore] = useState<number>(0);
  const { user, setUser } = useUser();
  const [loginOpen, setLoginOpen] = useState(false);
  const [openMobileRating, setOpenMobileRating] = useState<boolean>(false);
  const [charLength, setCharLength] = useState<any>();

  // Get global configuation from the store
  const globalconfigs = useSelector(
    (state: RootState) => state.globalConfig?.data
  );
  const globalConfigStatus = useSelector(
    (state: RootState) => state.globalConfig?.status
  );

  // Get character by id from the store
  const character = useSelector(
    (state: RootState) => state.characters?.character
  );
  const characterStatus = useSelector(
    (state: RootState) => state.characters?.status
  );
  const prompt = useSelector((state: RootState) => state.prompts?.prompt);
  const promptStatus = useSelector((state: RootState) => state.prompts?.status);

  // image converter
  const [sourceImg, setSourceImg] = useState("");
  const [OgImage, setOgImage] = useState("");
  const [ShareLoading, setShareLoading] = useState(false);

  const node = document.getElementById("image-container");

  // const convertNodeToImage = () => {
  //   const node = document.getElementById("image-container");

  //   console.log("node1 >>>>", node);
  //   if (node) {
  //     domtoimage.toPng(node).then(function (image: any) {
  //       if (image) {
  //         setOgImage(image);
  //         dispatch(OGImagePost({ file: image, convoId: newConversationId }));
  //         console.log("node2 >>>>", image);
  //         setShareLoading(false);

  //         setIsSharePopupOpen(!isSharePopupOpen);
  //       }
  //     });
  //   }
  // };

  const convertNodeToImage = () => {
    const node = document.getElementById("new-image-container");

    return new Promise((res) => {
      console.log("node1 >>>>", node);
      if (node) {
        domtoimage
          .toPng(node)
          .then(function (image: any) {
            if (image) {
              setOgImage(image);
              dispatch(
                OGImagePost({ file: image, convoId: newConversationId })
              );
              setShareLoading(false);
              res("complete");
              console.log("node2 >>>>", image);
              setIsSharePopupOpen(!isSharePopupOpen);
            }
          })
          .catch(function (error: any) {
            console.error("Oops, something went wrong!", error);
            res("complete");
            console.log("node3 >>>>", error);
          });
      }
    });
  };

  useEffect(() => {
    if (character?.avatarUrl) {
      setShareLoading(true);

      fetch("/get-img-base64", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          img: character?.avatarUrl,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setSourceImg(res?.image);
          setShareLoading(false);
        });
    }
  }, [character]);

  const toggleSharePopup = async () => {
    if (!isSharePopupOpen) {
      setShareLoading(true);
      await convertNodeToImage();
    }
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const serveCollapse = () => {
    setTimeout(() => {
      settriggerCollapse(true);
    }, 25);
  };

  // In all below handle functions, we 1. change UX, 2, set the UX Score, 3. call the API to update the score
  const handleVoteUp = () => {
    if (vote === Voting.ThumbUp) {
      setVote(Voting.Empty);
      setScore(0);
      RateConversationApi(Voting.Empty);
    } else {
      setVote(Voting.ThumbUp);
      setScore(1);
      RateConversationApi(Voting.ThumbUp);
    }
  };
  const handleVoteDown = () => {
    if (vote === Voting.ThumbDown) {
      setVote(Voting.Empty);
      setScore(0);
      RateConversationApi(Voting.Empty);
    } else {
      setVote(Voting.ThumbDown);
      setScore(-1);
      RateConversationApi(Voting.ThumbDown);
    }
  };
  const handleVoteSmily = () => {
    if (vote === Voting.Smily) {
      setVote(Voting.Empty);
      setScore(0);
      RateConversationApi(Voting.Empty);
    } else {
      setVote(Voting.Smily);
      setScore(1);
      RateConversationApi(Voting.Smily);
    }
  };

  const handleNewChat = () => {
    window.location.reload();
  };
  const handleBestAndRecent = () => {
    navigate(`/bestandrecent`);
  };
  const updateScreenHeight = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidtht(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenHeight);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const { characterId, promptId } = useParams<{
    characterId: string;
    promptId: string;
  }>();

  const [screenHeightsMb, setScreenHeightsMb] = useState(
    window.screen.availHeight
  );
  function scrollToTop() {
    return new Promise((res) => {
      // Scroll to the top of the page with smooth animation
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
      setTimeout(() => {
        res("scroll up");
      }, 50);
    });
  }

  const isKeyboardOpenLib = useDetectKeyboardOpen();
  useEffect(() => {
    const scrollableElement = document.querySelector("#enable-chat");
    // if (isMobile) {
    if (isKeyboardOpenLib && focused) {
      // disableScroll.on();
      // @ts-ignore
      setScreenHeightsMb(window.visualViewport?.height);
      scrollToTop().then(() => {
        if (!chatStarted) {
          disablePageScroll();
          if (scrollableElement) {
            enablePageScroll(scrollableElement);
          }
        }
      });
    } else {
      // disableScroll.off();
      if (!chatStarted) {
        enablePageScroll();
      }
      setScreenHeightsMb(window.innerHeight);
    }
  }, [isKeyboardOpenLib, focused]);

  const initHEight = {
    chatScreenChange: 180,
  };

  const [focusedHeight, setFocusedHeight] = useState(initHEight);
  useEffect(() => {
    if (focused && isKeyboardOpenLib) {
      setFocusedHeight({
        chatScreenChange: 90,
      });
    } else {
      setFocusedHeight(initHEight);
    }
  }, [focused, isKeyboardOpenLib]);

  useEffect(() => {
    dispatch(getCharacterById(characterId!) as any);
    dispatch(getPromptById(promptId!) as any);
    dispatch(fetchGlobalConfig() as any);

    // get best & recent for particular character
    // dispatch(getRecentActivity({ characterId }));
    // dispatch(getBestActivity({ characterId }));
  }, [dispatch, characterId, promptId]);

  const RateConversationApi = (vote: Voting) => {
    const data: Rate = {
      // userId: user?.attributes?.email,
      userId: encodeURIComponent(user?.attributes?.email),
      conversationId: newConversationId,
      vote: vote,
    };
    if (data) {
      dispatch(setUserConversationRate(data));
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the message container whenever the conversation updates.
    if (messageContainerRef.current) {
      //@ts-ignore
      messageContainerRef.current.scrollTop =
        //@ts-ignore
        messageContainerRef.current.scrollHeight;
    }
  }, [conversation]);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

  const textareaStyle = {
    marginTop: isMobile ? "3px" : "7px",
    // maxHeight: isFirefox && textareaRows < 2 ? "30px" : "none",
    // maxHeight: isFirefox && textareaRows < 2 ? "37px" : "auto",
  };

  const AssignChatValues = (text: string) => {
    return new Promise((res, rej) => {
      let startLen = 0;
      const interval = 5; // Adjust the typing speed (milliseconds per character)

      const typingInterval = setInterval(() => {
        const someChats = [...conversation];
        someChats[someChats?.length - 1].content = text?.substring(
          0,
          startLen + 1
        );
        setConversation(someChats);
        startLen++;
        if (startLen === text.length) {
          clearInterval(typingInterval); // Stop typing when done
          res("done");
        }
      }, interval);
    });
  };

  useEffect(() => {
    localStorage.removeItem("userInput");
  }, []);

  useEffect(() => {
    localStorage.removeItem("userInput");
  }, []);

  useEffect(() => {
    localStorage.removeItem("userInput");
  }, []);

  useEffect(() => {
    if (user !== null) {
      if (responseLoad.isLoadStart) {
        AssignChatValues(responseLoad.content).then(() => {
          setResponseLoad({
            content: "",
            isLoadStart: false,
          });
        });
      }
    }
  }, [responseLoad.isLoadStart]);

  const handleStartChat = async () => {
    if (user === null) {
      setLoginOpen(true);
    } else {
      setLoginOpen(false);

      if (!chatStarted) {
        const conversation: Conversation = {
          content: userInput,
          avatar:
            character?.avatarUrl === "" ? DefaultImage : character?.avatarUrl!,
          score,
          emojiImage: ThumbUpImage,
          characterName: character?.name!,
          characterId: characterId!,
          promptId: promptId!,
          userId: encodeURIComponent(user?.attributes?.email),
          visible: true,
        };
        const newConversation = await dispatch(
          createConversation(conversation) as any
        );
        const conversationId = newConversation.payload._id;
        setNewConversationId(conversationId);
        createMessageForConversation(conversationId, userInput);
      } else {
        createMessageForConversation(newConversationId, userInput);
      }
      setChatStarted(true);
      setUserInput("");
      setTextareaRows(1);
      setAttempts(attempts + 1);

      if (!localStorage.getItem("userInput")) {
        localStorage.setItem("userInput", userInput);
      }
      setChatStarted(true);
      setUserInput("");
      setTextareaRows(1);
      setAttempts(attempts + 1);
    }
  };

  const createMessageForConversation = async (
    conversationId: string,
    content: string
  ) => {
    const message: SentMessage = {
      conversationId,
      characterId: character?._id!,
      prompt: prompt?.prompt!,
      content,
    };
    const messageSent: ReceivedMessage = {
      role: "user",
      content,
      avartar: BotImage,
    };
    setConversation([...conversation, messageSent]);
    const receivedMessage = await dispatch(createMessage(message) as any);
    const messageReceived: ReceivedMessage = {
      ...receivedMessage.payload,
      avartar:
        character?.avatarUrl === "" ? DefaultImage : character?.avatarUrl,
    };
    setResponseLoad({
      isLoadStart: true,
      content: messageReceived.content,
    });
    let dupMessageReceive = messageReceived;
    dupMessageReceive.content = "|";
    setConversation([...conversation, messageSent, dupMessageReceive]);

    if (messageContainerRef.current) {
      //@ts-ignore
      messageContainerRef.current.scrollTop =
        //@ts-ignore
        messageContainerRef.current.scrollHeight;
    }
  };

  const resizeTextArea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const { style } = textarea;
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);

      style.height = "auto";
      style.height = `${Math.min(textarea.scrollHeight + 5, 5 * lineHeight)}px`;
      style.maxHeight = `${4 * lineHeight}px`;
      style.overflowY =
        textarea.scrollHeight > 5 * lineHeight ? "scroll" : "hidden";
    }
  };

  const handleTextareaChange = () => {
    resizeTextArea();
    setUserInput(textareaRef.current?.value || "");
    setCharLength(textareaRef?.current?.value.length);
  };

  // const handleTextareaChange = (
  //   event: React.ChangeEvent<HTMLTextAreaElement>
  // ) => {
  //   const textarea = event.target;
  //   const minRows = 1; // Minimum number of rows
  //   const maxRows = 4; // Maximum number of rows
  //   setCharLength(textarea.value.length);

  //   // console.log({lengthhhhhhhhhhh : textarea.value.length});

  //   // Calculate the number of rows based on the content and textarea properties
  //   const currentRows = Math.min(
  //     maxRows,
  //     Math.max(minRows, textarea.value.split("\n").length)
  //   );

  //   // Update the state with the new number of rows
  //   setTextareaRows(currentRows);

  //   // Update the user input
  //   setUserInput(textarea.value);
  // };

  // const onUserInput = (value: string) => {
  //   setUserInput(value);
  // };

  const backgroundImageStyle: CSSProperties = {
    width: "100%",
    // height: backgroundImageHeight,
    minHeight: "100%",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "absolute",
    // backgroundImage: `url(${
    //   character?.avatarUrl === "" ? DefaultImage : character?.avatarUrl
    // })`,
    backgroundImage: `linear-gradient(to right, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
    url(${character?.avatarUrl === "" ? DefaultImage : character?.avatarUrl})`,
    //  background:
    // `linear-gradient(147deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.25) 100%),
    // url(${DefaultImage}),lightgray 50% / cover no-repeat`,

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 0.2, // 20% opacity
  };

  if (chatStarted) {
    backgroundImageStyle.paddingBottom = "20px";
  }

  const contentStyle: CSSProperties = {
    zIndex: 1, // Place content above the background image
  };

  const promptCharLimit: any = charLength >= globalconfigs?.promptCharLimit!;
  const reachedMaxAttempts = attempts >= globalconfigs?.maxPrompts!;

  // const chatDisabled =
  //   userInput === "" || reachedMaxAttempts || promptCharLimit;

  const chatDisabled =
    userInput.trim() === "" ||
    reachedMaxAttempts ||
    (promptCharLimit && userInput.trim() !== "") ||
    userInput.length === 1 ||
    (userInput.trim() === "" && userInput.includes(" "));

  // Page conditions
  if (characterStatus === "loading") {
    return <div className="loading">Loading character {characterId} ...</div>;
  }

  if (characterStatus === "failed") {
    return (
      <div className="loading">Failed to load character {characterId}</div>
    );
  }

  if (promptStatus === "loading") {
    return <div className="loading">Loading prompt {promptId} ...</div>;
  }

  if (promptStatus === "failed") {
    return <div className="loading">Failed to load prompt {promptId}</div>;
  }

  if (globalConfigStatus === "loading") {
    return <div className="loading">Loading global config...</div>;
  }

  if (globalConfigStatus === "failed") {
    return <div className="loading">Failed to load global config</div>;
  }

  console.log({ conversationnnnnnnnn: conversation });

  return (
    // <div className="home-page">
    <div className={!isMobile ? "container" : ""}>
      {showDropdown && (
        <div className="toggle-dropdown">
          {/* Dropdown items go here */}
          <div className="new-conversation-dropdown-item">
            <a> EinsteinJobs</a>
          </div>
          <div className="new-conversation-dropdown-item">
            <a>JesusGPT</a>
          </div>
          {/* Add more character links as needed */}
        </div>
      )}
      {/* <div className="c-main-container"> */}
      {!isMobile ? (
        <Header
          triggerCollapse={triggerCollapse}
          settriggerCollapse={settriggerCollapse}
          isMobile={isMobile}
          ref={HeaderHeight}
          loginOpen={loginOpen}
          setLoginOpen={setLoginOpen}
        />
      ) : null}
      <div className="chat-main-container">
        <div
          className={
            isKeyboardOpenLib ? "header-page-focused" : "header-page-maindiv"
          }
          style={{
            overflow: isMobile ? "hidden" : "auto",
          }}
        >
          {isMobile ? (
            <Header
              triggerCollapse={triggerCollapse}
              settriggerCollapse={settriggerCollapse}
              // isMobile={isMobile}
              ref={HeaderHeight}
              loginOpen={loginOpen}
              setLoginOpen={setLoginOpen}
            />
          ) : null}
          <div
            style={{
              // height: "auto" ,
              height: isMobile ? "auto" : `${screenHeight - 140}px`,
            }}
            className="main-chat-page"
            ref={MainChat}
          >
            <div
              className={
                !isMobile
                  ? "header-character"
                  : isKeyboardOpenLib
                  ? "header-character-mobo"
                  : "header-character"
              }
              ref={ChatHeader}
            >
              <div className="character">
                <div className={isMobile ? "avatar-s" : "avatar-m"}>
                  <img
                    className="avatar-image"
                    src={
                      character?.avatarUrl === ""
                        ? DefaultImage
                        : character?.avatarUrl
                    }
                    alt={character?.name}
                  />
                </div>

                <div className="title">
                  <div className="character-name">
                    <h1 className="name dmmono-medium-turquoise-24px">
                      {character?.name}
                    </h1>
                    {/* <img
                      className="vector-img-toggle"
                      src={VectorImage}
                      alt="avatar"
                      onClick={handleDropdown}
                    /> */}
                  </div>
                  <div className="character-description">
                    {character?.description}
                  </div>
                </div>

                {chatStarted ? (
                  <button onClick={toggleSharePopup} className="share-button">
                    <span className="share-button-text">Share</span>
                    {ShareLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <img
                        className="share-icon"
                        src={SendIcon}
                        alt="Share Icon"
                      />
                    )}
                  </button>
                ) : null}

                {!isMobile ? (
                  <img className="three-dot" src={threeDot} alt="three-dot" />
                ) : isKeyboardOpenLib ? (
                  <button className="navbar-toggler" onClick={serveCollapse}>
                    <img className="menu" src={MenuImage} alt="menu" />
                  </button>
                ) : (
                  <img className="three-dot" src={threeDot} alt="three-dot" />
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                height: isMobile
                  ? `${screenHeightsMb - focusedHeight.chatScreenChange + 5}px`
                  : "40vh", // "auto",//"50vh",
              }}
            >
              {chatStarted ? (
                <>
                  {isMobile ? (
                    <RemoveScroll forwardProps>
                      <div
                        id="enable-chat"
                        className="message-window"
                        style={{
                          height: isMobile ? "100%" : "auto", //"60vh",
                          width: isMobile
                            ? "100vw"
                            : //@ts-ignore
                              MainChat?.current?.clientWidth,
                        }}
                      >
                        <div style={backgroundImageStyle}></div>
                        <div
                          style={{
                            ...contentStyle,
                            //  width: isMobile ? "90%" : "80%",
                            // width: isMobile ? "90%" :
                            // //@ts-ignore
                            // MainChat?.current?.clientWidth - 40,

                            width: MainChat
                              ? //@ts-ignore
                                MainChat?.current?.clientWidth - 30
                              : "90%",

                            // height:reachedMaxAttempts ? "88%": "84%",
                            //@ts-ignore
                            height: `${
                              screenHeight -
                              125 -
                              //@ts-ignore
                              ChatHeader?.current?.clientHeight -
                              //@ts-ignore
                              ChatInput?.current?.clientHeight
                            }px`,
                            // overflowY: "auto",
                            overflowY: "scroll",
                            position: "absolute",
                          }}
                          className="message-body"
                          ref={messageContainerRef}
                        >
                          <div className={`message-Text`}>
                            <p className="message-start-text">So I asked</p>
                          </div>
                          {conversation?.map((message, index) => (
                            <div
                              key={index}
                              className={`message-${message.role}`}
                            >
                              <div className={`message-role-${message.role}`}>
                                {message.avartar ? (
                                  <div className="avatar-s">
                                    <img
                                      className="avatar-image"
                                      src={message.avartar}
                                      alt={message.role}
                                    />
                                  </div>
                                ) : (
                                  message.role
                                )}
                              </div>
                              <div
                                className={`message-content-${message.role}`}
                              >
                                <p className={`message-text`}>
                                  {message.content}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </RemoveScroll>
                  ) : (
                    <div
                      id="enable-chat"
                      className="message-window"
                      style={{
                        height: isMobile ? "100%" : "auto", //"60vh",
                        width: isMobile
                          ? "100vw"
                          : //@ts-ignore
                            MainChat?.current?.clientWidth,
                      }}
                    >
                      <div style={backgroundImageStyle}></div>
                      <div
                        style={{
                          ...contentStyle,
                          //  width: isMobile ? "90%" : "80%",
                          width: MainChat
                            ? //@ts-ignore
                              MainChat?.current?.clientWidth - 80
                            : `${screenWidtht - 460}px`,
                          // height: "90%",
                          //@ts-ignore
                          height: `${
                            screenHeight -
                            188 -
                            //@ts-ignore
                            ChatHeader?.current?.clientHeight -
                            //@ts-ignore
                            ChatInput?.current?.clientHeight
                          }px`,
                          // overflowY: "auto",
                          overflowY: "scroll",
                          position: "absolute",
                        }}
                        className="message-body"
                        ref={messageContainerRef}
                      >
                        <div className={`message-Text`}>
                          <p className="message-start-text">So I asked</p>
                        </div>
                        {conversation?.map((message, index) => {
                          // [login user image] is not coming in {user} auth object

                          // const defPic = user.attributes.avatarUrl
                          //   ? user.attributes.avatarUrl
                          //   : DefaultProfileImage;

                          // const pic =
                          //   message?.role === "user" ? defPic : message.avartar;

                          console.log({ rrrrrrrr: message.role });
                          return (
                            <div
                              key={index}
                              className={`message-${message.role}`}
                            >
                              <div className={`message-role-${message.role}`}>
                                {message.avartar ? (
                                  <div className="avatar-s">
                                    <img
                                      className="avatar-image"
                                      src={message.avartar}
                                      alt={message.role}
                                    />
                                  </div>
                                ) : (
                                  message.role
                                )}
                              </div>
                              <div
                                className={`message-content-${message.role}`}
                              >
                                <p className={`message-text`}>
                                  {message.content}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <ChatNotStartedComponent
                  backgroundImageStyle={backgroundImageStyle}
                  contentStyle={contentStyle}
                  character={character!}
                  screenHeight={screenHeightsMb}
                  isKeyboard={isKeyboardOpenLib}
                />
              )}
            </div>

            <div
              className={isMobile ? "chat-window-mobo" : "chat-window"}
              ref={ChatInput}
            >
              {reachedMaxAttempts ? (
                <>
                  <div
                    className={
                      screenWidtht < 900
                        ? "rating-options-mob"
                        : "rating-options-no"
                    }
                  >
                    <RatingComponent
                      score={score}
                      vote={vote}
                      handleVoteUp={handleVoteUp}
                      handleVoteDown={handleVoteDown}
                      handleVoteSmily={handleVoteSmily}
                    />
                  </div>

                  <div
                    className={
                      chatStarted
                        ? "rating-option-mobile"
                        : "rating-option-mobile-disable"
                    }
                  >
                    <button onClick={handleNewChat} className="reating-button">
                      <span className="reating-button-text">New Chat</span>
                    </button>
                    <button className="reating-button">
                      <span className="reating-button-text">Best</span>
                    </button>
                    <button
                      onClick={toggleSharePopup}
                      className="rating-share-button"
                    >
                      <span className="rating-share-button-text">Share</span>
                      {ShareLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <img
                          className="share-icon"
                          src={SendIcon}
                          alt="Share Icon"
                        />
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {openMobileRating && (
                    <div
                      className={
                        isMobile ? "rating-options-mob" : "rating-options-no"
                      }
                    >
                      <RatingComponent
                        score={score}
                        vote={vote}
                        handleVoteUp={handleVoteUp}
                        handleVoteDown={handleVoteDown}
                        handleVoteSmily={handleVoteSmily}
                      />
                    </div>
                  )}

                  <div
                    className={
                      chatStarted
                        ? "rating-option-mobile"
                        : "rating-option-mobile-disable"
                    }
                  >
                    <button
                      className="reating-button"
                      onClick={() => setOpenMobileRating(!openMobileRating)}
                    >
                      <span className="reating-button-text">Rate</span>
                    </button>
                    <button onClick={handleNewChat} className="reating-button">
                      <span className="reating-button-text">New Chat</span>
                    </button>
                    <button
                      onClick={toggleSharePopup}
                      className="rating-share-button"
                    >
                      <span className="rating-share-button-text">Share</span>
                      {ShareLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <img
                          className="share-icon"
                          src={SendIcon}
                          alt="Share Icon"
                        />
                      )}
                    </button>
                  </div>
                </>
              )}
              <div
                className={
                  reachedMaxAttempts
                    ? "chat-container-disable "
                    : "chat-container"
                }
              >
                <textarea
                  ref={textareaRef}
                  className={reachedMaxAttempts ? "input max-prompts" : "input"}
                  // style={{
                  //   marginTop: isMobile ? "3px" : "7px",
                  //   maxHeight: textareaRows < 3 ? "37px" : "auto",
                  // }}
                  style={textareaStyle}
                  placeholder={
                    reachedMaxAttempts
                      ? "Reached max prompts..."
                      : "Ask and you shall be...informed..."
                  }
                  value={userInput}
                  // rows={4}
                  rows={textareaRows}
                  // rows={calculateRows()}
                  // onChange={(e) => onUserInput(e.target.value)}.
                  onInput={handleTextareaChange}
                  onBlur={() => setFocused(false)}
                  onFocus={() => setFocused(true)}
                  disabled={reachedMaxAttempts}
                />
                <button
                  className={`${
                    chatDisabled ? "chat-button-disable" : "chat-button-enable"
                  }`}
                  style={{ alignSelf: `${textareaRows > 1}` ? "flex-end" : "" }}
                  onClick={handleStartChat}
                  disabled={chatDisabled}
                >
                  {chatStarted ? "Chat On" : "Start Chat"}
                </button>
                <button
                  className={`${
                    chatDisabled
                      ? "chat-button-mobile-disable"
                      : "chat-button-mobile-enable"
                  }`}
                  onClick={handleStartChat}
                  disabled={chatDisabled}
                >
                  {chatDisabled ? (
                    <img src={SendArrowdisable} alt="SendArrowdisable" />
                  ) : (
                    <img src={SendArrowenable} alt="SendArrowenable" />
                  )}
                  {/* {chatStarted ? <img  src={SendArrowenable} alt="SendArrowenable" /> : <img  src={SendArrowdisable} alt="SendArrowdisable" />} */}
                </button>
              </div>

              {promptCharLimit && (
                <div
                  style={{
                    color: "red",
                    alignItems: "left",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                >
                  You have reached the maximum {globalconfigs?.promptCharLimit}{" "}
                  character limit.
                </div>
              )}

              {chatStarted ? (
                <div className="rating-option-main">
                  {/* <div
                    className={"rating-options" 
                      // ratingShow ? "rating-options" : "rating-options-no"
                    }
                  > */}
                  <div className="rating-options">
                    <RatingComponent
                      score={score}
                      vote={vote}
                      handleVoteUp={handleVoteUp}
                      handleVoteDown={handleVoteDown}
                      handleVoteSmily={handleVoteSmily}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <button onClick={handleNewChat} className="reating-button">
                      <span className="reating-button-text">New Chat</span>
                    </button>
                    <button
                      onClick={handleBestAndRecent}
                      className="reating-button"
                    >
                      <span className="reating-button-text">Best & Recent</span>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              <>
                <p className="bottom-text">
                  <span>
                    <span className="">
                      Remember: you are chatting with fictional characters and
                      these conversations are public. &nbsp;
                    </span>
                  </span>
                  <span
                    className="span1 "
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://bit.ly/fic-TOS", "_blank")
                    }
                  >
                    Terms of Service
                  </span>
                </p>
              </>
            </div>
          </div>
        </div>
        <div className="best-recent-container">
          {/* <Ads/> */}
          <SummaryBestAndRecentPage characterId={characterId} />
        </div>
      </div>

      {isSharePopupOpen && OgImage ? (
        <SharePopup
          conversationId={newConversationId}
          isSharePopupOpen={isSharePopupOpen}
          toggleSharePopup={toggleSharePopup}
          OgImage={OgImage}
        />
      ) : (
        ""
      )}

      <div className="preview-style">
        <PreviewNew
          conversationId={newConversationId}
          sourceImg={sourceImg}
          sourceName={character?.name}
          ConversionMessages={conversation}
        />
      </div>
    </div>
  );
};

function RatingComponent(props: {
  vote: Voting | null;
  handleVoteUp: () => void;
  handleVoteDown: () => void;
  handleVoteSmily: () => void;
  score: number | null;
}) {
  return (
    <>
      <div className="rating-score">
        <div className="rating-number">{props.score}</div>
        <div className="rating-text">score</div>
      </div>
      <div className="rating-options-emoji">
        <div
          className={
            props.vote === Voting.ThumbUp
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteUp}
            className="rating-emoji"
            src={thumbsup}
            alt="rating-emoji"
          />
        </div>
        <div
          className={
            props.vote === Voting.ThumbDown
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteDown}
            className="rating-emoji emoji2"
            src={thumbsdown}
            alt="rating-emoji"
          />
        </div>
        <div
          className={
            props.vote === Voting.Smily
              ? "rating-option-select"
              : "rating-option"
          }
        >
          <img
            onClick={props.handleVoteSmily}
            className="rating-emoji"
            src={rolling}
            alt="rating-emoji"
          />
        </div>
      </div>
    </>
  );
}

function ChatNotStartedComponent(props: {
  backgroundImageStyle: CSSProperties | undefined;
  contentStyle: CSSProperties | undefined;
  character: Character;
  screenHeight: number;
  isKeyboard: boolean;
}) {
  return (
    <div
      style={{
        height: "auto",
        // height: props.isKeyboard ? "auto" : ""
        // height:
        //   `${props.screenHeight - 264}px`,
        // width: "50%",
      }}
      className="message-window-not-started"
    >
      <div style={props.backgroundImageStyle}></div>
      <div
        style={{ ...props.contentStyle, scale: "0.7" }}
        className="ready-to-start-content"
      >
        <div className="avatar-xl ready-to-start-image">
          <img
            className="avatar-image "
            src={
              props.character?.avatarUrl === ""
                ? DefaultImage
                : props.character?.avatarUrl
            }
            alt={props.character?.name}
          />
        </div>
        <h1 className="ready-to-start-text">
          Start your chart with {props.character?.name}
        </h1>
      </div>
    </div>
  );
}

export default NewConversationPage;
